/**
 * This is a number input component.
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component } from 'react';
import { Input } from '../../../statics/styles/StyledComponents';
import styled from "styled-components";

const DesignedInput = styled( Input )`
  display: inline-block;
  margin: 0 1rem 1.5rem 1rem;
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height:50px;
  font-size:18px;
  width:95%;
`
type Props = {
  value: number;
  max: number;
  min: number;
  onChange?: Function;
};

type State = {
  numberEntered: number;
};

class NumberInput extends Component<Props, State> {
  constructor( props: Props ) {
    super( props );
    const { value } = this.props;
    this.state = {
      numberEntered: value,
    };
  }

  onNumberChange = ( event: any ): any => {
    const { onChange, max, min } = this.props;
    const input = event.target.value;
    let value = input ? parseInt( input, 10 ) : 0;
    if ( value > max ) {
      value = max;
    } else if ( value < min ) {
      value = min;
    }

    this.setState(
      {
        numberEntered: value,
      },
    );

    if ( onChange ) onChange( value );
  };

  render() {
    const { max, min } = this.props;
    const { numberEntered } = this.state;
    return (
      <DesignedInput
        type="number"
        onChange={this.onNumberChange}
        value={numberEntered}
        min={min}
        max={max}
      />
    );
  }
}

export default NumberInput;
