import { DateRangePicker, isInclusivelyAfterDay } from "react-dates";
import React, { useEffect, useState } from 'react'
import 'react-dates/initialize'; // necessary for latest version  
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import './daterange.css'
import { WhiteButton as MyButton } from "../../statics/styles/StyledComponents";
import styled from "styled-components";
import { DownOutline } from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";
type FocusedInputType = "startDate" | "endDate" | null
interface OnApplyInterface {
    // unix timestamp in seconds
    ( startDateTimeStampInSeconds: number, endDateTimeStampInSeconds: number ): any;
}

type Props = {
    onApply?: OnApplyInterface;
    refereshClicked?:Function
    refreshButton?:boolean
    // style: object;
    // Myrefrence: any; 
};
interface Dates {
    startDate: moment.Moment | null,
    endDate: moment.Moment | null;
    isOpen?: boolean
}

const WhiteButton = styled( MyButton )`
    background:#545454;
    font-family: Gotham book;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    color: #FFFFFF;
    text-align:start;
    padding:10px 0px 30px 20px;
    text-transform: capitalize;
`
const ControlButton = styled( MyButton )`
    background: #222222;
    font-family: Gotham bold;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    color: #FFFFFF;
    text-align:center;
    height: 40px;
    text-transform:Capitalize;
    border-radius:5px;
    width:26rem !important;
    max-width:none !important;
    margin:0px
`
const CalenderControl = styled.div`
   display:flex;
   justify-content:center;
   width:100%;
   background:#545454;
   height:60px;
`
// const [startDate, setStartDate] = useState( moment() );
// const [showCalender, setShowCalender] = useState( false );
// const [showDiv, setShowDiv] = useState( false );
// const [endDate, setEndDate] = useState( moment().add( 1, 'day' ) );


const DateRange = ( props: Props ) => {
    const [state, setState] = useState<Dates>( {
        startDate: moment(),
        endDate: moment().add( 1, 'day' ),
        isOpen: false
    } )
    const { onApply,refreshButton,refereshClicked } = props;
    const [focusedInput, setFocusedInput] = useState<FocusedInputType>( null );
    const [showCalender, setShowCalender] = useState( false )
    const [showDrop, setShowDrop] = useState( "display-none" )
    useEffect(()=>{
        if(refreshButton){
            setShowDrop("display-none")
            setState({...state,isOpen:false})
        }        
    },[refreshButton])

    if ( state.isOpen ) {
        return <DateRangePicker
            calendarInfoPosition="bottom"
            // orientation="vertical"
            startDate={state.startDate} // momentPropTypes.momentObj or null,
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={state.endDate} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            onDatesChange={( { startDate, endDate } ) => setState( { startDate, endDate, isOpen: true } )} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={( focusedInput: FocusedInputType ) => setFocusedInput( focusedInput )} // PropTypes.func.isRequired,
            isOutsideRange={day => ( moment().diff( day ) < 0 )}
            displayFormat="DD-MM-YYYY"
            keepOpenOnDateSelect={true}
            renderCalendarInfo={() => <CalenderControl><ControlButton
                onClick={() => {
                    setShowDrop( "display-none" )
                    setState( ( prevstate ) => ( { ...prevstate, isOpen: false } ) )
                    onApply && onApply( state.startDate ? state.startDate.unix() : 0, state.endDate ? state.endDate.unix() : 0 );

                }}
            >Apply</ControlButton></CalenderControl>}
            numberOfMonths={1}
        // isOutsideRange={day => isInclusivelyAfterDay(day, moment())}
        />
    } else {
        return ( <div className="dropdown" >
            <button className="dropbtn" onClick={() => { 
                setShowDrop( "display" ) 
                refereshClicked && refereshClicked()
                
                }}>select date
            <AntdIcon type={DownOutline} style={{ float: "right" }} />
            </button>

            <div className={showDrop}>
                <WhiteButton onClick={() => {
                    // setState( { startDate: moment().subtract( 6, 'days' ), endDate: moment(), isOpen: true } )
                    setShowDrop( "display-none" )
                    onApply && onApply( moment().subtract( 6, 'days' ).unix(), moment().unix() );

                }}>Last 7 Days</WhiteButton>
                <WhiteButton
                    onClick={() => {

                        setShowDrop( "display-none" )
                        onApply && onApply( moment().subtract( 29, 'days' ).unix(), moment().unix() );
                    }}
                >Last 30 Days</WhiteButton>
                <WhiteButton
                    onClick={() => {
                        setShowDrop( "display-none" )
                        onApply && onApply( moment().startOf( 'month' ).unix(), moment().unix() );
                    }}
                >This Month</WhiteButton>
                <WhiteButton
                    onClick={() => {
                        setShowDrop( "display-none" )
                        onApply && onApply( moment().subtract( 1, 'month' ).startOf( 'month' ).unix(), moment().subtract( 1, 'month' ).endOf( 'month' ).unix() );
                    }}
                >Last Month</WhiteButton>
                <WhiteButton
                    onClick={() => {
                        setShowDrop( "display-none" )
                        onApply && onApply(moment().subtract( 3, 'month' ).unix(), moment().unix() );
                    
                    }}
                >Last Quarter</WhiteButton>
                <WhiteButton onClick={() => {
                    setState( ( prevstate ) => ( { ...prevstate, isOpen: true } ) )
                }}>Custom Date</WhiteButton>


            </div>
        </div> )
    }
}

export default DateRange;
