/**
 * This is a styled loading component
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import styled from 'styled-components';
import React from 'react'
type Props = {
    bgOne: string;
    bgTwo?: string;
    icons?: string;
    title?:string;
    value?:number
};

const Card = styled.div`
background:${( { bgOne, bgTwo }: Props ) => { return `linear-gradient(90deg,${bgOne},${bgTwo} )` }};
height: 80px;
padding:1em;
margin:1em 0; 
  -webkit-transition: margin 0.5s ease,box-shadow 0.5s ease; /* Safari */
  transition: margin 0.5s ease,box-shadow 0.5s ease; 
-moz-box-shadow:0px 0.2em 0.4em rgb(0, 0, 0,0.8);
-webkit-box-shadow:0px 0.2em 0.4em rgb(0, 0, 0,0.8);
box-shadow:0px 0.2em 0.4em rgb(0, 0, 0,0.8);
display:inline;
margin:10px;
border-radius:5px
>img{
    margin-top:1.3em; 
    width:30px
}
>div{
    width: calc(100% - 6em);
    display: block;
    float: right;
}
>div >b{
    font-size:2.3em;
    width: 100%;
    padding-top:5px;
    margin-top:-0.2em;
    margin-bottom:-0.2em;
    display: block;
    font-family: Gotham bold;
    font-style: normal;
    line-height: 120%;
    color:white
}
>div >span{
    font-family: Gotham book ;
    font-style: normal;
    display:block;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    color: #FFFFFF;
    margin-top:10px
}
`

const Statistics = ( props: Props ) => {
    return <Card bgOne={props.bgOne} bgTwo={props.bgTwo}>
        <img src={props.icons} alt="img" />
        <div>
            <span>{props.title}</span>

            <b>{props.value}</b>
        </div>

    </Card>
}

export default Statistics
