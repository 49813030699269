/* eslint-disable no-restricted-globals */
/**
 * This is the key requesting component
 * It sends an AJAX request with key numbers,
 * when success, server returns a link to download a file of all keys requested.
 *
 * After the request, do initialization (fetch data and update table)
 *
 * TODO: It is suggested to specify the minimum value, maximum value,
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { actions } from '../../../../stores/reducers/actions';
import {
  api as APIType,
  Payload,
  KeyCreatePayload,
  KeyCreateSendPayload,
} from '../../../../utilities/types';
import NumberInput from '../../../../components/Input/NumberInput';
import ProgressBar from '../../../../components/ProgressBar';
import { SOCKET_ACTION_TYPE } from '../../../../utilities/Functions/CONSTANTS';
import { useDialog } from '../../../../components/Dialog';
import { Grid, WhiteButton as MyButton } from '../../../../statics/styles/StyledComponents';
import { ItrazokeysChildren } from '../styles';
import styled from 'styled-components'

type Props = {
  api: APIType;
  initialize: Function;
  keyCreateStatus: KeyCreatePayload;
  sendMessage: Function;
  clearResults: Function;
  state?: any
};
const StyledButton = styled( MyButton )`
  display: inline-block;
  margin: 0 10px 0px 10px;
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height:50px;
  float:right
  font-size:18px;
  width:95%;
  max-width:100% !import;
  text-transform:capitalize;
  font-family: gotham bold;
`;
const StyledInput = styled( NumberInput )`
  display: inline-block;
  margin: 0 1.2rem 1.5rem 1.5rem;
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height:50px;
  font-size:18px;
`
const KeyRequest = ( props: Props ) => {
  const openDialog = useDialog();
  const [QRAmount, setQRAmount] = useState( 1 );
  const [downloadURL, setDownloadURL] = useState( '' );
  const [percent, setPercent] = useState( 0 );
  const [complete, setComplete] = useState( false );
  const {
    keyCreateStatus,
    api,
    initialize,
    clearResults,
  } = props;
  const { status, result } = keyCreateStatus;

  const getDownloadLink = useCallback( () => {
    let a = axios.get( api.url ).then( ( response ) => {
      const { data } = response;
      const { url } = data;
      setDownloadURL( url );
    } )
  }, [api.url] );

  useEffect( () => {
    if ( !complete ) {
      setPercent( status.percent );
      if ( status.done ) {
        setComplete( true );
        initialize();
      }
    }
  }, [status, complete, initialize] );

  useEffect( () => {
    if ( result ) {
      getDownloadLink();
      clearResults();
      openDialog( 'Result', result, "Activated", true );
    }
  }, [result, getDownloadLink, clearResults, openDialog] );

  useEffect( () => {
    getDownloadLink();
  }, [getDownloadLink] );

  const putRequest = ( data: KeyCreateSendPayload ) => {
    const { sendMessage } = props;
    setComplete( false );
    sendMessage( data );
  };

  const onClick = () => {
    const descriptionTitle = "Confirm Key Request"
    const message = `Number of keys: ${QRAmount}`;
    openDialog( 'Warning', message, descriptionTitle ).then( () => {
      putRequest( {
        quantity: QRAmount,
      } );
    } );
  };
  return (
    // <ItrazokeysChildren>
    <React.Fragment>
      <StyledInput
        value={1}
        min={1}
        max={100000}
        onChange={( input: number ) => setQRAmount( input )}
      />
      <StyledButton
        type="button"
        onClick={() => onClick()}
      >
        Request Keys
        </StyledButton>
      <StyledButton
        type="button"
        onClick={() => {
          // mark used
          axios.get( `${api.url}?file_status=USED` );
          // download
          window.open( downloadURL );
          setDownloadURL( '' );
        }}
        disabled={downloadURL.length === 0}
      >
        Download Keys
        </StyledButton>

    </React.Fragment>
    // {/* <ProgressBar progress={percent} /> */}

    // </ItrazokeysChildren>
  );
};


const mapStateToProps = ( state: Payload ) => ( {
  keyCreateStatus: state.keyCreate,
  state: state
} );

const mapDispatchToProps = ( dispatch: Function ) => ( {
  sendMessage:
    ( data: KeyCreateSendPayload ) => dispatch( actions.sendMessage( data, SOCKET_ACTION_TYPE.KEY_CREATE ) ),
  clearResults:
    () => dispatch( actions.clearResults() ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( KeyRequest );
