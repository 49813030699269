/**
 * This is a styled loading component
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import styled from 'styled-components';
import React, { useState } from 'react'
import search from '../../statics/images/search.svg'

import { isMobile } from 'react-device-detect';
import { DateRangePickerInput } from 'react-dates';
const Input = styled.input`
        background: #383838;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        border-radius: 5px;
        width:${( { width }: { width?: string } ) => {
        if ( width ) return width;
        return '48%';
    }};;
        height: 50px;
        margin-left:10px;
        border:0px;
        color:#FFFFFF;
        font-family: Gotham book;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 13px;
        padding-left:15px;
`
const StyledSearch = styled.span`
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
position:relative;
right:30px;
top:7px;
border-radius: 5px;
width:48%;
height: 50px;
`;
const SearchContainer = styled.div`
    display:inline;
`
type myProps = {
    placeHolder?: string,
    onChange?: Function
    onKeyEvent?: Function
}

const SearchBox = ( props: myProps ) => {
    const { placeHolder } = props
    const [textEntered, setTextEntered] = useState( "" )
    const onTextChange = ( event: any ): any => {
        const { onChange } = props;
        const input = event.target.value;
        setTextEntered( input )
        if ( onChange ) onChange( input );
    };

    const onKeyUp = ( e: any ) => {
        const { onKeyEvent } = props;
        if ( onKeyEvent ) {
            onKeyEvent( e );
        }
    };
    return <SearchContainer>
        <Input
            placeholder={placeHolder}
            width={isMobile ? "95%" : "48%"}
            onChange={( e ) => onTextChange( e )}
            onKeyUp={( e: any ) => onKeyUp( e )}

        />
        <StyledSearch >
            <img src={search} />
        </StyledSearch>
    </SearchContainer>
}
export default SearchBox
