/**
 * Bundle reducers and provide an reducer interface
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

// import { combineReducers } from 'redux';
// import alertsReducer from './Alerts';
// import realtimeDataReducer from './RealTimeData';

// export default combineReducers({
//   alerts: alertsReducer,
//   realtimeData: realtimeDataReducer,
// });


import { types } from './types';
import { Payload } from '../../utilities/types';

const initialState: Payload = {
  alerts: {
    read: [],
    unread: [],
  },
  realtimeData: {
    oldData: [],
    newData: [],
  },
  keyCreate: {
    status: {
      done: false,
      percent: 0,
    },
    result: '',
  },
  keyActivate: {
    status: {
      done: false,
      percent: 0,
    },
    status_code: 200,
    result: '',
  },
  scanStatistics:{}
};

export default (
  state: Payload,
  action:
  {
    type: string; payload: Payload;
  },
): Payload => {
  if (!state) return initialState;
  switch (action.type) {
    case types.SET_DATA: {
      return {
        ...state,
        alerts: action.payload.alerts,
        realtimeData: action.payload.realtimeData,
        keyCreate: action.payload.keyCreate,
        keyActivate: action.payload.keyActivate,
        scanStatistics:action.payload.scanStatistics,
      };
    }
    case types.CLEAR_RESULTS: {
      return {
        ...state,
        keyCreate: {
          ...state.keyCreate,
          result: '',
        },
        keyActivate: {
          ...state.keyActivate,
          result: '',
          status_code: 200,
        },
      };
    }
    default: {
      return state;
    }
  }
};
