/**
 * Reusable styled components
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const theme = {
  main: '#e33622',
  primary: "#545454",
  maxHeight: '1500px',
  maxWidth: '2500px',
  // minHeight: '800px',
  // minWidth: '1024px',
  sideWidth: '30em',
  collapseWidth: '4em',
  footerHeight: '3rem',
  headerHeight: '3rem',
  mobile: isMobile,
  myScrollbar: `
    /* Firefox */
    scrollbar-color: #444 #888;
    scrollbar-width: thin;

    /* chrome */
    ::-webkit-scrollbar {
      display: block;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #888;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #444;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #333;
    }
    
    // /* ie/Edge */
    -ms-overflow-style: scrollbar;
    scrollbar-face-color: #444;
    scrollbar-track-color: #888;
  `,
};

export const Button = styled.button`
  display: block;
  background: none;
  border: none;
  
  &, &:focus{
    outline: none;
  }

  &:disabled{
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
`;

export const TableButton = styled( Button )`
  background: #383838;
  padding: 0.8rem;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const CloseButton = styled( Button )`
  color: white;
  border: 1px solid white;
  border-radius: 5px;
`;

// export const StyledLabel = styled.label`
//   display: inline-block;
//   width: 10rem;
//   text-align: center;
//   padding: 1rem;
//   background: ${(props) => props.theme.main};
//   color: white;
//   margin: 0 1rem 2rem 0;
//   border-radius: 2px;
//   cursor: pointer;
//   font-size: 1.1rem;
//   font-family: system-ui;
// `;

// export const StyledButton = styled(Button)`
//   width: 10rem;
//   padding: 1rem;
//   background: ${(props) => props.theme.main};
//   color: white;
//   margin-bottom: 1rem;
//   border-radius: 2px;
//   font-size: 1.1rem;
// `;

export const StyledLabel = styled.label`
  text-align: center;
  cursor: pointer;
  font-size: 1.1rem;
  display: inline-block;
  width: 20rem;
  height: 2.5rem;
  font-weight: 500;
  margin: 0 1rem 0 0;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  background: linear-gradient(rgb(76,76,76), rgb(42,42,42));
  text-transform: uppercase;

  &&&{
    &, *{
      color: rgb(246, 246, 246)
    }
  }
`;

export const WhiteButton = styled( Button )`
  display: inline-block;
  width: 20rem;
  height: 2.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0 1rem 0 0;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  background: linear-gradient(rgb(76,76,76), rgb(42,42,42));
  text-transform: uppercase;

  :disabled {
    opacity: 0.4;
  }
  
  &&&{
    &, *{
      color: rgb(246, 246, 246)
    }
  }
`;

export const Table = styled.table`
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 1.2rem;
  text-align: left;
  *{
    color: white;
  }

  tr:nth-child(even){
    background: #333333;

  }
  tr:nth-child(odd){
    background: #383838;

}

  td{
    padding: 2rem;
  }

  tr, td{
    // min-width: 10rem;
  }

  td, th{
    overflow-x:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    height: 68px;
  }

  tr:hover{
    td,th{
      white-space:normal;
      word-break: break-all;
    }
  }

  thead{
   
    border-radius: 5px 5px 0px 0px;
    tr:first-child{
      th{
        padding: 1rem 2rem;
        text-transform: uppercase;
        height:68px;
        background: #1A1A1A;

      }
    }

  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  max-height: 90%;
  overflow: scroll;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  color: white;
  background: rgb(75,75,75);
  padding: 0.5rem;
  border: 1px solid transparent;

  &::placeholder{
      color: rgb(126, 126, 126);
  }

  &:focus{
      border-color: ${( props ) => props.theme.main};
  }
`;

export const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  flex: 3rem;
  color: white;
  display: ${( props ) => {
    if ( props.theme.mobile ) return 'none';
    return 'block';
  }};
`;

export const Body = styled.div`
  flex: auto;
  height: 100%;
  overflow-y: scroll;
`;
export const Grid = styled.div`
display: grid;
grid-gap: 1rem;
@media (min-width: 440px) {
   grid-template-columns: repeat(2, 1fr)
}
@media (min-width: 1150px) {
   grid-template-columns: repeat(4, 1fr); 
}
`
export const GridTwo = styled.div`
display: grid;
grid-gap: 1rem;
@media (min-width: 400px) {
   grid-template-columns: repeat(2, 1fr)
}
@media (min-width: 1150px) {
   grid-template-columns: repeat(2, 1fr); 
}
`

export default null;
