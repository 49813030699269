/* eslint-disable @typescript-eslint/camelcase */
/**
 * This is the key activation component
 * This component asks users to select a json file on their local machine,
 * and then read the content within that file, check the schema,
 * and then post the content to an API.
 *
 * After the request, do initialization (fetch data and update table)
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, {
  useRef, useState, useEffect,
} from 'react';
import { connect } from 'react-redux';
import Ajv from 'ajv';
import { Payload, KeyActivatePayload } from '../../../../utilities/types';
import Progress from '../../../../components/ProgressBar';
import { actions } from '../../../../stores/reducers/actions';
import { SOCKET_ACTION_TYPE } from '../../../../utilities/Functions/CONSTANTS';
import { useDialog } from '../../../../components/Dialog';
import { StyledLabel as Label} from '../../../../statics/styles/StyledComponents';
import { ItrazokeysChildren } from '../styles';
import styled from 'styled-components';

type Props = {
  initialize: Function;
  sendMessage: Function;
  clearResults: Function;
  keyActivateStatus: KeyActivatePayload;
};
const StyledLabel = styled( Label )`
    display: inline-block;
    margin: 0 10px 0px 10px;
    background: #383838;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    height:50px;
    font-size:18px;
    width:94%;
    text-transform:capitalize
    padding-top:10px;
    font-family: gotham bold;
`
const ActiveWrapper = styled.div`
input[type="file"]{
  display: none;
}

`
const schema = {
  type: 'object',
  properties: {
    activate: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          qr_code: {
            type: 'array',
            item: {
              type: 'string',
            },
          },
          product_info: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              brand: { type: 'string' },
              detail: {
                type: 'object',
              },
              product_date: { type: 'string' },
              product_code: { type: 'string' },
              product_image: {
                type: 'string',
                pattern: '^https://',
              },
            },
            required: [
              'name',
              'brand',
              'detail',
              'product_date',
              'product_code',
              'product_image',
            ],
          },
        },
        required: [
          'qr_code',
          'product_info',
        ],
      },
    },
    location: { type: 'string' },
  },
  required: ['activate', 'location'],
};

const KeyActivation = (props: Props) => {
  const myRef = useRef({} as any);
  const {
    initialize,
    sendMessage,
    clearResults,
    keyActivateStatus,
  } = props;
  const { status, status_code, result } = keyActivateStatus;
  const [percent, setPercent] = useState(0);
  const [complete, setComplete] = useState(false);
  const [resultMemo, setResultMemo] = useState('');
  const openDialog = useDialog();

  useEffect(() => {
    if (!complete) {
      setPercent(status.percent);

      if (status.done) {
        setComplete(true);
        initialize();
      }
    }
  }, [status, complete, initialize]);

  useEffect(() => {
    if (result) {
      if (result !== resultMemo) {
        setResultMemo(result);
        switch (status_code) {
          case 200: {
            openDialog('Result', result, "Successfully activated",true).then(() => {
              clearResults();
            });
            break;
          }
          case 500:
          default: {
            const { Res } = JSON.parse(result);
            const message = Res.map(
              (item: any) => `${item.uni_code} - ${item.body}`,
            ).join('\n');
            openDialog('Results', `The following keys failed:\n\n${message}`,"", true).then(() => {
              clearResults();
            });
          }
        }
      }
    } else {
      setResultMemo('');
    }
  }, [result, status_code, clearResults, openDialog, resultMemo]);

  const onChange = (fileEvent: any) => {
    setComplete(false);
    const file = fileEvent.target.files[0];
    const reader = new FileReader();
    reader.onload = (event: any) => {
    // The file's text will be printed here
      const content = event.target.result;
      const ajv = new Ajv({ allErrors: true });
      const validate = ajv.compile(schema);
      // JSON must be of certain format. schema validation
      try {
        const parseContent = JSON.parse(content);
        if (validate(parseContent)) {
          sendMessage(parseContent);
        } else {
          openDialog('Error', `json schema error\n${ajv.errorsText(validate.errors)}`,"", true);
        }
      } catch (e) {
        openDialog('', e.message, "",true);
      }
    };

    reader.readAsText(file);
    if (myRef.current) myRef.current.value = '';
  };

  return (
 
      <ActiveWrapper>  
        <StyledLabel htmlFor="file-input">Activate Keys</StyledLabel>
        <input
          ref={myRef}
          type="file"
          id="file-input"
          name="file"
          accept=".json"
          onChange={(event: any) => onChange(event)}
        />
      </ActiveWrapper>
     
  );
};


const mapStateToProps = (state: Payload) => ({
  keyActivateStatus: state.keyActivate,
});

const mapDispatchToProps = (dispatch: Function) => ({
  sendMessage:
 (data: any) => dispatch(actions.sendMessage(data, SOCKET_ACTION_TYPE.KEY_ACTIVATE)),
  clearResults:
  () => dispatch(actions.clearResults()),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeyActivation);
