/**
 * This component displays the details of an alert
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React from 'react';
import styled from 'styled-components';
import { alertDetails as dataItem } from '../../../../utilities/types';
import Table from '../../../../components/Table/SimpleTable';

const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  box-shadow: 0 0 1rem black;
  max-height: 80vh;
  background:#333333;
  overflow: scroll;
  width: 100%;
  p{
    margin: 0;
  }
`;
const NoDetails= styled.p`
  color:#fff;


`

export const detailContext = React.createContext<dataItem[]>([]);

const AlertDetails = () => (
  <detailContext.Consumer>
    {
      (originalList: dataItem[]) => (
        <Wrapper>
          <Table
            originalList={originalList}
            NoData={<NoDetails>No Details</NoDetails>}
          />
        </Wrapper>
      )
    }
  </detailContext.Consumer>
);

export default AlertDetails;
