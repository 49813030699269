/**
 * This is the socket module.
 * It handles websocket connection and communication
 *
 * It has a keep-socket-alive design
 * which checks socket connection with heartbeat communication with backend every 5 minutes
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import moment from 'moment';
import { actions } from '../reducers/actions';
import {
  Payload,
  AlertPayload,
  RealTimeDataPayload,
  KeyCreatePayload,
  KeyActivatePayload,
  RealTimeMap,
  alert,
} from '../../utilities/types';
import { FormatRealTimeMapData, FormatAlertData } from '../../utilities/Functions/FormatSocketData';
import { SESSION_STORAGE_KEYS, SOCKET_ACTION_TYPE, SOCKET_MESSAGE_TYPE } from '../../utilities/Functions/CONSTANTS';

const scanDataMemory: RealTimeDataPayload = {
  newData: [],
  oldData: [],
};

const alertDataMemory: AlertPayload = {
  read: [],
  unread: [],
};

const getPayload = ( data: any ) => {
  const message = data.data;
  console.log( "Message=========>", message )
  const statusCode = data?.data?.status_code;
  const messageType = data.msg_type;

  // key creation dispatch
  const keyCreate: KeyCreatePayload = {
    status: {
      done: false,
      percent: 0,
    },
    result: '',
  };
  const keyActivate: KeyActivatePayload = {
    status: {
      done: false,
      percent: 0,
    },
    status_code: 200,
    result: '',
  };
  let scanDataPayload: RealTimeDataPayload = { ...scanDataMemory };
  let alertPayload: AlertPayload = { ...alertDataMemory };
  let scanStatistics: any = {}
  switch ( messageType ) {
    case SOCKET_MESSAGE_TYPE.CREATE_KEY: {
      keyCreate.status = message;
      keyCreate.result = '';
      break;
    }
    case SOCKET_MESSAGE_TYPE.ACTIVATE_KEY: {
      keyActivate.status = message;
      keyActivate.result = '';
      break;
    }
    case SOCKET_MESSAGE_TYPE.CREATE_KEY_RESULT: {
      keyCreate.result = message.body;
      break;
    }
    case SOCKET_MESSAGE_TYPE.ACTIVATE_KEY_RESULT: {
      keyActivate.result = message.body;
      keyActivate.status_code = statusCode;
      break;
    }
    default: {
      if ( message ) {
        // scan data dispatch
        if ( message.scan_msg ) {
          const scanData = message.scan_msg.scans;
          if ( Array.isArray( scanData ) ) {
            scanDataMemory.oldData = scanDataMemory.oldData.concat( scanDataMemory.newData );
            // mark old data
            for ( let i = 0; i < scanDataMemory.oldData.length; i += 1 ) {
              scanDataMemory.oldData[i].newScan = false;
            }
            scanDataMemory.newData = scanData.map( ( item: any ): RealTimeMap => {
              if ( scanDataMemory.oldData.filter(
                ( oldItem: any ) => oldItem.id === item.scan_event_id,
              ).length > 0 ) {
                return null as unknown as RealTimeMap;
              } // make sure record not repeat
              // format and mark new record
              return FormatRealTimeMapData( item, true );
            } ).filter( ( item: any ) => item !== null ); // make sure no null value
          }
        }
        scanDataPayload = { ...scanDataMemory };

        // alert dispatch
        if ( message.alert_msg ) {
          const { read, unread } = message.alert_msg;
          let ReadFormatted = [];
          if ( read ) {
            ReadFormatted = read.map( FormatAlertData );
          }
          let UnreadFormatted = [];
          if ( unread ) {
            UnreadFormatted = unread.map( FormatAlertData );
          }

          // add new unread
          alertDataMemory.unread = alertDataMemory.unread.concat( UnreadFormatted );
          // update read
          // alertDataMemory.read = alertDataMemory.read.concat(ReadFormatted);

          // delete repeat alerts from unread
          {
            const newAlertIDs: string[] = [];
            alertDataMemory.unread = alertDataMemory.unread.filter(
              ( item: alert ) => {
                for ( let i = 0; i < newAlertIDs.length; i += 1 ) {
                  if ( item.alert_id === newAlertIDs[i] ) return false;
                }
                newAlertIDs.push( item.alert_id );
                return true;
              },
            );
          }

          // delete alerts that has been read
          {
            const newKeys = ReadFormatted.map( ( item: alert ): string => item.key );
            alertDataMemory.unread = alertDataMemory.unread.filter(
              ( item: alert ) => {
                for ( let i = 0; i < newKeys.length; i += 1 ) {
                  if ( item.key === newKeys[i] ) return false;
                }
                return true;
              },
            );
          }
        }
        alertPayload = { ...alertDataMemory };
        const idToken = JSON.parse( `${sessionStorage.getItem( SESSION_STORAGE_KEYS.IDTOKEN )}` );
        let payload = idToken.payload;
        let organizations = Array.isArray( payload['cognito:groups'] ) ? payload['cognito:groups'][0] : null;
        if ( organizations == "thechosenones" ) {
          if ( message.scan_stats && message.scan_stats.scan_detail ) {
            scanStatistics = {
              newScan: message.scan_stats.new_scan,
              totalScan: message.scan_stats.total_scan,
              stoneFoods: message.scan_stats.scan_detail.find( ( rec: any ) => rec.name == "Stone Fruit" ).total_scan,
            }

          }
         } else {
            if ( message.scan_stats && message.scan_stats.scan_detail ) {
              scanStatistics = {
                newScan: message.scan_stats.new_scan,
                totalScan: message.scan_stats.total_scan,
                shepardScans: message.scan_stats.scan_detail.find( ( rec: any ) => rec.name == "Shepard Avocado" ).total_scan,
                hassScans: message.scan_stats.scan_detail.find( ( rec: any ) => rec.name == "Hass Avocado" ).total_scan,
                NNSWHass: message.scan_stats.scan_detail.find( ( rec: any ) => rec.name == "NNSW Hass" ).total_scan,
                LFBanana: message.scan_stats.scan_detail.find( ( rec: any ) => rec.name == "Lady Finger Banana" ).total_scan,

              }

            }
          }
      }
    }
  }

  const payload: Payload = {
    alerts: alertPayload,
    realtimeData: scanDataPayload,
    keyCreate,
    keyActivate,
    scanStatistics
  };
  return payload;
};

const uri = `${process.env.REACT_APP_WEBSOCKET_URI}`;

const Socket: { [propname: string]: any } = {};

Socket.initialize = function initialize( emitter: Function ) {
  clearInterval( Socket.timer );
  Socket.lastHeartBeat = new Date().getTime();
  Socket.interval = 300000;
  Socket.overtime = 306000;
  console.log( "Last Heartbeat", Socket.lastHeartBeat )
  const tokenStorage = JSON.parse( `${sessionStorage.getItem( SESSION_STORAGE_KEYS.IDTOKEN )}` );
  if ( tokenStorage ) {
    const { jwtToken } = tokenStorage;
    Socket.socket = new WebSocket( `${uri}?Auth=${jwtToken}` );
  }

  Socket.socket.onopen = () => {
    console.log( 'OPEN' );
    const handshakeMessage = {
      action: SOCKET_ACTION_TYPE.INITIAL,
      data: {
        start: moment().startOf( 'day' ).unix(),
        end: moment().unix(),
      },
    };
    Socket.socket.send( JSON.stringify( handshakeMessage ) );
  };

  Socket.socket.onmessage = ( e: any ) => {
    const data = JSON.parse( e.data );
    console.log( 'Message: ', data );
    // console.log(data);
    // console.log(scanDataMemory);

    // heartbeat check
    if ( data.status_code === 200 ) {
      console.log( 'CONNECTION VALID' );
      // update heartbeat
      Socket.lastHeartBeat = new Date().getTime();
      return null;
    }

    // valid message
    const payload = getPayload( data );

    console.log( payload, "Payload", data )
    return emitter( actions.setData( payload ) );
  };

  Socket.socket.onerror = () => {
    console.log( 'SOCKET ERROR' );
  };

  Socket.socket.onclose = () => {
    console.log( 'CHANNEL CLOSED' );
    console.log( 'Trying to rebuild socket connection...' );
  };

  Socket.timer = setInterval( () => {
    const heartBeatMessage = {
      action: SOCKET_ACTION_TYPE.HEART_BEAT,
      data: '{}',
    };
    Socket.socket.send( JSON.stringify( heartBeatMessage ) );
    if ( ( new Date().getTime() - Socket.lastHeartBeat ) > Socket.overtime ) {
      console.log( 'CONNECTION LOST' );
      console.log( 'Trying to rebuild socket connection...' );
      // close the socket property to make sure next connection has INIT data
      Socket.socket.close();
      Socket.initialize( emitter );
    }
  }, Socket.interval );

  // function to be called when call channel.close()
  const unsubscribe = () => Socket.socket.close();
  return unsubscribe;
};

export default Socket;
