/**
 * The alerts page
 * This page receives saga alert messages and displays them
 * It will also send HTTP request to fetch alert details when user clicks
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 *
 * @update 8 Jan 2020 'updated details layout'
 */

import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import Table from '../../../components/Table';
import { actions } from '../../../stores/reducers/actions';
import { SOCKET_ACTION_TYPE } from '../../../utilities/Functions/CONSTANTS';
import {
  alert as AlertType,
  alerts as AlertsType,
  alertDetails,
  clearAlertsDataType,
  singleRecord,
} from '../../../utilities/types';
import AlertDetails, { detailContext } from './Details';
import Loading from '../../../components/Loading';
import {
  PageWrapper,
  WhiteButton as MyButton,
  TableButton,
  Header,
  Body,
  Grid,
} from '../../../statics/styles/StyledComponents';
import Selection from '../../../components/Selection';
import { useDialog } from '../../../components/Dialog';
import Statistics from '../../../components/Statistics';
import SearchBox from '../../../components/SearchBox';
import diversion from '../../../statics/images/diversion.svg'
import counter from '../../../statics/images/counter.svg'
import valueAlert from '../../../statics/images/valueAlert.svg'
import failureAlert from '../../../statics/images/failureAlert.svg'
import { isMobile } from 'react-device-detect';


type Props = {
  alerts: AlertsType;
  sendMessage: Function;
  writable?: boolean;
};

type myTableListType = {
  key: string;
  itrazoKey: string;
  ipAddress: string;
  alertType: string;
  time: string;
  details: any;
};

const StyledButton = styled( MyButton )`
  display: inline-block;
  margin: 0 1.2rem 0.5rem 0;
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height:50px;
  float:right
  font-size:18px;
  text-transform:Capitalize;
  font-size: 18px;
  line-height: 17px;
  font-family:gotham book;
`;

const SearchContainer = styled.div`
    margin: 10px 0px 10px 0px
`

const list = [{
  key: 'alerts',
  label: 'Alerts',
}];

const Alerts = ( props: Props ) => {
  const openDialog = useDialog();

  const { alerts, sendMessage, writable } = props;
  const { unread } = alerts;
  const [loading, setLoading] = useState<boolean>( false );
  const [listToClear, setListToClear] = useState<singleRecord[]>( [] );
  const [tableDisplay, setTableDisplay] = useState<any>();
  const [detailsData, setDetailsData] = useState<alertDetails[]>( [] );
  const CardsData = [
    {
      bgOne: "#2ABAD3",
      bgTwo: "#3CEAA3",
      icons: diversion,
      title: "Diversion Alert",
      value: 0,
    },
    {
      bgOne: "#6B8DEC",
      bgTwo: "#49C4F2",
      icons: counter,
      title: "Counterfeit Alert",
      value: 0,
    },
    {
      bgOne: "#EE931E",
      bgTwo: "#FBD224",
      icons: valueAlert,
      title: "Multi-Location Alert",
      value: 0
    },
    {
      bgOne: "#E2382E",
      bgTwo: "#EF7F48",
      icons: failureAlert,
      title: "ADI Comms Failure Alert",
      value: 0
    },
  ]
  const clearList = () => {
    if ( listToClear.length > 0 ) {
      openDialog( 'Warning', 'Alerts will be permanently deleted.' ).then(
        () => {
          sendMessage( listToClear.map( ( item: singleRecord ): clearAlertsDataType => {
            const myItem = item as unknown as myTableListType;
            return {
              alert_id: myItem.key,
              uni_code: myItem.itrazoKey,
            };
          } ) );
        },
      );
    }
  };

  // config detail display
  const showDetails = useCallback(
    ( theKey: string ) => {
      setDetailsData( [] );
      unread.find( ( element: AlertType ) => {
        const { key, url, itrazoKey } = element;
        if ( key === theKey ) {
          setLoading( true );
          axios.get( url ).then(
            ( response ) => {
              const { data } = response;
              const { Res } = data;
              if ( Array.isArray( Res ) ) {
                const newDetailsData = Res.map(
                  ( item: any ): alertDetails => (
                    {
                      key: item.scan_event_id,
                      ipAddress: item.ip_address,
                      productId: itrazoKey,
                      latitude: item.lat === 1000 ? item.ip_lat : item.lat,
                      longitude: item.lng === 1000 ? item.ip_lng : item.lng,
                      gpsAllow: item.lng !== 1000 ? 'Allowed' : 'Not Allowed',
                      city: item.city,
                      suburb: item.suburb,
                      state: item.state,
                      country: item.country,
                      time: moment( item.full_date ).format( 'D MMM YYYY HH[:]mm[:]ss' ),
                    }
                  ),
                );
                setDetailsData( newDetailsData );
              }
            },
          ).catch( ( e ) => {
            console.log( e );
          } ).finally( () => {
            setLoading( false );
          } );
          return true;
        }
        return false;
      } );
    }, [unread],
  );

  // manage display
  const prepareTable = useCallback( () => {
    if ( !unread ) return;
    const originalList = [...unread].map( ( alert: AlertType ): myTableListType => {
      const {
        key, itrazoKey, time, alertType, ipAddress,
      } = alert;
      return (
        {
          key,
          itrazoKey,
          ipAddress,
          alertType,
          time,
          details: (
            <TableButton
              type="button"
              onClick={() => {
                // showDetails(url, itrazoKey);
              }}
            >
              Read More
            </TableButton>
          ),
        }
      );
    } );

    if ( writable ) {
      setTableDisplay(
        <Table
          originalList={originalList.length ? originalList : [{
            key: "",
            itrazoKey: "",
            ipAddress: "",
            alertType: "",
            time: "",
            details: ""
          }]}
          NoData={<p>No Alert</p>}
          onSelect={
            ( listSelected: singleRecord[] ) => {
              setListToClear( listSelected );
            }
          }
          collapse={
            {
              onSelect: ( key: string ) => {
                showDetails( key );
              },
              details: <AlertDetails />,
            }
          }
        />,
      );
    } else {
      setTableDisplay(
        <Table
          originalList={originalList}
          NoData={<p>No Alert</p>}
          collapse={
            {
              onSelect: ( key: string ) => {
                showDetails( key );
              },
              details: <AlertDetails />,
            }
          }
        />,
      );
    }
  }, [unread, writable, setTableDisplay, showDetails] );

  useEffect( prepareTable, [unread] );

  return (
    <PageWrapper>
      <Header>
        <Selection
          dropdown={isMobile}
          list={list}
          upperCase
        />
      </Header>
      <Body>
        <Loading show={loading} />
        <Grid>

          {CardsData.map( rec => <Statistics
            bgOne={rec.bgOne}
            bgTwo={rec.bgTwo}
            icons={rec.icons}
            title={rec.title}
            value={rec.value}
          /> )}
        </Grid>
        <SearchContainer >
          <SearchBox
            placeHolder="Search by Alert Type"
          // onChange={()=>{

          // }}

          />
          {
            writable
              ? (
                isMobile ?<StyledButton
                type="button"
                style={{marginTop:"5px"}}
                onClick={() => {
                  clearList();
                }}
              >
                Clear
              </StyledButton>  :<StyledButton
                  type="button"
                  onClick={() => {
                    clearList();
                  }}
                >
                  Clear
                </StyledButton> 
              )
              : null
          }
        </SearchContainer>

        <detailContext.Provider value={detailsData}>
          {tableDisplay}
        </detailContext.Provider>
      </Body>
    </PageWrapper>
  );
};
const mapStateToProps = ( state: any ) => ( {
  alerts: state.alerts,
} );

const mapDispatchToProps = ( dispatch: Function ) => ( {
  sendMessage:
    ( data: clearAlertsDataType[] ) => dispatch( actions.sendMessage( data, SOCKET_ACTION_TYPE.ALERT ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( Alerts );
