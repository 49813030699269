/**
 * This is the log out button. It calls the outest signout function
 *
 * @author Sanaullah
 * @version 2.0.0
 * @Date 11 Dec 2020
 */
import React from "react";
import styled from "styled-components";
import defaultImage from '../../../statics/images/avatar.png'
const Image = styled.img`
  display: block;
  border-radius: 50%;
  width: 100%;
  height: auto;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #ddd;
  padding: "2px";
  width: 24px;
  height: 24px;
  background-color: white;
  display:inline;
`;
type Props = {
  imageUrl?: string;
  username?: string;
  size?: string;
};

const Avatar = ( props: Props ) => {
  const { imageUrl, username, size } = props
  return <Wrapper >
    <Image src={imageUrl ? imageUrl : defaultImage} alt={username} />
  </Wrapper>
};

Avatar.defaultProps = {
  size: "md"
};

export default Avatar;