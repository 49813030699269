/**
 * Entrance of the program
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, Reducer } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import App from './containers/App';
import reducer from './stores/reducers';
import rootSaga from './stores/sagas';

import "./styles/swift.css";
import "./styles/hover-min.css";
import "./styles/main.css";
import "./styles/themes/theme-default.css";

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(reducer as Reducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}><App /></Provider>,
  document.getElementById('root'),
);

console.log("commit: 9a54a64");

export default null;
