/**
 * compare functions (rules) for array sorting
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import {
  RealTimeMap,
  clearAlertsDataType,
  singleRecord,
  eventHistoryItem,
} from '../types';

/* rules for sorting scan data */

export const compareScanData = (a: RealTimeMap, b: RealTimeMap) => {
  if (a.id > b.id) return 1;
  if (a.id === b.id) return 0;
  return -1;
};

/* rules for sorting the list of alerts to clear */

export const
  compareClearListData = (a: clearAlertsDataType, b: clearAlertsDataType) => {
    const A = a.alert_id;
    const B = b.alert_id;
    if (A > B) return 1;
    if (A === B) return 0;
    return -1;
  };

/* rules for sorting normal table data */

export const sortList = (
  originalList: singleRecord[],
  sorter: string,
  ascendent: boolean,
): singleRecord[] => {
  const newList = originalList.sort((a: singleRecord, b: singleRecord) => {
    const larger = ascendent ? 1 : -1;
    const equal = 0;
    const less = -larger;
    if (a[sorter] > b[sorter]) return larger;
    if (a[sorter] === b[sorter]) return equal;
    return less;
  });
  return newList;
};

export const
  compareEventHistoryItems = (a: eventHistoryItem, b: eventHistoryItem) => {
    const A = a.time;
    const B = b.time;
    if (A > B) return -1;
    if (A === B) return 0;
    return 1;
  };

export const
  compareScanTime = (a: RealTimeMap, b: RealTimeMap) => {
    const A = a.time;
    const B = b.time;
    if (A > B) return -1;
    if (A === B) return 0;
    return 1;
  };

export default null;
