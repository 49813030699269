/**
 * Action types
 *
 * NOTE: Types start with "SAGA" will fire a saga,
 * while others will directly dispatch a redux action
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

export const types = {
  SAGA_OPEN_SOCKET: 'SAGA_OPEN_SOCKET',
  SAGA_SEND_MESSAGE: 'SAGA_SEND_MESSAGE',
  SAGA_CLOSE_SOCKET: 'SAGA_CLOSE_SOCKET',
  // GET_ALERTS: 'GET_ALERTS',
  // SOCKET_ERROR: "SOCKET_ERROR",
  // GET_REALTIME_DATA: 'GET_REALTIME_DATA',
  SET_DATA: 'SET_DATA',
  CLEAR_RESULTS: 'CLEAR_RESULTS',
};

export default null;
