/**
 * This is the log out button. It calls the outest signout function
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React from 'react';
import { LogoutOutline, LoginOutline } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import { Button } from '../../../statics/styles/StyledComponents';
import logout from '../../../statics/images/logout.svg'

type Props = {
  desktop?: boolean;
};

export const SignOutContext = React.createContext( () => { } );

const LogOut = ( props: Props ) => {
  const { desktop } = props;
  return (
    <SignOutContext.Consumer>
      {( signOut: Function ) => (
        <Button
          role="button"
          onClick={() => { signOut(); }}
        >
          {desktop ? (
            <div>
             <img src={logout} alt="logout"/>
            </div>
          )
            : (
              <div>
                <AntdIcon type={LogoutOutline} />
              </div>
            )}
        </Button>
      )}
    </SignOutContext.Consumer>
  );
};

export default LogOut;
