/**
 * This is the sort button of a table.
 * It sort the table with a sorter assigned
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React from 'react';
import AntdIcon from '@ant-design/icons-react';
import { CaretUpFill, CaretDownFill } from '@ant-design/icons';
import styled from 'styled-components';
import { singleRecord, OnListUpdateInterface } from '../../../utilities/types';
import { sortList } from '../../../utilities/Functions/SortFunctions';
import { FormatTableHeader } from '../../../utilities/Functions/FormatWords';
import { Button as MyButton } from '../../../statics/styles/StyledComponents';

type Props = {
  originalList: singleRecord[];
  sorter: string;
  onUpdate: OnListUpdateInterface;
};

const JustifiedDiv = styled.div`
  ${(props) => {
    if (props.theme.mobile) {
      return `
        span {
          display: block;
        }
      `;
    }
    return `
      display: flex;
      justify-content: space-between;
    `;
  }}
`;

const Button = styled(MyButton)`
  padding: 0 1rem;
  height: 10px;

  svg{
    margin-top: -2px;
  }
`;

const TableSortButton = (props: Props) => {
  const {
    originalList,
    sorter,
    onUpdate,
  } = props;
  return (
    <JustifiedDiv>
      <span>
        {FormatTableHeader(sorter)}
      </span>
      {/* <span>
        <Button
          type="button"
          className="up"
          onClick={() => {
            onUpdate(sortList(originalList, sorter, true));
          }}
        >
          <AntdIcon type={CaretUpFill} />
        </Button>
        <Button
          type="button"
          className="down"
          onClick={() => {
            onUpdate(sortList(originalList, sorter, false));
          }}
        >
          <AntdIcon type={CaretDownFill} />
        </Button>
      </span> */}
    </JustifiedDiv>
  );
};

export default TableSortButton;
