import styled from 'styled-components';
import { Body as MyBody } from '../../../statics/styles/StyledComponents';

export const ScanDetails = styled.div`
position: absolute;
text-align: left;
left: 0;
bottom: 0;
z-index: 10000;
color: white;
background: #545454;
display: none;
width: 200px;
padding: 1rem 1rem 0;
border-radius: 5px;
box-shadow:
0 2.8px 2.2px rgba(120, 0, 0, 0.034),
0 6.7px 5.3px rgba(0, 0, 0, 0.048),
0 12.5px 10px rgba(0, 0, 0, 0.06),
0 22.3px 17.9px rgba(0, 0, 0, 0.072),
0 41.8px 33.4px rgba(0, 0, 0, 0.086),
0 100px 80px rgba(0, 0, 0, 0.12)
p{
  font-weight: 300;

  span{
    display: block;
    font-weight: 500;
    padding-bottom: 0.2rem;

    &:after{
      content: ':';
    }
  }

  &.location-type{
    font-weight: bold;
  }
}
`;

export const Cluster = styled.div`
width: 40px;
height: 40px;
position: absolute;
left: -20px;
bottom: -20px;
border-radius: 50%;
background-color: white;
text-align: center;
color: #333;
font-size: 1.16rem;
font-weight: bold;
display: flex;
align-items: center;
justify-content: center;
border: 5px solid red;
`;

export const Dot = styled.div`
  position: absolute;
  overflow: visible;
  width: 1rem;
  height: 1rem;
  left: -0.5rem;
  bottom: -0.5rem;

  &:hover{
    *{
        display: block;
    }
  }

  &:before{
    content:' ';
    position: absolute;
    pointer-events: none;
    z-index:2;
    left:0;
    top:0;
    width:0;
    height:0;
    border-radius: 50%;
  }

  &:after {
    content:' ';
    position: absolute;
    pointer-events: none;
    z-index:1;
    background-color: #ff4200;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0,0,0, 0.3) inset;
    animation-name:'ripple';
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  @keyframes ripple {
    0% {
      left:0.5rem;
      top:0.5rem;
      opcity:1;
      width:0;
      height:0;
    }   
    70% {
    left:-1.5rem;
    top:-1.5rem;
    opacity: 0.7;
    width:4rem;
    height:4rem;
    }

    100% {
      left:-2rem;
      top:-2rem;
      opacity: 0;
      width:5rem;
      height:5rem;
    }
  }
`;

export const DotMarker = styled.div`
position: absolute;
overflow: visible;
width: 30px;
height: 30px;
left: -15px;
bottom: 0;
color: ${({ newScan }: {newScan?: boolean}) => {
    if (newScan) return 'yellow';
    return 'green';
  }};

svg{
    height: 30px !important;
    width: 30px !important;
}

&:hover{
    *{
        display: block;
    }
}
`;

export const Summary = styled.div`
width: 60px;
height: 60px;
position: absolute;
left: -30px;
bottom: 0;

p{
    position: absolute;
    z-index: 100;
    color: white;
    width: 60px;
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    line-height: 40px;
    text-align: center;
}

.pin{
    width: 60px;
    height: 60px;
    border-radius: 50% 50% 50% 0;
    background:  linear-gradient(rgb(219, 93, 175), rgb(190, 13, 87));
    // background:  linear-gradient(rgb(255, 0, 0), rgb(216, 39, 39), rgb(255, 131, 131));
    transform: rotate(-45deg);
    position: absolute;
    bottom: 10px;

    &:after{
        content: '';
        width: 48px;
        height: 48px;
        background: rgb(24, 22, 5);
        position: absolute;
        top: 6px;
        left: 6px;
        border-radius: 50%;
    }
}
`;

export const Body = styled(MyBody)`
  display: flex;
  flex-direction: column;
`;

export const MapHeader = styled.div`
  flex: 3rem;
  height: 100%;
  display: ${(props) => {
    if (props.theme.mobile) return 'block';
    return 'flex';
  }};
  justify-content: space-between;
`;

export const MapBody = styled.div`
  flex: auto;
  height: 100%;
`;

export const ControlPanel = styled.div`
  &>*{
    display: inline-block;
  }
`;

export const Instruction = styled.div`
  padding: 0.5rem;

  &>*{
    display: inline-block;
  }
`;

export const MarkerInstruction = styled.div`
  position: relative;
  margin-left: 0.5rem;
  
  span{
    color: white;
    padding: 0 0.5rem;
  }

  svg{
    width: 1rem;
    height: 1rem;
  }
`;

export const DotExample = styled.div`
display: inline-block;
width:1rem;
height:1rem;
background-color: rgba(255,66,0);
border-radius: 50%;
`;

export const Title = styled.p`
font-size: 1.2rem;
font-weight: 600;
color: ${(props) => props.theme.main};
margin: 0 0 1rem 0;
`;

export const Live = styled.span`
position: relative;

&::after{
  content: '';
  width: 1rem;
  height: 1rem;
  position: absolute;
  border-radius: 50%;
  margin-left: 1rem;
  margin-top:3px;
  background: lightgray;
  animation: ${({ realtime }: {realtime: boolean}) => {
    if (realtime) {
      return 'blink 1s infinite step-end';
    }
    return 'none';
  }};
}

@keyframes blink {
  0% {background-color: red;}
  50% {background-color: rgb(126, 126, 126);}
}
`;

export default null;
