/**
 * This is the ConsumerEngagement page
 * It shows scan summary, the latest scan, new Scans, and old scans
 *
 * NOTE: The map recenter only when data changes
 * (it will not recenter if data are then same
 * even if you change mode or selected a different range)
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import GoogleMap from 'google-map-react';
import SuperCluster, { AnyProps } from 'supercluster';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Feature, Point, BBox } from 'geojson';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import { FormatRealTimeMapData } from '../../../utilities/Functions/FormatSocketData';
import { compareScanData, compareScanTime } from '../../../utilities/Functions/SortFunctions';
import { ReactComponent as Marker } from '../../../statics/images/map-marker.svg';
import {
  RealTimeDataPayload,
  RealTimeMap,
  subItem,
  api,
} from '../../../utilities/types';
import Selection from '../../../components/Selection';
import DateRangePicker from '../../../components/DateRangePicker';
// import styles from '../../styles/Themes/GoogleMapStylesGray.json';
import styles from '../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import {
  ScanDetails,
  Cluster,
  Dot,
  DotMarker,
  Summary,
  MapHeader,
  MarkerInstruction,
  Instruction,
  ControlPanel,
  Title,
  Live,
  Body,
  MapBody,
} from './styles';
import {
  PageWrapper,
  WhiteButton as MyButton,
  Grid,
  GridTwo,
} from '../../../statics/styles/StyledComponents';
import countryData from './Countries';
import totalScan from '../../../statics/images/totalScans.svg'
import newScan from '../../../statics/images/newScan.svg'
import repeat from '../../../statics/images/repeat.svg'
import campion from '../../../statics/images/campion.svg'
import Statistics from '../../../components/Statistics';
import styled from 'styled-components';
import DateRange from '../../../components/DateRange';
import { isMobile } from 'react-device-detect';
import refresh from '../../../statics/images/refresh-icon.svg';
import { SESSION_STORAGE_KEYS } from '../../../utilities/Functions/CONSTANTS';


/* -------------------------Part one - core computing logic and display------------------------- */

type Props = {
  data: RealTimeDataPayload;
  mode: 'live' | 'history';
  latestScan: RealTimeMap | undefined;
};

type summary = {
  lat: number;
  lng: number;
  key: string;
  value: string;
};

type State = {
  data: RealTimeDataPayload;
  clusters: any[];
  clustersSummary: any[];
  zoom: number;
  bounds: BBox;
  center: {
    lat: number;
    lng: number;
  };
  mode: 'live' | 'history';
};

const initialData: RealTimeDataPayload = {
  newData: [],
  oldData: [],
};

const subpageList: subItem[] = [{
  key: 'scan-monitoring',
  label: 'Consumer Engagement',
}];


const WhiteButton = styled( MyButton )`
  background:#191919
  font-family: Gotham book;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
`
const GridDev = styled( Grid )`
@media (min-width: 1150px) {
  grid-template-columns: repeat(6, 1fr); 
}
`
const ShowHeader = styled.div`
  display:flex
  margin-top: ${( { margin }: { margin?: string } ) => {
    if ( margin ) return margin;
    return '-5px';
  }};
  

`
const Header = styled.div`
*{
  color: white;
}

&>p{
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
}
display:flex;
align-item:flex-end;
justify-content:flex-end
`;
const minimumZoom = 3;
const summaryZoomLevel = 4;

/* -----------------------memories--------------------------- */

let dataMemory: RealTimeDataPayload = {
  newData: [],
  oldData: [],
};

let summaryMemory: RealTimeMap[] = [];

// tools
const initial: any = {};
const getStatics = ( array: string[] ) => array.reduce( ( prev, next ) => {
  const object = { ...prev };
  object[next] = ( object[next] + 1 ) || 1;
  return object;
}, initial );

const showDetails = ( item: RealTimeMap ) => (
  <ScanDetails>
    <p className="location-type">{item.locationType}</p>
    <p>
      <span>IP</span>
      {item.ipAddress}
    </p>
    <p>
      <span>Longitude</span>
      {item.longitude}
    </p>
    <p>
      <span>Latitude</span>
      {item.latitude}
    </p>
    <p>
      <span>Details</span>
      {item.address}
    </p>
    <p>
      <span>Time</span>
      {item.time}
    </p>
  </ScanDetails>
);

const getGeoJSON = ( item: RealTimeMap ): Feature<Point, AnyProps> => ( {
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [item.longitude, item.latitude],
  },
  properties: {
    data: item,
  },
} );

// markers
const Clusters = ( { number }: any ) => <Cluster>{number}</Cluster>;
const PointsNew = ( { item }: any ) => (
  <DotMarker
    newScan
  >
    <Marker />
    {showDetails( item )}
  </DotMarker>
);
const PointsOld = ( { item }: any ) => (
  <DotMarker>
    <Marker />
    {showDetails( item )}
  </DotMarker>
);
const CountrySummary = ( { value }: any ) => (
  <Summary>
    <div className="pin" />
    <p>{value}</p>
  </Summary>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LatestScan = ( { item }: any ) => (
  <Dot>
    {item ? showDetails( item ) : null}
  </Dot>
);
const defaultCenter = { lat: -25.274398, lng: 133.775136 };

class ConsumerEngagementCore extends Component<Props, State> {
  static getDerivedStateFromProps( props: Props, state: State ) {
    const { data, mode } = props;
    // NOTE: mode update is neccessary for all branches

    /* -----------------------------------validate check----------------------------------- */
    if ( !data ) {
      return {
        mode,
      };
    }
    if ( !Array.isArray( data.newData ) || !Array.isArray( data.oldData ) ) {
      return {
        mode,
      };
    }

    /* -------------------------------------valid data------------------------------------ */
    const newDataSet = data.oldData.concat( data.newData );
    const oldDataSet = state.data.oldData.concat( state.data.newData );


    /* ------------------------data not change------------------------ */
    // in any case, if old data and new data are exactly the same, do nothing
    if (
      JSON.stringify( newDataSet.sort( compareScanData ) )
      === JSON.stringify( oldDataSet.sort( compareScanData ) ) ) {
      return {
        mode,
      };
    }

    /* -------------------------data changed------------------------- */

    // if data are different, we should update everything:
    //    firstly clear, and then generate new summarys, clusters, points again
    //    (but no need for clear clusters, as it is dynamically processed by superclusters.)

    /* ------------data changed in same mode------------ */

    // if data mode stay the same
    if ( mode === state.mode ) {
      // if data mode stays 'live':
      // no need to never clear clustersSummary,
      // as the scan data we cached in socket always increase
      // meaning that countries in the new comming data will always be more than or equal to
      // our current country record, so they will override all existing countries
      // without missing any one of them (Function updateOneCountrySummary)
      // so just update data in such case
      if ( mode === 'live' ) {
        return {
          data,
          // mode,
        };
      }

      // if data mode stay the same but is 'history'
      // the new data comming has no relationship with the old one
      // we cannot guarantee that the summary items of all countries will be overrided,
      // so we have to clear the clustersSummary.
      // With updated data, the program will generate a new clustersSummary again
      return {
        data,
        clustersSummary: [],
        // mode,
      };
    }


    /* ------------data changed in different mode------------ */
    // if data mode changed, clear everything and update data
    // so the program can calculate everything from beginning
    return {
      data,
      clustersSummary: [],
      // clusters: [], superclusters will take care of this
      mode,
    };
  }

  // cluster when distance between two points is less than 40 px
  superCluster = new SuperCluster( { radius: 40 } );

  Geocoder: any;

  constructor( props: Props ) {
    super( props );
    this.state = {
      data: { ...initialData },
      clusters: [],
      clustersSummary: [],
      zoom: minimumZoom,
      bounds: [-180, -85, 180, 85],
      center: defaultCenter,
      mode: 'live',


    };
  }

  componentDidMount(): void {
    const { data } = this.props;
    const { newData, oldData } = data;
    // console.log("data====d",data);

    if ( newData.length === 0 && oldData.length === 0 ) {
      // reload data for supercluster
      this.updatePoints( dataMemory );
      // update map center
      this.recenter( dataMemory, defaultCenter );
      // update state
      this.setState( {
        data: dataMemory,
      } );
    } else {
      this.updatePoints( data );
      // update map center
      this.recenter( data, defaultCenter );
      // update state
      this.setState( {
        data,
      } );
    }
  }

  componentDidUpdate( prevProps: Props, prevState: State ): void {
    // update
    // map should update only when data, and any two of zoom, bounds or center changes
    const { zoom, center, data } = this.state;
    // console.log("Complete State:",completeState,data);
    const prevZoom = prevState.zoom;
    const prevCenter = prevState.center;
    const prevData = prevState.data;

    // new data
    if ( data !== prevData ) {
      // update map center
      this.recenter( data, prevCenter );
      // update model
      this.updateSummary();
      this.updatePoints( data );
      this.updateClusters();
      return;
    }

    // new zoom
    if ( zoom !== prevZoom ) {
      this.updateSummary();
      this.updateClusters();
      return;
    }

    // new center
    if ( center !== prevCenter ) {
      this.updateSummary();
      this.updateClusters();
    }
  }

  componentWillUnmount() {
    summaryMemory = [];
  }

  updateOneCountrySummary = ( country: string, value: string, lat: number, lng: number ) => {
    this.setState( ( prevState: State ): any => {
      const { clustersSummary } = prevState;
      // when the country already exist
      // update that country's value
      if ( clustersSummary.find( ( element, i ) => {
        if ( element.key === country ) {
          // if (element.value !== value) {
          //   clustersSummary[i].value = value;
          // }
          clustersSummary[i].value = value;
          return true;
        }
        return false;
      } ) ) {
        return { clustersSummary };
      }

      // when the country is not recorded
      // add the country to our summary
      const newItem = {
        lat,
        lng,
        key: country,
        value,
      };
      clustersSummary.push( newItem );
      return { clustersSummary };
    } );
  };

  /* -------------------------------------tools------------------------------------- */
  getCountryCentroids = ( country: string, value: string ) => {
    // find the country's lat, lng in our database
    if ( countryData.find( ( element ) => {
      if ( element.country === country ) {
        this.updateOneCountrySummary(
          country,
          value,
          element.lat,
          element.lng,
        );
        return true;
      }
      return false;
    } ) ) {
      return;
    }

    // if the country does not exist in our data base, use Google's api
    if ( this.Geocoder ) {
      this.Geocoder.geocode(
        { address: country },
        ( results: any ) => {
          console.log( `${country}${value}`, results );
          if ( results && results[0] ) {
            const coords = results[0].geometry.location;
            this.updateOneCountrySummary( country, value, coords.lat(), coords.lng() );
          }
        },
      );
    } else {
      // loop until Geocoder is loaded
      const timer = setInterval( () => {
        if ( this.Geocoder ) {
          this.Geocoder.geocode(
            { address: country },
            ( results: any ) => {
              if ( results && results[0] ) {
                const coords = results[0].geometry.location;
                this.updateOneCountrySummary( country, value, coords.lat(), coords.lng() );
              }
            },
          );
          clearInterval( timer );
        }
      }, 1000 );
    }
  };

  // update center in only two scenarios - move map, or there is a new scan in new location
  // this is the second senario
  recenter = ( data: RealTimeDataPayload, prevCenter: { lat: number; lng: number } ) => {
    const doRecenter = () => {
      const { newData, oldData } = data;
      const newLength = newData.length;
      const oldLength = oldData.length;
      let RecenterData: RealTimeMap[] | null;
      if ( newLength > 0 ) RecenterData = newData;
      else if ( oldLength > 0 ) RecenterData = oldData;
      else RecenterData = null;

      if ( RecenterData ) {
        const { latitude, longitude } = RecenterData.sort( compareScanTime )[0];
        const { lat, lng } = prevCenter;
        if ( latitude !== lat || longitude !== lng ) {
          this.setState( {
            center: {
              lat: latitude,
              lng: longitude,
            },
          } );
        }
      }
    };
    if ( this.Geocoder ) {
      doRecenter();
    } else {
      // loop to update the new center until map is ready
      const timer = setInterval( () => {
        if ( this.Geocoder ) {
          doRecenter();
          clearInterval( timer );
        }
      }, 1000 );
    }
  };

  /* -------------------------------------model------------------------------------- */
  updateSummary = () => {
    const { data, clustersSummary } = this.state;
    const { newData, oldData } = data;

    // update summary
    const pointsSummary = newData.concat( oldData );

    // check memory, only run the following logic when new data is different from memory
    if (
      clustersSummary.length !== 0 // check only when it's not initialization
      && JSON.stringify( [...pointsSummary].sort( compareScanData ) )
      === JSON.stringify( [...summaryMemory].sort( compareScanData ) ) ) {
      return;
    }

    summaryMemory = [...pointsSummary];

    // generate new clusters
    const countries = pointsSummary.map( ( item: RealTimeMap ) => {
      // when there is no country, summarize points based on address
      if ( item.country ) return item.country;
      return item.address;
    } );

    const summary = getStatics( countries );
    // calculate percentage
    const sum = ( total: any, current: any ) => total + current;
    const totalNumber = Object.values( summary ).reduce( sum, 0 ) as number;
    const getPercentage = ( value: number ) => `${( Math.round( ( value / totalNumber ) * 10000 ) / 100 ).toFixed( 1 )}%`;
    const keys = Object.keys( summary );
    for ( let i = 0; i < keys.length; i += 1 ) {
      const key = keys[i];
      this.getCountryCentroids( key, getPercentage( summary[key] ) );
    }
  };

  updatePoints = ( data: RealTimeDataPayload ) => {
    const { newData, oldData } = data;
    // update points contained in cluster
    const pointsOld = oldData.map(
      ( item: RealTimeMap ) => getGeoJSON( item ),
    );
    const pointsNew = newData.map(
      ( item: RealTimeMap ) => getGeoJSON( item ),
    );
    const points = pointsOld.concat( pointsNew );
    this.superCluster.load( points );
  };

  updateClusters = () => {
    const { zoom, bounds } = this.state;
    // if ( zoom < summaryZoomLevel ) {
    //   this.setState( {
    //     clusters: [],
    //   } );
    //   return;
    // }
    const clusters = this.superCluster.getClusters( bounds, zoom );
    this.setState( {
      clusters,
    } );
  };

  /* -------------------------------------control------------------------------------- */
  onMapChange = ( item: any ): any => {
    const bounds: BBox = [
      item.bounds.sw.lng,
      item.bounds.sw.lat,
      item.bounds.ne.lng,
      item.bounds.ne.lat,
    ];
    const { zoom, center } = item;
    this.setState( {
      zoom,
      bounds,
      center,
    } );
  };

  /* -------------------------------------view------------------------------------- */
  displayClusters = () => {
    const { clusters } = this.state;
    const display = clusters.length === 0
      ? []
      : clusters.map( ( item ) => {
        if ( item.properties.cluster ) {
          return (
            <Clusters
              number={item.properties.point_count}
              lat={item.geometry.coordinates[1]}
              lng={item.geometry.coordinates[0]}
              key={`${JSON.stringify( item.geometry.coordinates )}`}
            />
          );
        }
        if ( item.properties.data.newScan ) {
          return (
            <PointsNew
              lat={item.geometry.coordinates[1]}
              lng={item.geometry.coordinates[0]}
              key={`${item.properties.data.id}new`}
              item={item.properties.data}
            />
          );
        }
        return (
          <PointsOld
            lat={item.geometry.coordinates[1]}
            lng={item.geometry.coordinates[0]}
            key={`${item.properties.data.id}old`}
            item={item.properties.data}
          />
        );
      } );
    return display;
  };

  displaySummary = () => {
    const { clustersSummary, zoom } = this.state;
    if ( zoom === minimumZoom ) {
      return clustersSummary.map( ( item: summary ) => (
        <CountrySummary
          lat={item.lat}
          lng={item.lng}
          key={item.key}
          value={item.value}
        />
      ) );
    }
    return [];
  };

  render() {
    const { center } = this.state;
    const { latestScan, mode } = this.props;
    return (
      <div className="height-65vh map-scrol" >
        <GoogleMap
          bootstrapURLKeys={{
            key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
            libraries: 'geometry,places',
          }}
          defaultCenter={defaultCenter}
          center={center}
          defaultZoom={minimumZoom}
          onGoogleApiLoaded={( { maps } ) => {
            try {
              this.Geocoder = new maps.Geocoder();
            } catch ( e ) {

            }

            return null;
          }}
          options={
            {
              minZoomOverride: true,
              minZoom: minimumZoom,
              styles,
            }
          }
          onChange={( item ) => this.onMapChange( item )}
        >
          {this.displayClusters()}
          {this.displaySummary()}
          {
            latestScan
              && mode === 'live'
              ? (
                <LatestScan
                  lat={latestScan.latitude}
                  lng={latestScan.longitude}
                  item={latestScan}
                />
              )
              : null
          }
        </GoogleMap>
      </div>
    );
  }
}

/* --------------------------Part two - layout------------------------- */

type PropsPage = {
  apis: api[];
  data: RealTimeDataPayload;
  scanStatistics?: any
};

const mapStateToProps = ( state: any ) => ( {
  data: state.realtimeData,
  scanStatistics: state.scanStatistics,
} );


const ConsumerEngagement = ( props: PropsPage ) => {
  const { apis, data, scanStatistics } = props;
  const [loading, setLoading] = useState( false );
  const [realtime, setRealtime] = useState( true );
  const [mode, setMode] = useState<'live' | 'history'>( 'live' );
  const [title, setTitle] = useState( 'LIVE' );
  const [dataHistory, setDataHistory] = useState<RealTimeDataPayload>( { ...initialData } );
  const [latestScan, setLatestScan] = useState<RealTimeMap>();
  const [organizations, setOrganizations] = useState( "" )
  const [completeState, setCompleteState] = useState( {} )
  const [refreshButton, setRefreshButton] = useState( false )

  const getHistoryData = ( a: number, b: number ) => {
    setLoading( true );
    const url = `${apis[0].url}?start=${a}&end=${b}`;
    axios.get( url ).then( ( response ) => {
      const { Res } = response.data;
      setDataHistory( {
        newData: [] as RealTimeMap[],
        oldData: Array.isArray( Res )
          ? Res.map( ( item: RealTimeMap ): RealTimeMap => FormatRealTimeMapData( item, false ) )
          : [] as RealTimeMap[],
      } );
    } ).catch( ( e ) => {
      console.log( e );
    } ).finally( () => {
      setLoading( false );
    } );
  };
  const CardsData = [
    {
      bgOne: "#2ABAD3",
      bgTwo: "#3CEAA3",
      icons: totalScan,
      title: "Total Scans",
      value: scanStatistics ? ( scanStatistics.totalScan ? scanStatistics.totalScan : 0 ) : 0,
    },
    {
      bgOne: "#6B8DEC",
      bgTwo: "#49C4F2",
      icons: newScan,
      title: "New Scans",
      value: scanStatistics ? ( scanStatistics.newScan ? scanStatistics.newScan : 0 ) : 0,
    },
    {
      bgOne: "#2FC27D",
      bgTwo: "#1AAC7A",
      icons: repeat,
      title: organizations == "costagroup" ? "Hass Scans" : "Repeat Scans",
      value: scanStatistics ? ( scanStatistics.hassScans ? scanStatistics.hassScans : 0 ) : 0
    },
    {
      bgOne: "#E2382E",
      bgTwo: "#EF7F48",
      icons: organizations == "costagroup" ? repeat : campion,
      title: organizations == "costagroup" ? "Shepard Scans" : "Campaign",
      value: scanStatistics ? ( scanStatistics.shepardScans ? scanStatistics.shepardScans : 0 ) : 0
    },
    {
      bgOne: "#d1764f",
      bgTwo: "#b0542c",
      icons: organizations == "costagroup" ? repeat : campion,
      title: organizations == "costagroup" ? "NNSW Hass" : "Campaign",
      value: scanStatistics ? ( scanStatistics.NNSWHass ? scanStatistics.NNSWHass : 0 ) : 0
    },
    {
      bgOne: "#EE931E",
      bgTwo: "#FBD224",
      icons: organizations == "costagroup" ? repeat : campion,
      title: organizations == "costagroup" ? "LF Banana" : "Campaign",
      value: scanStatistics ? ( scanStatistics.LFBanana ? scanStatistics.LFBanana : 0 ) : 0
    },
  ]

  const CardsDataTCO = [
    {
      bgOne: "#2ABAD3",
      bgTwo: "#3CEAA3",
      icons: totalScan,
      title: "Total Scans",
      value: scanStatistics ? ( scanStatistics.totalScan ? scanStatistics.totalScan : 0 ) : 0,
    },
    {
      bgOne: "#6B8DEC",
      bgTwo: "#49C4F2",
      icons: newScan,
      title: "New Scans",
      value: scanStatistics ? ( scanStatistics.newScan ? scanStatistics.newScan : 0 ) : 0,
    },
    {
      bgOne: "#2FC27D",
      bgTwo: "#1AAC7A",
      icons: repeat,
      title: "Stone Foods Scans",
      value: scanStatistics ? ( scanStatistics.stoneFoods ? scanStatistics.stoneFoods : 0 ) : 0
    },



  ]



  // when unmount the page, store data
  useEffect( () => {
    if ( data ) {
      const { newData } = data;
      if ( newData && newData[0] ) {
        setLatestScan( newData.sort( compareScanTime )[0] );
      }
    }
    return () => {
      dataMemory = data;
    };
  }, [data] );
  // useEffect(()=>{
  //   setCompleteState(scanStatistics)
  // },[scanStatistics])
  useEffect( () => {
    const idToken = JSON.parse( `${sessionStorage.getItem( SESSION_STORAGE_KEYS.IDTOKEN )}` );
    let payload = idToken ? idToken.payload : [];
    let organization = Array.isArray( payload['cognito:groups'] ) ? payload['cognito:groups'][0] : null;
    setOrganizations( organization )
  } )
  return (
    <PageWrapper>
      <Loading show={loading} />

      {isMobile ? <Header>
        {/* <GridTwo>  */}
        {/* <Selection
          list={subpageList}
          upperCase
        /> */}

        <ShowHeader >
          <DateRange
            onApply={( a: any, b: any ) => {
              getHistoryData( a, b );
              setRealtime( false );
              setMode( 'history' );
            }}

            refereshClicked={() => {
              setRefreshButton( false )
            }}
            refreshButton={refreshButton}
          />

          <span onClick={() => {
            setRefreshButton( true )
          }}
            style={{ marginRight: "10px", marginTop: "13px", cursor: "pointer" }}><img src={refresh} />
          </span>
        </ShowHeader>
        {/* </GridTwo> */}
      </Header>
        : <Header>
          <Selection
            list={subpageList}
            upperCase
          /><ShowHeader margin="40">
            <ShowHeader margin="40px">
              <DateRange
                onApply={( a: any, b: any ) => {
                  getHistoryData( a, b );
                  setRealtime( false );
                  setMode( 'history' );
                }}
                refereshClicked={() => {
                  setRefreshButton( false )
                }}
                refreshButton={refreshButton}
              />

              <span onClick={() => {
                setRefreshButton( true )
                setRealtime( true );
              }}
                style={{ marginRight: "10px", marginTop: "15px", cursor: "pointer" }}><img src={refresh} />
              </span>
            </ShowHeader>
          </ShowHeader>

        </Header>}

      <Body>
        <GridDev>
        {organizations === "thechosenones" ? CardsDataTCO.map( rec => <Statistics
            bgOne={rec.bgOne}
            bgTwo={rec.bgTwo}
            icons={rec.icons}
            title={rec.title}
            value={rec.value}
          /> ) : CardsData.map( rec => <Statistics
            bgOne={rec.bgOne}
            bgTwo={rec.bgTwo}
            icons={rec.icons}
            title={rec.title}
            value={rec.value}
          /> )}
        </GridDev>
        <MapHeader>
          <ControlPanel>
            <WhiteButton
              type="button"
              onClick={() => {
                setRealtime( true );
                setTitle( 'LIVE' );
                setMode( 'live' );
              }}
            >
              <Live
                realtime={realtime}
              >
                LIVE
              </Live>
            </WhiteButton>

            {/* <Title>{title}</Title> */}
          </ControlPanel>

        </MapHeader>
        {/* <Left className="col-xl-12 pad-0 height-65vh margin-10" style={{ width: "98%" }}> */}
        <MapBody>
          <ConsumerEngagementCore
            data={
              realtime ? data : dataHistory
            }
            mode={mode}
            latestScan={latestScan}
          />
        </MapBody>
        <Instruction>
          <MarkerInstruction>
            <Marker style={{ color: 'red' }} />
            <span>Last Scan</span>
          </MarkerInstruction>
          <MarkerInstruction>
            <Marker style={{ color: 'green' }} />
            <span>Old Scan</span>
          </MarkerInstruction>
        </Instruction>
      </Body>
    </PageWrapper>
  );
};

export default connect( mapStateToProps )( ConsumerEngagement );
