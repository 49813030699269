/**
 * The Key management page with two mode:
 * - readonly
 * - writable: request & activate keys
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Loading from '../../../components/Loading';
import Selection from '../../../components/Selection';
import KeyRequest from './KeyRequest';
import KeyActivation from './KeyActivation';
import KeyTable from './KeyTable';
import { api } from '../../../utilities/types';
import {
  PageWrapper,
  Header,
  Body,
  TableWrapper,
  WhiteButton as MyButton,
  Input as NumberInput,
  Grid,
} from '../../../statics/styles/StyledComponents';
import { ItrazokeysChildrenWrapper } from './styles';
import Statistics from '../../../components/Statistics';
import totalOrders from '../../../statics/images/totalOrders.svg'
import activeOn from '../../../statics/images/activeOn.svg'
import damaged from '../../../statics/images/damaged.svg'
import inactive from '../../../statics/images/inActive.svg'
import styled from 'styled-components'
import SearchBox from '../../../components/SearchBox';
import Table from '../../../components/Table';

type PropsItrazokeys = {
  apis: api[];
  writable?: boolean;
};
const SearchContainer = styled.div`
    margin: 10px 0px 10px 0px
`
const StyledButton = styled( MyButton )`
  display: inline-block;
  margin: 0 10px 0px 10px;
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height:50px;
  float:right
  font-size:18px;
  width:100%;
  text-transform:capitalize

`;
const StyledInput = styled( NumberInput )`
  display: inline-block;
  margin: 0 1.2rem 1.5rem 1.5rem;
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height:50px;
  font-size:18px;
`

const Itrazokeys = ( props: PropsItrazokeys ) => {
  const { apis, writable } = props;
  const [listingApi, downloadApi] = apis;
  const [totalNumber, setTotalNumber] = useState( 0 );
  const [activeNumber, setActiveNumber] = useState( 0 );
  const [inactiveNumber, setInactiveNumber] = useState( 0 );
  const [deviceData,setDeviceData] = useState([
    {
      key:"",
      itrazokey:"",
      adiType:"",
      Location:"",
      Alters:""
    }
  ]);
  // const [invalidNumber, setInvalidNumber] = useState(0);
  const [loading, setLoading] = useState( false );
  const CardsData = [
    {
      bgOne: "#2ABAD3",
      bgTwo: "#3CEAA3",
      icons: totalOrders,
      title: "Total Ordered",
      value: 2,
    },
    {
      bgOne: "#6B8DEC",
      bgTwo: "#49C4F2",
      icons: activeOn,
      title: "Active on Products",
      value: 2,
    },
    {
      bgOne: "#EE931E",
      bgTwo: "#FBD224",
      icons: damaged,
      title: "Damaged",
      value: 0
    },
    {
      bgOne: "#E2382E",
      bgTwo: "#EF7F48",
      icons: inactive,
      title: "Inactive",
      value: 0
    },
  ]
  const initialize = useCallback( () => {
    setLoading( true );
    axios.get( listingApi.url ).then( ( response ) => {
      const { data } = response;
      setTotalNumber( data.total_number );
      setActiveNumber( data.activate_number );
      setInactiveNumber( data.total_number - data.activate_number );
    } ).catch( ( e ) => {
      console.log( e.message );
    } ).finally( () => {
      setLoading( false );
    } );
  }, [listingApi.url] );

  useEffect( initialize, [initialize] );

  const tableData = {
    total: totalNumber,
    active: activeNumber,
    inactive: inactiveNumber,
  };
  let keyActivation: any = (
    <KeyActivation
      initialize={
        () => {
          initialize();
        }
      }
    />
  );
  let keyRequest: any = (
    <KeyRequest
      api={downloadApi}
      initialize={
        () => {
          initialize();
        }
      }
    />
  );
  if ( !writable ) {
    keyRequest = null;
    keyActivation = null;
  }
  return (
    <PageWrapper>
      <Loading show={loading} />

      {/* <div style={{ display: "flex" }}> */}

      <Body>
        <Header>
          <Selection
            list={[
              {
                key: 'key-managament',
                label: 'ADI SERIALISED KEYS',
              },
            ]}
            upperCase
          />
        </Header>
        <Grid>
          {CardsData.map( rec => <Statistics
            bgOne={rec.bgOne}
            bgTwo={rec.bgTwo}
            icons={rec.icons}
            title={rec.title}
            value={rec.value}
          /> )}
        </Grid>

        <SearchContainer >
          <ItrazokeysChildrenWrapper>
            <Grid>
              {keyRequest}
              {keyActivation}
            </Grid>
          </ItrazokeysChildrenWrapper>
        </SearchContainer>
        <Header>
          <Selection
            list={[
              {
                key: 'key-managament',
                label: 'ADI DEVICES',
              },
            ]}
            upperCase
          />
        </Header>
        <SearchContainer >
          <SearchBox
           placeHolder ="Search by ADI Number"
          
          ></SearchBox>
        </SearchContainer>
        {/* <TableWrapper>
          <KeyTable data={tableData} />
        </TableWrapper> */}
      
        <Table
        originalList={deviceData}
        NoData={<p>No Device</p>}
        />
      </Body>
    </PageWrapper>
  );
};

export default Itrazokeys;
