/* eslint-disable react/jsx-props-no-spreading */
/**
 * This is thee authorized app
 * It determines the app layout and configure pages differently according to a user's role
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import {
  LineChartOutline as InsightsIcon,
  WifiOutline as DeviceManagementIcon,
  FileTextOutline as ContractIcon,
  BellOutline as Bell,
  GlobalOutline as ConsumerEngagementIcon,
  ClockCircleFill as ProductTrackingIcon,
  TeamOutline as UserManagementIcon,
  KeyOutline as ItrazokeysIcon,
} from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import { BrowserView, MobileView } from 'react-device-detect';
import Logo from '../../../statics/images/Logo.png';
import MobileLayout from './MobileLayout';
import DesktopLayout from './DesktopLayout';
import Insights from '../../Pages/Insights';
import ConsumerEngagement from '../../Pages/ConsumerEngagement';
import ProductTracking from '../../Pages/ProductTracking';
import Alerts from '../../Pages/Alerts';
import AssetManagement from '../../Pages/AssetManagement';
// import ContractManagement from '../../Pages/ContractManagement';
import UserManagement from '../../Pages/UserManagement';
import Itrazokeys from '../../Pages/Itrazokeys';
import { Page } from '../../../utilities/types';
import { USER_ROLES, SESSION_STORAGE_KEYS } from '../../../utilities/Functions/CONSTANTS';
import location from '../../../statics/images/location.svg'
import insights from '../../../statics/images/insights.svg'
import consumer from '../../../statics/images/consumer.svg'
import asset from '../../../statics/images/asset.svg'

const Wrapper = styled.div`
  position: fixed;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
`;

type State = {
  logo: string;
  pages: Page[];
  pagesExternal: Page[];
};

class App extends Component<{}, State> {
  constructor( props: {} ) {
    super( props );
    /* -------------------------------------TO CHANGE ----------------------------------------- */
    // static mapping of organiztion to different mocked data just for demo purpose

    const idToken = JSON.parse( `${sessionStorage.getItem( SESSION_STORAGE_KEYS.IDTOKEN )}` );
    let payload: any = null;
    let organizations: any = null;
    let userRole: any = null;
    if ( idToken ) {
      payload = idToken.payload;
      organizations = Array.isArray( payload['cognito:groups'] ) ? payload['cognito:groups'][0] : null;
      userRole = payload['custom:role'];
    }

    const pageInsights = {
      key: 'insights',
      path: '/app/insights',
      name: 'insights',
      icon: <img src={insights} />,
      component: Insights,
      apis: [
        {
          key: 'Daily',
          label: 'Daily',
          url: `https://demo-${organizations}.s3-ap-southeast-2.amazonaws.com/DAY_CHARTS.json`,
        },
        {
          key: 'Weekly',
          label: 'Weekly',
          url: `https://demo-${organizations}.s3-ap-southeast-2.amazonaws.com/Weekly_Charts.json`,
        },
        {
          key: 'Monthly',
          label: 'Monthly',
          url: `https://demo-${organizations}.s3-ap-southeast-2.amazonaws.com/MONTH_CHARTS.json`,
        },
        {
          key: 'Quarterly',
          label: 'Quarterly',
          url: `https://demo-${organizations}.s3-ap-southeast-2.amazonaws.com/Quarter_Charts.json`,
        },
        
      ],
    };


    const pageConsumerEngagement = {
      key: 'consumer-engagement',
      path: '/app/consumer-engagement',
      name: 'consumer engagement',
      icon: <img src={consumer} />,
      component: ConsumerEngagement,
      apis: [
        {
          key: 'scan history',
          label: 'Scan History',
          url: `${process.env.REACT_APP_API_BASE}scan`,
        },
      ],
    };

    const pageProductTracking = {
      key: 'value-chain-tracking',
      path: '/app/value-chain-tracking',
      name: 'Value Chain Tracking',
      icon: <img src={location} />,
      component: ProductTracking,
      apis: [
        {
          key: 'product all',
          label: 'product all',
          url: `${process.env.REACT_APP_API_BASE}product/all`,
        },
        {
          key: 'product all',
          label: 'product all',
          url: `${process.env.REACT_APP_API_BASE}event/all/`,
        },
      ],
    };

    const pageAlertsAdmin = {
      key: 'alerts',
      path: '/app/alerts',
      name: 'alerts',
      icon: <AntdIcon type={Bell} />,
      component: ( myProps: any ) => <Alerts writable {...myProps} />,
    };
    const pageDeviceManagement = {
      key: 'asset-management',
      path: '/app/asset-management',
      name: 'Asset Management',
      icon: <img src={asset}/>,
      component: AssetManagement,
    };
    const pageAlerts = {
      key: 'alerts',
      path: '/app/alerts',
      name: 'Alerts',
      icon: <AntdIcon type={Bell} />,
      component: Alerts,
    };

   

    // const pageContractManagement = {
    //   key: 'contract-management',
    //   path: '/app/contract-management',
    //   name: 'Contract Management',
    //   icon: <AntdIcon type={ContractIcon} />,
    //   component: ContractManagement,
    //   apis: [
    //     {
    //       url: `${process.env.REACT_APP_API_BASE}file`,
    //       key: 'contract-api',
    //       label: 'contract-api',
    //     },
    //   ],
    // };

    const pageWritableUserManagement = {
      key: 'user-management',
      path: '/app/user-management',
      name: 'User Management',
      icon: <AntdIcon type={UserManagementIcon} />,
      component: ( myProps: any ) => <UserManagement writable {...myProps} />,
      apis: [
        {
          url: `${process.env.REACT_APP_API_BASE}user/list`,
          key: 'listing-users-api',
          label: 'listing-users-api',
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/create`,
          key: 'create-user-api',
          label: 'create-user-api',
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/delete`,
          key: 'delete-user-api',
          label: 'delete-user-api',
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/amend`,
          key: 'amend-user-api',
          label: 'amend-user-api',
        },
      ],
    };

    const pageReadOnlyUserManagement = {
      key: 'user-management',
      path: '/app/user-management',
      name: 'UserManagement',
      icon: <AntdIcon type={UserManagementIcon} />,
      component: UserManagement,
      apis: [
        {
          url: `${process.env.REACT_APP_API_BASE}user/list`,
          key: 'listing-users-api',
          label: 'listing-users-api',
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/create`,
          key: 'create-user-api',
          label: 'create-user-api',
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/delete`,
          key: 'delete-user-api',
          label: 'delete-user-api',
        },
        {
          url: `${process.env.REACT_APP_API_BASE}user/amend`,
          key: 'amend-user-api',
          label: 'amend-user-api',
        },
      ],
    };

    const pageWritableItrazokeys = {
      key: 'iTrazokeys',
      path: '/app/iTrazokeys',
      name: 'ADI Key Management',
      icon: <AntdIcon type={ItrazokeysIcon} />,
      component: ( myProps: any ) => <Itrazokeys writable {...myProps} />,
      apis: [
        {
          url: `${process.env.REACT_APP_API_BASE}key`,
          key: 'requesting-api',
          label: 'requesting-api',
        },
        {
          url: `${process.env.REACT_APP_API_BASE}key/download`,
          key: 'key-download-api',
          label: 'key-download-api',
        },
      ],
    };

    const pageReadonlyItrazokeys = {
      key: 'iTrazokeys',
      path: '/app/iTrazokeys',
      name: 'iTrazokeys',
      icon: <AntdIcon type={ItrazokeysIcon} />,
      component: Itrazokeys,
      apis: [
        {
          url: `${process.env.REACT_APP_API_BASE}key`,
          key: 'requesting-api',
          label: 'requesting-api',
        },
        {
          url: `${process.env.REACT_APP_API_BASE}key/download`,
          key: 'key-download-api',
          label: 'key-download-api',
        },
      ],
    };

   let adminPages;
   let operationalPages;
   let managerPages ; 
   let marketingPages ;
  //  if(organizations==="costagroup"){
    if(false){
      adminPages = [
       pageInsights,
       pageConsumerEngagement,
       pageWritableUserManagement,
       // pageContractManagement,
     ];
 
      operationalPages = [
       pageInsights,
       pageConsumerEngagement,
       pageReadOnlyUserManagement,
       // pageContractManagement,
     ];
 
      managerPages = [
       pageInsights,
       pageConsumerEngagement,
       pageReadOnlyUserManagement,
       // pageContractManagement,
     ];
 
      marketingPages = [
       pageInsights,
       pageConsumerEngagement,
     ];
   }else{
       adminPages = [
        pageInsights,
        pageProductTracking,
        pageConsumerEngagement,
       // pageDeviceManagement,
        pageAlertsAdmin,
        pageWritableItrazokeys,
        pageWritableUserManagement,
        // pageContractManagement,
      ];
  
       operationalPages = [
        pageInsights,
        pageProductTracking,
        pageConsumerEngagement,
        //pageDeviceManagement,
        pageAlerts,
        pageReadonlyItrazokeys,
        pageReadOnlyUserManagement,
        // pageContractManagement,
      ];
  
       managerPages = [
        pageInsights,
        pageProductTracking,
        pageConsumerEngagement,
        //pageDeviceManagement,
        pageAlerts,
        pageWritableItrazokeys,
        pageReadOnlyUserManagement,
        // pageContractManagement,
      ];
  
       marketingPages = [
        pageInsights,
        pageProductTracking,
        pageConsumerEngagement,
        //pageDeviceManagement,
        pageAlerts,
      ];
    }
    if(organizations==="thechosenones"){
      adminPages = [
        pageInsights,
      
        pageConsumerEngagement,
       // pageDeviceManagement,
        // pageAlertsAdmin,
        // pageWritableItrazokeys,
        pageWritableUserManagement,
        // pageContractManagement,
      ];
    }
    let pages: Page[] = [];
    switch ( userRole ) {
      case USER_ROLES.ADMIN: {
        pages = adminPages;
        break;
      }
      case USER_ROLES.OPERATIONAL: {
        pages = operationalPages;
        break;
      }
      case USER_ROLES.MANAGER: {
        pages = managerPages;
        break;
      }
      case USER_ROLES.MARKETING:
      default: pages = marketingPages;
    }

    this.state = { // customized data to be used in this app
      logo: Logo,
      pages,
      pagesExternal: [
      ],
    };
  }

  // componentWillUnmount() {
  //   const { signOut } = this.props;
  //   signOut();
  // }

  render() {
    const {
      logo,
      pages,
      pagesExternal,
    } = this.state;
    return (
      <Wrapper>
        <BrowserView>
          <DesktopLayout
            logo={logo}
            pages={pages}
            pagesExternal={pagesExternal}
          />
        </BrowserView>
        <MobileView>
          <MobileLayout
            pages={pages}
          />
        </MobileView>
      </Wrapper>
    );
  }
}

export default App;
