const coordinateLibrary: {
  [prop: string]: {
    lat: number;
    lng: number;
  };
} = {
  '1303 Barossa Valley Way, Lyndoch SA 5351': {
    lat: -34.594506,
    lng: 138.8827305,
  },
  'Treasury Wine Estates - Penfolds Vineyard Bordertown SA 5268': {
    lat: -36.320507,
    lng: 140.691747,
  },
  '34 Barossa Valley Way, Tanunda SA 5352': {
    lat: -34.5366402,
    lng: 138.9479801,
  },
  '30 Tanunda Rd, Nuriootpa SA 5355': {
    lat: -34.4799808,
    lng: 138.9921639,
  },
  '25-27 Barossa Valley Way, Lyndoch SA 5351': {
    lat: -34.6011728,
    lng: 138.8918912,
  },
  '91 Crown Street, Darlinghurst, East Sydney, NSW 2010': {
    lat: -33.8746862,
    lng: 151.2159474,
  },
  '87 Epsom Road, Rosebery, Sydney, NSW 2018': {
    lat: -33.9132899,
    lng: 151.2117531,
  },
  '3 Keith Smith Ave, Mascot NSW 2020': {
    lat: -33.9337869,
    lng: 151.1864529,
  },
  '55 Ricketts Rd, Mount Waverley VIC 3149': {
    lat: -37.8946376,
    lng: 145.1374972,
  },
  '133 Queens Bridge St, Southbank VIC 3006': {
    lat: -37.825294,
    lng: 144.960458,
  },
  'sydney': {
    lat: -33.856159,
    lng: 151.215256
  },
  "Linfox": {
    lat: -37.825294,
    lng: 144.960458,
  },
  "Costa Atherton - 104 Beantree Rd, Atherton QLD 48":{
    lat: -17.234890,
    lng: 145.486450
  },
  "385 Sherwood Rd, Rocklea QLD 4106":{
    lat: -27.536869,
    lng: 153.001404
  },
  // "Linfox":{
  //   lat: -31.611521,
  //   lng: 116.014442
  // },
  "Woolworths 522 Wellington Rd, Mulgrave VIC 3170":{
    lat: -37.921980,
    lng: 145.183030

  },
  "Woolworths - 113 Cecil St, South Melbourne VIC 3205":{
    lat: -37.830870,
    lng: 144.957070
  },
  "6 Camolina Way, Clyde North VIC 3978":{
    lat: -38.110820,
    lng: 145.323230
  }

};

export default coordinateLibrary;
