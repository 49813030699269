/**
 * The Header to render for desktop devices.
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React from 'react';
import styled from 'styled-components';
import Alert from '../../../components/GlobalButtons/AlertButton';
import Avatar from '../../../components/GlobalButtons/Avatar';
import LogOut from '../../../components/GlobalButtons/LogOutBotton';
import {
  SESSION_STORAGE_KEYS
} from '../../../utilities/Functions/CONSTANTS';
// import downIcon from '../../../statics/images/down-icon.svg'
const Wrapper = styled.div`
  width: 100%;
  background: #1F1F1F;
  text-align: right;
`;

const Item = styled.div`
  display: inline-block;
  
  *{
    color: white;
  }

  svg{
    margin: auto;
  }

  div{
    display: flex;

    span{
      display: inline-block;
      margin: auto;
    }
  }

  a{
    text-decoration: none;
    
    &:hover{
        text-decoration: none;
    }

    &:focus{
      color: $text-color-light;
    }
  }
  p{
    margin-top:0px;
    padding-top:0px
  }
`;
const UserName = styled.p`
width: 61px;
height: 14px;
left: 1337.46px;
top: 13.43px;
font-family: Gotham;
font-style: normal;
font-weight: 350;
font-size: 12px;
line-height: 14px;
text-align: center;
color: #FFFFFF;`
// const name = sessionStorage.getItem( SESSION_STORAGE_KEYS.USER_NAME );
// console.log("Name========>",name)
type MyProps = {
  name?:any
}
const idToken = JSON.parse( `${sessionStorage.getItem( SESSION_STORAGE_KEYS.IDTOKEN )}` );
let payload = idToken ? idToken.payload:[];
let organizations = Array.isArray( payload['cognito:groups'] ) ? payload['cognito:groups'][0] : null;

const Header = (props:MyProps) => (
  <Wrapper className="site-header text-md bold">
    {/* <Item className="pad-sm">
      <Alert desktop />
    </Item>
    <Item className="pad-sm">
      <LogOut desktop />
    </Item> */}
    <Item className="pad-sm">
      <LogOut desktop />
    </Item>
    {/* {organizations==="costagroup" ? null: */}
  <Item className="pad-sm">
      <Alert desktop />
    </Item> 
    {/* } */}
    <Item className="pad-sm">
      <Avatar username="UI" />
    </Item>
    <Item className="pad-sm">
    <span className="mrg-sm-left" style={{fontSize:"25px"}}>{props.name.split('.').map((rec:any)=>rec.charAt(0).toUpperCase()).join("")}</span>
    </Item>
    {/* <Item className="pad-sm">
      <img src={downIcon} alt="down" />
    </Item> */}
  </Wrapper>
);

export default Header;
