/**
 * The Dialog box component.
 * It is based on functional component and react context.
 * Use:
 *  Wrap the root component with DialogProvider,
 *  and call the useDialog function to create get a function.
 *
 * The function takes three parameters:
 *  - dialog title
 *  - dialog description,
 *  - (optional) whether it is an alert box
 * The function returns a promise:
 *  resolve when click the first button (or the only button when it is an alert box)
 *  reject when click the second button
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 12 Dec 2019
 */

import React, {
  createContext,
  useContext,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import { Button } from '../../statics/styles/StyledComponents';

type DialogProps = {
  open: boolean;
  title: string;
  descriptionTitle?: string;
  description: string;
  onSubmit: Function;
  onClose: Function;
  alert?: boolean;
};

const DialogBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 1000;
`;

const DialogDisplay = styled.div`
  background: #545454;
  width: 50rem;
  min-height: 20rem;
  max-width: 80vw;
  margin: auto;
  border-radius: 1rem;
  overflow: hidden;

`;

const DialogTitle = styled.h1`
  margin: 0;
  width: 100%;
  padding: 1rem;
  color: white;
  text-transform: uppercase;
  text-align: center;
`;

const DialogContent = styled.p`
  width: 100%;
  max-height: 30rem;
  overflow: scroll;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  color: #FFFFFF;
`;
const DialogContentTitle = styled.p`
width: 100%;
max-height: 30rem;
overflow: scroll;
font-family: Gotham Pro;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 17px;
text-align: center;
color: #FFFFFF;
margin-top:60px
`;



const DialogActions = styled.div`
  padding-bottom: 1rem;
  margin: auto;
  text-align: center;
  &>button{
    color: black;
    font-weight: 500;
    &:last-child{
      border: 2px solid #FFFFFF
      width:35%
      color:#FFFFFF
    }
    &:first-child{
      background:#FFFFFF;
      width:35%
    }
  }
`;

const DialogButton = styled( Button )`
  display: inline-block;
  width: 10rem;
  padding: 1rem;
  font-size: 1.2rem;
  margin: 0 1rem;
  border-radius: 5px;
  &:focus{
    box-shadow: 1px 1px 2px gray;
    ::-moz-focus-inner {
      border: 0;
    }
  }
`;
const DialogButtonOk = styled( Button )`
  display: inline-block;
  width: 10rem;
  padding: 1rem;
  font-size: 1.2rem;
  margin: 0 1rem;
  border-radius: 5px;
  background:red !important;
  &:focus{
    box-shadow: 1px 1px 2px gray;
    ::-moz-focus-inner {
      border: 0;
    }
  }
`;

const Dialog = ( {
  open,
  title,
  description,
  onSubmit,
  onClose,
  alert,
  descriptionTitle
}: DialogProps ) => {
  if ( open ) {
    return (
      <DialogBackground>
        <DialogDisplay>
          <DialogContentTitle>
            {alert? title:descriptionTitle}
          </DialogContentTitle>
          <DialogContent>
            {description}
          </DialogContent>
          {/* <DialogTitle>{title}</DialogTitle> */}
          <DialogActions>
            {
              alert
                ? (
                  <DialogButtonOk
                    onClick={() => {
                      onSubmit();
                    }}
                  >
                    OK
                  </DialogButtonOk>
                )
                : (
                  <>
                    <DialogButton
                      onClick={() => {
                        onSubmit();
                      }}
                      autoFocus
                    >
                      CONFIRM
                    </DialogButton>
                    <DialogButton
                      onClick={() => {
                        onClose();
                      }}
                    >
                      CANCEL
                    </DialogButton>
                  </>
                )
            }
          </DialogActions>
        </DialogDisplay>
      </DialogBackground>
    );
  }
  return null;
};

const DialogContext = createContext<( title: string, description: string, descriptionTitle?: string, alert?: boolean ) =>
  Promise<any>>( Promise.reject );

const DialogProvider = ( { children }: { children: any } ) => {
  const [
    dialogTitle,
    setDialogTitle,
  ] = useState( '' );
  const [
    dialogDescription,
    setDialogDescription,
  ] = useState( '' );
  const [descriptionTitle, setDescriptionTitle] = useState( "" )
  const [
    alert,
    setAlert,
  ] = useState( false );

  const [open, setOpen] = useState( false );

  const awaitingPromiseRef = useRef(
    {
      resolve: () => { },
      reject: () => { },
    },
  );

  const openDialog = ( title: string, description: string, descriptionTitle?: string, isAlert?: boolean ): Promise<any> => {
    setDialogTitle( title );
    setDialogDescription( description );
    setAlert( Boolean( isAlert ) );
    setOpen( true );
    descriptionTitle ? setDescriptionTitle( descriptionTitle ) : setDescriptionTitle( "" )
    return new Promise<void>( ( resolve, reject ) => {
      awaitingPromiseRef.current = { resolve, reject };
    } );
  };

  const handleClose = () => {
    setOpen( false );
  };

  const handleSubmit = () => {
    if ( awaitingPromiseRef.current ) {
      awaitingPromiseRef.current.resolve();
    }
    setOpen( false );
  };

  return (
    <>
      <DialogContext.Provider
        value={openDialog}
      >
        {children}
      </DialogContext.Provider>
      <Dialog
        open={open}
        onClose={() => { handleClose(); }}
        onSubmit={() => { handleSubmit(); }}
        title={dialogTitle}
        description={dialogDescription}
        descriptionTitle={descriptionTitle}
        alert={alert}
      />
    </>
  );
};

export const useDialog = () => useContext( DialogContext );

export default DialogProvider;
