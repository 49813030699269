/**
 * The Navigation bar for mobile devices.
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { Page } from '../../../utilities/types';

interface RouterType extends RouteComponentProps<any>{
  location: any;
}

type Props = {
  pages: Page[];
} & RouterType;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: black;
  display: flex;
`;

const MyLink = styled(Link)`
  flex: 0 0 ${({ number }: {number: number; [props: string]: any}) => `${100 / number}%`};
  text-align: center;
  color: white;
  margin: auto;
  
  svg{
    font-size: 3rem;
    color: ${({ number, selected, ...props }: {number: number; [props: string]: any}) => {
    if (selected) return props.theme.main;
    return 'white';
  }};
  }
`;

const MobileNavigation = (props: Props) => {
  const { pages, location } = props;
  const number = pages.length > 0 ? pages.length : 1;
  return (
    <Wrapper>
      {pages.map((page) => (
        <MyLink
          key={page.key}
          to={page.path}
          number={number}
          selected={location.pathname.split('/app/').pop() === page.key}
        >
          {page.icon}
        </MyLink>
      ))}
    </Wrapper>
  );
};

export default withRouter(MobileNavigation);
