/**
 * The Navigation Sidebar for desktop devices.
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import styled from 'styled-components';
import Poweredby from '../../../statics/images/Poweredby.svg'
import { Page } from '../../../utilities/types';
import Utils from "../../../utils/Utils";

interface RouterType extends RouteComponentProps<any>{
  location: any;
}

type Props = {
  logo: string;
  pages: Page[];
  pagesExternal: Page[];
} & RouterType;

const NavigationDisplay = styled.div`
  height: 100%;
  width: 100%;
  background: #454545;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding: 0 2rem 1rem;
  flex: 0;
`;

const ItemsWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Img = styled.img`
  display: block;
  width: 100%;
`;

const NavigationItem = styled(Link)`
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: white;
  cursor: pointer;
  border-radius: 0px;
  font-weight: 400;
  height:68px;
  &>div{
    margin: auto 0;
  }

  &:hover{
      text-decoration: none;
      color: white;
  }
  span{
      display: inline-block;
      &:first-child{
        width: 3rem;
      }
  }
`;

const DesktopNavigation = (props: Props) => {
  const {
    logo, pages, location,
  } = props;
  return (
    <NavigationDisplay className="">
      <ImageWrapper className="pad-xl mrg-2xl-bottom">
        <Img className="sidebar-logo max-width-5xl mrg-auto-left mrg-auto-right" src={logo} alt="navigation logo" />
      </ImageWrapper>
      <ItemsWrapper>
        {pages.map((page) => {

 var isActive = Utils.isSameOrSubPath(location.pathname, page.path);
    return ( 
          <NavigationItem className={"sidebar-menu-link block mrg-0  " + (isActive ? "active" : "")}
            key={page.key}
            to={page.path}
          >
            <div className="sidebar-menu-item pad-md min-height-3xl flex align-items-center">
              <span className="text-xl line-height-xl mrg-xs-right">{page.icon}</span>
              <span className="text-lg line-height-2xl title sidebartitles">{page.name}</span>
            </div>
          </NavigationItem>
        )}
)}
      </ItemsWrapper>
      <ImageWrapper className="pad-xl mrg-2xl-bottom">
        <Img className="sidebar-logo max-width-9xp mrg-auto-left mrg-auto-right" src={Poweredby} alt="navigation logo" />
      </ImageWrapper>
    </NavigationDisplay>
  );
};

export default withRouter(DesktopNavigation);
