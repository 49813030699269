/**
 * This is the User delete button
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React from 'react';
import axios from 'axios';
import { api, userDeletePayload } from '../../../../utilities/types';
import { TableButton as Button } from '../../../../statics/styles/StyledComponents';
import { useDialog } from '../../../../components/Dialog';
import AntdIcon from '@ant-design/icons-react'
import {DeleteOutline} from '@ant-design/icons'
import styled from "styled-components";
type Props = {
  deleteUserApi: api;
  update: Function;
  setLoading: Function;
  userName: string;
  currentUserName: string;
  userId:string;
};
const DeleteBtn = styled(Button)`
background: rgba(255, 255, 255, 0) !important;
border:0px !important;
display:inline !important
`

const UserDelete = (props: Props) => {
  const openDialog = useDialog();
  const {
    deleteUserApi,
    update,
    setLoading,
    userId,
    currentUserName,
    userName
  } = props;
  const deletePayload: userDeletePayload = {
    user_id: userId,
  };
  const deleteUser = (url: string, payload: userDeletePayload) => {
    setLoading(true);
    axios.post(url, payload).then((response) => {
      const { data } = response;
      openDialog('Message', data,"Users", true);
    }).catch((e) => {
      const { message } = e;
      openDialog('Warning', message, "error",true);
    }).finally(() => {
      setLoading(false);
      update();
    });
  };

  if (userName === currentUserName) return null;
  return (
    <DeleteBtn
      type="button"
      onClick={() => {
        openDialog('Warning', 'Action will delete user permanently.').then(() => {
          deleteUser(deleteUserApi.url, deletePayload);
        });
      }}
    >
        <AntdIcon type={DeleteOutline}/>
    </DeleteBtn>
  );
};

export default UserDelete;
