/**
 * This is the alert button.
 * It receive saga alert messages and displays the number of them
 * It navigates to the alerts page
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { BellOutline as Bell } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from '../../../stores/reducers/actions';
import { alerts as alertsType } from '../../../utilities/types';
import notification from '../../../statics/images/bell.svg'

type Props = {
  alerts: alertsType;
  openSocket: Function;
  closeSocket: Function;
  desktop?: boolean;
};

const Wrapper = styled.div`
  overflow: visible;
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  *{
    color: white;
  }
`;

const Span = styled.span`
  &&&{
    vertical-align: sup;
  }
`;

class Alert extends Component<Props, {}> {
  // connect to server with websocket
  componentDidMount() {
    const { openSocket } = this.props;
    openSocket();
  }

  // close channel when unmount
  componentWillUnmount() {
    const { closeSocket } = this.props;
    closeSocket();
  }

  render() {
    const { alerts, desktop } = this.props;
    const { unread } = alerts;
    const unreadNumber = unread ? unread.length : 0;
    return (
      <Wrapper>
        {
          desktop
            ? (
              <Link to="/app/alerts">
                <img src={notification} alt="alerts" />
              </Link>
            )
            : (
              <Link to="/app/alerts">
                <AntdIcon type={Bell} />
                <Span className="mrg-sm-left">{unreadNumber === 0 ? 0 : unreadNumber}</Span>
              </Link>
            )
        }
      </Wrapper>
    );
  }
}

const mapStateToProps = ( state: any ) => ( {
  alerts: state.alerts,
} );

const mapDispatchToProps = ( dispatch: Function ) => ( {
  openSocket: () => dispatch( actions.openSocket() ),
  closeSocket: () => dispatch( actions.closeSocket() ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( Alert );
