/**
 * This is a text input component.
 * It can be set to password display when have password property = true
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component } from 'react';
import { Input } from '../../../statics/styles/StyledComponents';
import { SESSION_STORAGE_KEYS } from '../../../utilities/Functions/CONSTANTS';
var CryptoJS = require("crypto-js");
type Props = {
  className: string;
  placeholder: string;
  onChange?: Function;
  password?: boolean;
  focus?: boolean;
  disable?: boolean;
  onKeyEvent?: Function;
  value?: string
};

type State = {
  textEntered: string;
};

class TextInput extends Component<Props, State> {
  myInput: any;

  constructor( props: Props ) {
    super( props );
    this.state = {
      textEntered: '',
    };
  }
  componentDidMount() {
    const { focus } = this.props;
    if ( focus ) this.myInput.focus();
    const idToken = JSON.parse( `${sessionStorage.getItem( SESSION_STORAGE_KEYS.IDTOKEN )}` );
    if ( !idToken ) {
      let credentials = localStorage.getItem( "credentials" )
      if ( credentials ) {
        // credentials = cryptr.decrypt(credentials)
        var bytes  = CryptoJS.AES.decrypt(credentials, 'secret key 123');
        let dataOne = JSON.parse(  bytes.toString(CryptoJS.enc.Utf8)  ?  bytes.toString(CryptoJS.enc.Utf8)  : "" )
        let data = JSON.parse(dataOne);
        if ( data && data.email && !this.props.password ) {
          this.setState( {
            textEntered: data.email
          } )
        }
        if ( data && data.password && this.props.password ) {
          this.setState( {
            textEntered: data.password
          } )
        }
      }
    }
  }

  componentDidUpdate() {
    const { focus } = this.props;
    if ( focus ) this.myInput.focus();
  }
  componentWillMount() {
    // const idToken = JSON.parse( `${sessionStorage.getItem( SESSION_STORAGE_KEYS.IDTOKEN )}` );
    // if ( !idToken ) {
    //   let credentials = localStorage.getItem( "credentials" )
    //   if ( credentials ) {
    //     credentials = cryptr.decrypt(credentials)
    //     let data = JSON.parse( credentials ? credentials : "" )
    //     if ( data && data.email && !this.props.password ) {
    //       this.setState( {
    //         textEntered: data.email
    //       } )
    //     }
    //     if ( data && data.password && this.props.password ) {
    //       this.setState( {
    //         textEntered: data.password
    //       } )
    //     }
    //   }
    // }

  }
  componentWillReceiveProps( nextProps: Props ) {
    if ( nextProps.value != this.props.value ) {
    this.setState( { textEntered: nextProps.value ? nextProps.value : this.state.textEntered } );

    }
  }
  componentWillUnmount() {
    console.log( "Unmounted" );
    
  }

  onTextChange = ( event: any ): any => {
    const { onChange } = this.props;
    const input = event.target.value;
    this.setState(
      {
        textEntered: input,
      },
    );
    if ( onChange ) onChange( input );
  };

  onKeyUp = ( e: any ) => {
    const { onKeyEvent } = this.props;
    if ( onKeyEvent ) {
      onKeyEvent( e );
    }
  };

  render() {
    const {
      password,
      placeholder,
      disable,
      className,
      value,
    } = this.props;
    const { textEntered } = this.state;
    return (
      <Input
        className={className}
        disabled={disable}
        ref={( input ) => { this.myInput = input; }}
        type={password ? 'password' : 'text'}
        placeholder={placeholder}
        onChange={this.onTextChange}
        value={textEntered}
        onKeyUp={( e: any ) => { this.onKeyUp( e ); }}
        autoComplete="new-password"
      />
    );
  }
}

export default TextInput;
