/**
 * This is the user creation component
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import EmailValidator from 'email-validator';
import { isValidPhoneNumber } from 'react-phone-number-input';
import TextInput from '../../../../components/Input/TextInput';
import PhoneInput from '../../../../components/Input/PhoneInput';
import Selection from '../../../../components/Selection';
import Loading from '../../../../components/Loading';
import { USER_ROLES, KEYBOARD_CODES } from '../../../../utilities/Functions/CONSTANTS';
import { onKeyEvent } from '../../../../utilities/Functions/Functions';
import { api, subItem, userCreationPayload, userAmendPayload } from '../../../../utilities/types';
import { WhiteButton, CloseButton as Close } from '../../../../statics/styles/StyledComponents';
import { useDialog } from '../../../../components/Dialog';
import noActivity from '../../../../statics/images/noActivity.svg'
import { CodeSandboxOutline } from '@ant-design/icons';
type Props = {
  createUserApi: api;
  onClose: Function;
  initialize: Function;
  show: boolean;
  isEdit: boolean;
  editData: any;
  amendUserApi: api;

};


const CloseButton = styled( Close )`
  margin-left: 1rem;
  padding: 0 1rem;
  height: 20px;
  color:#000000 !important;
  font-family: Gotham Pro !important;
  float:right;
  border:0px;
  font-style: normal !important;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 15px;
`;
const StyledButton = styled( WhiteButton )`
  margin-left: 1rem;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 0 1rem;
  width: 95%;
  height: 40px;
  color:#000000 !important;
  font-family: Gotham Pro !important;
  font-style: normal !important;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 15px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  left:  ${( props ) => {
    if ( props.theme.mobile ) return 0;
    return '30%';
  }};
  top: 0;
  height: auto;
  width: ${( props ) => {
    if ( props.theme.mobile ) return '100%';
    return '30%';
  }};
  margin: auto;
  z-index: 1;
  border-radius: 5px;
  border: 0px solid rgb(210, 210, 210);
  padding: 1rem 1rem 2rem;
  background:#545454;
  display: ${( { show }: { show: boolean } ) => {
    if ( show ) return 'block';
    return 'none';
  }};
`;

const FormWrapper = styled.div`
  padding: 3rem 0;
`;

const FormItem = styled.div`
  padding: 0 1rem;
  width: 100%;
  h1{
    font-size: 1.5rem;
    padding: 1rem;
    margin: 0;
    color: white;
  }

  &>*:not(h1){
    background: #383838;
    font-size: 1.3rem;
    border-radius: 5px;
    min-height: 4rem;
    padding: 1rem;
    
    &::placeholder{
      color: rgb(210, 210, 210);
    }
  }
`;

const Message = styled.p`
  padding: 1rem;
  color: red;
  font-size: 1.5rem;
  margin: 0;
`;

const roleList = [
  {
    key: USER_ROLES.ADMIN,
    label: USER_ROLES.ADMIN,
  },
  {
    key: USER_ROLES.OPERATIONAL,
    label: USER_ROLES.OPERATIONAL,
  },
  {
    key: USER_ROLES.MANAGER,
    label: USER_ROLES.MANAGER,
  },
  {
    key: USER_ROLES.MARKETING,
    label: USER_ROLES.MARKETING,
  },
];

const UserCreation = ( props: Props ) => {
  const openDialog = useDialog();

  const {
    createUserApi, onClose, show, initialize, isEdit, editData,amendUserApi
  } = props;
  const [emailAddress, setEmailAddress] = useState( '' );
  const [loading, setLoading] = useState( false );
  const [userRole, setUserRole] = useState( roleList[0].key );
  const [name, setName] = useState( '' );
  const [phone, setPhone] = useState( '' );
  const [focusName, setFocusName] = useState( false );
  const [focusPhone, setFocusPhone] = useState( false );
  const [focusEmail, setFocusEmail] = useState( false );
  const [focusUserId, setFocusUserId] = useState( false );

  const [message, setMessage] = useState( '' );
  const [formKey, setFormKey] = useState( new Date().getTime() * 1000 );
  const [userId, setUserId] = useState( '' );


  const payload: userCreationPayload = {
    user_name: userId,
    email: emailAddress,
    action: 'CREATE',
    role: userRole,
    name,
    phone_number: phone,
  };
  const checkPhone = ()=>{
    if(!phone && !editData){
      return ""
    }else{
      if(!editData && phone){
        return phone
      }else if(!phone && editData){
        return editData.phone_number
      }else{
        return phone
      }
    }
  }
  const amendPayload: userAmendPayload = {
    username: editData ? editData.nick_name :"",
    user_id: editData ? editData.user_id :"",
    phone_number: checkPhone() ,
    role: userRole,
    preferred_name:name.length ? name :(editData ?editData.preferred_name : ""),
  };

  const clearForm = () => {
    setFormKey( new Date().getTime() * 1000 );
    setUserRole( roleList[0].key );
    setName( '' );
    setEmailAddress( '' );
    setPhone( '' );
    setMessage( '' );
  };

  const createUser = ( url: string, thePayload: userCreationPayload ) => {
    setLoading( true );
    axios.post( url, thePayload, {
      validateStatus( status ) {
        // Reject when status is not 200
        return status === 200 || status === 409;
      },
    } ).then(
      ( response ) => {
        const { status } = response;
        if ( status === 200 ) {
          openDialog( 'Message', 'User created successfully.', "", true );
        } else {
          openDialog( 'Warning', 'Email already exists.', "", true );
        }
      },
    ).catch( ( e ) => {
      openDialog( 'Warning', e.message, "", true );
    } ).finally( () => {
      setLoading( false );
      initialize();
      clearForm();
    } );
  };
  const amendUser = ( url: string, thePayload: userAmendPayload ) => {
    setLoading( true );
    axios.post( url, thePayload, {
      validateStatus( status ) {
        // Reject when status is not 200
        return status === 200 || status === 409;
      },
    } ).then(
      ( response ) => {
        const { status } = response;
        if ( status === 200 ) {
          openDialog( 'Message', 'User Updated successfully.', "", true );
        } else {
          openDialog( 'Warning', `Updation Fail`, "", true );
        }
      },
    ).catch( ( e ) => {
      openDialog( 'Warning', e.message, "", true );
    } ).finally( () => {
      setLoading( false );
      initialize();
      // onClose();
      clearForm();
    } );
  };
  const onCreate = () => {
    if ( !name ) {
      setMessage( 'Please enter a name' );
      setFocusName( true );
      setFocusPhone( false );
      setFocusEmail( false );
      return;
    }
    if ( !isValidPhoneNumber( phone ) ) {
      setMessage( 'Please enter a valid phone number' );
      setFocusName( false );
      setFocusPhone( true );
      setFocusEmail( false );
      return;
    }
    if ( !EmailValidator.validate( emailAddress ) ) {
      setMessage( 'Please enter an valid email address' );
      setFocusName( false );
      setFocusPhone( false );
      setFocusEmail( true );
      return;
    }
    createUser( createUserApi.url, payload );
  };
  const onAmend = () => {
    amendUser( amendUserApi.url, amendPayload );
  };
  return (
    <Wrapper show={show}>
      <Loading show={loading} />
      <CloseButton
        type="button"
        onClick={() => {
          onClose();
          clearForm();
        }}
      >
        <img src={noActivity} />
      </CloseButton>
      <FormWrapper key={`${formKey}`}>
        <FormItem>
          <h1>Role</h1>
          <Selection
            list={roleList}
            onClick={( item: subItem ) => {
              setUserRole( item.key );

            }}
            dropdown
            selectedValue={isEdit ? roleList.find( rec => rec.key === editData.user_role ) : undefined}
          />
        </FormItem>
        <FormItem>
          <h1>Name</h1>
          <TextInput
            className="my-input"
            placeholder="Please enter name"
            onChange={( input: string ) => {
              setName( input );
              setFocusName( true );
              setFocusPhone( false );
              setFocusUserId(false)
              setFocusEmail( false );
            }}
            onKeyEvent={( e: any ) => {
              onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
                onCreate();
              } );
            }}
            focus={focusName}

            value={isEdit ? editData.preferred_name : name}

          />
        </FormItem>
        <FormItem>
          <h1>Phone</h1>
          <PhoneInput
            placeholder="Please enter phone number"
            onChange={( input: string ) => {
              setPhone( input );
              setFocusName( false );
              setFocusUserId(false)
              setFocusPhone( true );
              setFocusEmail( false );
            }}
            onKeyEvent={( e: any ) => {
              onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
                onCreate();
              } );
            }}
            focus={focusPhone}
            previousValue={isEdit ? editData.phone_number : ""}
          />
        </FormItem>
     {  isEdit ? null: <FormItem>
          <h1>Email</h1>
          <TextInput
            className="my-input"
            placeholder="Please enter email address"
            onChange={( input: string ) => {
              setEmailAddress( input.toLowerCase() );
              setFocusName( false );
              setFocusPhone( false );
              setFocusEmail( true );
            }}
            onKeyEvent={( e: any ) => {
              onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
                onCreate();
              } );
            }}
            focus={focusEmail}
            value={isEdit ? editData.email : emailAddress}
          />
        </FormItem>

}
     </FormWrapper>
      <Message>
        {`*${message}`}
      </Message>
      <StyledButton
        // className="create-user"
        type="button"
        onClick={() => { isEdit ? onAmend() : onCreate() }}
      >
        {isEdit ? "Save changes" : "Create User"}
      </StyledButton>
    </Wrapper>
  );
};

export default UserCreation;
