/**
 * This is a styled loading component
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import styled from 'styled-components';

type Props = {
  show: boolean;
  top?: string;
};

const Loading = styled.div`
  display: ${({ show }: Props) => {
    if (show) return 'block';
    return 'none';
  }};
  position: fixed;
  top: 50vh;
  left: 50vw;
  z-index: 1000;

  &::before{
    content: '';
    position: absolute;
    border: 3rem solid rgb(255, 255, 255, 0.5);
    border-radius: 50%;
    border-top: 3rem solid ${(props) => props.theme.main};
    height: 16rem;
    width: 16rem;
    top: ${({ top }: Props) => {
    if (top) return top;
    return '-11rem';
  }};
    left: -11rem;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default Loading;
