/**
 * This is the saga module.
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import {
  call, put, takeLatest, takeEvery,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import Socket from './socket';
import { types } from '../reducers/types';

async function websocketInitializeChannel() {
  return eventChannel(Socket.initialize);
}

function* websocketSaga() {
  // open socket channel with this socket
  const channel = yield call(websocketInitializeChannel);
  // send message to socket server
  yield takeLatest(types.SAGA_SEND_MESSAGE, function* sendSocketMessage(newAction: any) {
    console.log(JSON.stringify(newAction.payload));
    console.log(Socket.socket)
    if (Socket.socket.readyState === 1) yield Socket.socket.send(JSON.stringify(newAction.payload));
  });
  // close socket channel (unsubscribe)
  yield takeLatest(types.SAGA_CLOSE_SOCKET, function* closeSocket() {
    yield channel.close();
  });
  // listening to socket message coming
  // while (true) {
  //   const newAction = yield take(channel);
  //   yield put(newAction);
  // }

  yield takeEvery(channel, function* dispatchData(action) {
    yield put(action);
  });
}

export default function* rootSaga() {
  yield takeLatest(types.SAGA_OPEN_SOCKET, websocketSaga);
}
