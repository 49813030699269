/**
 * @author Sanaullah
 * @version 1.0
 * @Date 25 Jan 2021
 */

import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import axios from 'axios';
import GoogleMap, { Maps } from 'google-map-react';
import moment from 'moment';
import { AssetItem, essetHistoryItem, eventHistoryItem } from '../../../../utilities/types';
import {
    Left,
    Loading,
} from '../../ProductTracking/styles';
import { ReactComponent as MapMarker, ReactComponent as Mark } from '../../../../statics/images/map-marker.svg';
import { ReactComponent as EndLogo } from '../../../../statics/images/truck-solid.svg';
import { ReactComponent as StartLogo } from '../../../../statics/images/flag-solid.svg';
import { compareEventHistoryItems } from '../../../../utilities/Functions/SortFunctions';
import styles from '../../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';
import Supercluster, { AnyProps } from 'supercluster';
import { Cluster, ScanDetails, DotMarker as MarkerColor } from '../../ConsumerEngagement/styles'
import { Point, Feature, BBox } from 'geojson';
import styled from 'styled-components';
import AssetEvent from '../AssetTracking/index';
import { ReactComponent as Temperature } from '../../../../statics/images/temperatureIcon.svg'
import { ReactComponent as TemperatureCross } from '../../../../statics/images/tempCross.svg'
import { ReactComponent as CrossNo } from '../../../../statics/images/crossNo.svg'





type Props = {
    url: string;
    historyUrl: string;
    historyRefresh: boolean,
    setTotalAsset: Function,
    setTemperaturedAsset: Function,
    setNoActivety: Function,
    setHistoryRefresh: Function,
};
const DotMarker = styled( MarkerColor )`
    color: yellow !important;

`
const MyMarker = ( { properties, time, position }: {
    properties: any;
    time: string;
    position: 'start' | 'middle' | 'end';
    [prop: string]: any;
} ) => {

    let marker;
    switch ( position ) {
        case 'start': {
            marker = <StartLogo />;
            break;
        }
        case 'end': {
            marker = <MapMarker />;
            break;
        }
        default: marker = <MapMarker />;
    }
    return marker
};

const defaultCenter = { lat: -25.274398, lng: 133.775136 };
const summaryZoomLevel = 1;
const timeDifference = ( evtDate: string ) => {
    var diff = Math.abs( new Date().getTime() - new Date( evtDate ).getTime() );
    var hours = Math.floor( Math.floor( ( diff / 1000 ) / 60 ) / 60 );
    return hours
}
function getRandomString(length:number) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}
const AssetMap = ( props: Props ) => {
    const { url, historyUrl, historyRefresh, setTotalAsset, setTemperaturedAsset, setNoActivety, setHistoryRefresh } = props;
    // const [validUrl, setValidUrl] = useState<string>('');
    const [data, setData] = useState<{ key: string, date: Date, location: any, properties: any }[]>( [] );
    const [historyDisplay, setHistoryDisplay] = useState<React.ReactElement[]>( [<Loading key="event-details-loading">Loading...</Loading>] );
    const [locationsDisplay, setLocationsDisplay] = useState<any[]>( [] );
    const [center, setCenter] = useState<{ lat: number; lng: number }>( defaultCenter );
    const [zoom, setZoom] = useState<number>( 5 );
    const [cluster, setClustures] = useState<any>( [] )
    const [ready, setReady] = useState<any>( false )
    const [historyData, setHistoryData] = useState<any>( [] )
    const [bounds, setBounds] = useState<BBox>( [-180, -85, 180, 85] )
    const [showHistory, setShowHistory] = useState( historyRefresh )
    const [historyURLWithId, setHistoryURLWithId] = useState( "" )

    const superCluster = useRef( new Supercluster( { radius: 40 } ) ).current;
    const Clusters = ( { 
        number,cluster_id ,lat,lng
    
    }: any ) => <Cluster 
    onClick={()=>{
        let xoom = superCluster.getClusterExpansionZoom( cluster_id)
        let xenter = { lat: lat, lng: lng }
        setCenter( xenter )
        setZoom( xoom );
        console.log("ke",cluster_id)}}
    >{number}</Cluster>;

    // const [path, setPath] = useState<{lat: number; lng: number}[]>([]);
    const getGeoJSON = ( item: any ): Feature<Point, AnyProps> => ( {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [item.longitude, item.latitude],
        },
        properties: {
            data: item,
        },
    } );
    const updateClusters = ( bound: any, zooms: any ) => {

        if ( zooms < summaryZoomLevel ) {
            setClustures( [] )
            return;

        }

        const clusters = superCluster.getClusters( bound, zooms );
        console.log("update==>",clusters)
        setClustures( clusters )
    };
    const showDetails = ( item: any ) => (


        <ScanDetails onClick={() => {
            setHistoryRefresh( true )
            setHistoryURLWithId( historyUrl + item.animalId )
        }}>
            <p className="location-type">{item.locationType}</p>
            <p>
                <span >Animal ID : {item.animalId}</span>
                
            </p>
            <p>
                <span>Activity :  {item.activity}</span>
               
            </p>
            {/* <p>
                <span>Battery Charge</span>
                {item.battery_charge}
            </p> */}
            <p>
                <span>Location Accuracy :  {item.location_accuracy}</span>
               
            </p>
            <p>
                <span>Temperature : {item.temperature}</span>
                
            </p>
            <p>
                <span>Longitude</span>
                {item.longitude}
            </p>
            <p>
                <span>Latitude</span>
                {item.latitude}
            </p>
            <p>
            <span>Date : {moment(item.observationDateUTC).format("lll")}</span>
            </p>

        </ScanDetails>
    );
    const returnIcon = ( item: any ) => {
        if ( item ) {
            if ( item.temperature > 30 && timeDifference( item.observationDateUTC ) > 12 ) {
                return <TemperatureCross />
            } else if ( item.temperature > 30 ) {
                return <Temperature />
            } else if ( timeDifference( item.observationDateUTC ) > 12 ) {
                return <CrossNo />
            } else {
                return <Mark />
            }
        }
        return

    }
    const PointsOld = ( { item }: any ) => {
        return (
            <DotMarker >
                {returnIcon( item )}
                {showDetails( item )}
            </DotMarker>
        )
    };
    const displayClusters = () => {
        const display = cluster.length === 0
            ? []
            : cluster.map( ( item: any ) => {
                console.log("Item===>",item)
                if ( item.properties.cluster ) {
                    return (
                        <Clusters
                            number={item.properties.point_count}
                            lat={item.geometry.coordinates[1]}
                            lng={item.geometry.coordinates[0]}
                            key={`${JSON.stringify( item.geometry.coordinates )}`}
                            cluster_id = {item.properties.cluster_id}
                            
                        />
                    );
                }

                return (
                    <PointsOld
                        lat={item.geometry.coordinates[1]}
                        lng={item.geometry.coordinates[0]}
                        key={`${item.geometry.coordinates[0] + item.geometry.coordinates[1] + getRandomString(20)}old`}
                        item={item.properties.data}
                    />
                );
            } );
        return display;
    };
    const onMapChange = ( item: any ): any => {
        if ( ready ) {
            const bounds: BBox = [
                item.bounds.nw.lng,
                item.bounds.se.lat,
                item.bounds.se.lng,
                item.bounds.nw.lat
            ];
            // const { center } = item;
            // if ( cluster.length && item.zoom > zoom ) {
            
            //     for(let i=0;i<cluster.length;i++){
            //         if(cluster[i].properties.cluster){
            //             let xoom = superCluster.getClusterExpansionZoom( cluster[i] ? cluster[i].properties.cluster_id : 0 )
            //             let xenter = { lat: cluster[0].geometry.coordinates[1], lng: cluster[0].geometry.coordinates[0] }
            //             setCenter( xenter )
            //             setZoom( xoom );
            //             break
            //         }
            //     }
                
            // } else {
                // setCenter( item.center )
                // setZoom( item.zoom );
            // }
            setCenter( item.center )
            setZoom( item.zoom );
            setBounds( bounds )

            if ( data.length ) {
                updateClusters( bounds, zoom )
            }
        } else {
            setClustures( [] )
        }
    };
    const initialize = useCallback( () => {
        axios.get( url ).then( ( response ) => {
            const list = response.data.observations;
            if ( list instanceof Array ) {
                setData( list.map( ( item ) => ( {
                    key: item.date ? item.date.toString() + new Date().toString() : "",
                    date: item.observationDateUTC,
                    properties: {
                        animalId: item.animalId,
                        location_accuracy: item.location_accuracy,
                        activity: item.activity,
                        temperature: item.temperature,
                        battery_charge: item.battery_charge,
                    },
                    location: item.latitude && { lat: parseFloat( item.latitude ), lng: parseFloat( item.longitude ) },
                } ) ).sort( ( a: any, b: any ) => a.date - b.date ) )
                setTemperaturedAsset( list.filter( rec => rec.temperature > 30 ).length )

                setNoActivety( list.filter( rec => {
                    if ( timeDifference( rec.observationDateUTC ) > 12 ) {
                        return rec
                    }
                } ).length );

                let points = list.map( record => getGeoJSON( record ) )
                superCluster.load( points );
                updateClusters( bounds, zoom )

            }
            list && setTotalAsset( list.length ) 
        } )
    }, [url] );

    useEffect( () => {
        // if url ready (not end with '/')
        if ( url[url.length - 1] !== '/' ) {
            initialize();

        }

    }, [url, initialize] );
    // useEffect( () => {
    //     setShowHistory( historyRefresh )
    // }, [historyRefresh] );

    // useEffect(()=>{
    //     if(zoom){
    //         console.log("zooom======>",zoom,cluster)
    //         updateClusters(bounds,zoom)
    //         return
    //     }

    // },[zoom])
    const Google = useRef<any>();

    const handleGoogleMapApi = ( path: { lat: number; lng: number }[] ) => {
        const lineSymbol = {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            scale: 4,
        };
        const core = ( google: any ) => {
            // draw lines
            const flightPath = new google.maps.Polyline( {
                path,
                geodesic: true,
                strokeColor: 'green',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                fillOpacity: 1,
                icons: [{
                    icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                    offset: '100%',
                    repeat: '300px'

                }]
            } );

            flightPath.setMap( google.map );

            // auto-center and auto-zoom
            const bounds = new google.maps.LatLngBounds();
            for ( let i = 0; i < path.length; i += 1 ) {
                if ( i === 0 ) {
                    new google.maps.Marker( {
                        position: { lat: path[i].lat, lng: path[i].lng },
                        title: "#" + "Start",
                        map: google.map,
                    } );
                }
                if ( i === path.length - 1 ) {
                    new google.maps.Marker( {
                        position: { lat: path[i].lat, lng: path[i].lng },
                        title: "#" + "End",
                        map: google.map,
                    } );
                }
                bounds.extend( new google.maps.LatLng( path[i].lat, path[i].lng ) );
            }
            // auto zoom
            google.map.fitBounds( bounds );
            // auto center
            google.map.panToBounds( bounds );
        };


        const myGoogle = Google.current;
        if ( myGoogle ) {
            core( myGoogle );
        } else {
            const timer = setInterval( () => {
                const newGoogle = Google.current;
                if ( newGoogle ) {
                    core( newGoogle );
                    clearInterval( timer );
                }
            }, 1000 );
        }
    };

    const doLocationDisplay = useCallback( () => {
        if ( historyData[0] ) {

            // data control for demo purpose, only work when there is data in our lib

            setLocationsDisplay( historyData.map( ( item: any, index: any ) => {
                const { lat, lng } = item.location;
                let position: 'start' | 'middle' | 'end';
                switch ( true ) {
                    case index === 0: {
                        position = 'end';
                        break;
                    }
                    case index === data.length - 1: {
                        position = 'start';
                        break;
                    }
                    default: {
                        position = 'middle';
                    }
                }

                return (
                    <MyMarker
                        lat={lat}
                        lng={lng}
                        key={item.key}
                        properties={item.properties}
                        time={moment( item.date ).format( 'D MMM YYYY' )}
                        position={position}
                    />
                );
            } ) );
            handleGoogleMapApi( historyData.sort( ( a: any, b: any ) => {
                return Date.parse( a.date.valueOf() ) - Date.parse( b.date.valueOf() )

            } ).map( ( item: any ) => {
                return item.location
            } ) );
        }
    }, [historyData] );

    useEffect( () => {
        // doHistoryDisplay();
        doLocationDisplay();
    }, [historyData, doLocationDisplay] );
    useEffect(()=>{
        setZoom(5)
    },[historyRefresh])
    let componentS = displayClusters()

    return (
        // <Wrapper className="row mrg-0 pad-0-v pad-md-h">
        historyRefresh ? <AssetEvent url={historyURLWithId} /> : <Left className="col-xl-12 pad-0 height-65vh margin-10" style={{ width: "98%" }}>
            <GoogleMap
                bootstrapURLKeys={{
                    key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={( google ) => {
                    Google.current = google;
                    setReady( true )
                }}
                defaultCenter={defaultCenter}
                center={center}
                zoom={zoom}
                options={
                    {
                        // mapTypeId: Google.current.MapTypeId.SATELLITE,
                        minZoomOverride: true,
                        // mapTypeControl: true,
                        mapTypeId: 'satellite',
                        // minZoom: minimumZoom,
                        styles
                    }
                }
                onChange={( item ) => onMapChange( item )}


            >
                {componentS ? componentS : []}
            </GoogleMap>

        </Left>
    );
};

export default AssetMap;
