/**
 * The Devices page
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, {  useState } from 'react';
import {
  PageWrapper,
  Body,
  Grid,
} from '../../../statics/styles/StyledComponents';
import Selection from '../../../components/Selection';
import movement from '../../../statics/images/movement.svg'
import noActivity from '../../../statics/images/noActivity.svg'
import temperature from '../../../statics/images/temperature.svg'
import Statistics from '../../../components/Statistics';
import styled from 'styled-components';
import SearchBox from '../../../components/SearchBox';
import totalOrders from '../../../statics/images/totalOrders.svg'
import AssetMap from './AssetMapView';
import refresh from '../../../statics/images/refresh-icon.svg';
import moment from 'moment'
import { isMobile } from 'react-device-detect';

const list = [{
  key: 'asset-management',
  label: 'Asset Management',
}];
const SearchContainer = styled.div`
    margin: 10px 0px 10px 0px
`
const ShowHeader = styled.div`
  display:flex
  margin-top: ${( { margin }: { margin?: string } ) => {
    if ( margin ) return margin;
    return '-5px';
  }};
`
const Header = styled.div`
*{
  color: white;
}

&>p{
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
}
display:flex
align-item:flex-end;
justify-content:flex-end
`;

const url = `https://07z1zfz75l.execute-api.ap-southeast-2.amazonaws.com/develop/ceres-tagdetails?last=true`

const AssetManagement = () => {
  const [historyRefresh, setHistoryRefresh] = useState( false )
  const [totalAsset,setTotalAsset] = useState(0) 
  const [temperaturedAsset,setTemperaturedAsset] = useState(0) 
  const [noActivety,setNoActivety] = useState(0) 
  const CardsData = [
    {
      bgOne: "#2ABAD3",
      bgTwo: "#3CEAA3",
      icons: movement,
      title: "Movement Alert",
      value: 0,
    },
    {
      bgOne: "#6B8DEC",
      bgTwo: "#49C4F2",
      icons: noActivity,
      title: "No Activity",
      value: noActivety,
    },
    {
      bgOne: "#EE931E",
      bgTwo: "#FBD224",
      icons: temperature,
      title: "Temperature Alert",
      value: temperaturedAsset
    },
    {
      bgOne: "#E2382E",
      bgTwo: "#EF7F48",
      icons: totalOrders,
      title: "Total Assets",
      value: totalAsset
    },
  
  ]
  return <PageWrapper>
    <Header>
     { !isMobile && <Selection
        list={list}
        upperCase
      />}
      <ShowHeader>
        {isMobile ?  <span onClick={() => {
          setHistoryRefresh( false )
        }}
          style={{ marginRight: "10px", marginTop: "15px", cursor: "pointer" }}><img src={refresh} />
        </span>:<span onClick={() => {
          setHistoryRefresh( false )
        }}
          style={{ marginRight: "10px", marginTop: "45px", cursor: "pointer" }}><img src={refresh} />
        </span> }
      </ShowHeader>
    </Header>
    <Body>
      <Grid>

        {CardsData.map( rec => <Statistics
          bgOne={rec.bgOne}
          bgTwo={rec.bgTwo}
          icons={rec.icons}
          title={rec.title}
          value={rec.value}
        /> )}
      </Grid>
      <SearchContainer >
        <SearchBox placeHolder="Search by ADI Number or Date" />
      </SearchContainer>
     <AssetMap
        url={url}
        historyUrl={`https://07z1zfz75l.execute-api.ap-southeast-2.amazonaws.com/develop/ceres-tagdetails?start=${moment().subtract(2,'d').unix()}&end=${moment().unix()}&id=`}
        historyRefresh={historyRefresh}
        setTotalAsset={setTotalAsset}
        setTemperaturedAsset={setTemperaturedAsset}
        setNoActivety={setNoActivety}
        setHistoryRefresh={setHistoryRefresh}
        
      />
     

    </Body>
  </PageWrapper>

}

export default AssetManagement;
