/**
 * The sophisticated version of the reusable table:
 *    user enter an array of objects (whose first property must be primary key) and
 *    it displays the array as an table automatically when data is valid
 *    or display the ''NoData' component provided by user when data is invalid (e.g. undefined)
 *
 * The basic features include:
 *    - pagination
 *    - sort sortable data (string) by any one string column
 *    - filter filterable data (string) by any one or more than one column
 *    - add html components to table
 *
 * By configuration, user can also add additional controllers, including:
 *    - selection: when providing onselect function, there will be select boxes;
 *            the function will be called with the selected items upon select
 *    - collapse: when providing a collapse object including a component and a function,
 *            when user click on a row, the provided component will toggle between show and hide,
 *            and the function will be called with the current records primary key for
 *            the component's configuration
 *
 * TODO: add clearFiltering feature, and do clearFiltering when change pages
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 18 Dec 2019
 */

import React, { useEffect, useState, useCallback } from 'react';
import AntdIcon from '@ant-design/icons-react';
import { SearchOutline, CheckOutline, CodeSandboxOutline, LeftOutline, RightOutline } from '@ant-design/icons';
import TableSortButton from './sort';
import { singleRecord, subItem } from '../../utilities/types';
import Selection from '../Selection';
import { Button } from '../../statics/styles/StyledComponents';
import { baseList } from './pagination';
import {
  Tfoot, DropDownWrapper, PaginationWrapper, PageNumberWrapper, PageNumber, TextInput, MyIcon,
  TableDisplay, Thead, Tbody, Th, FullSizedTh, Tr, TrBrief, Td, DetailsWrapper, NoDataMessage,
  PaginationButton, Wrapper, Box, TbodyWrapper,
} from './styles';
import { FormatTableHeader } from '../../utilities/Functions/FormatWords';
import styled from 'styled-components';
import coordinateLibrary from '../../containers/Pages/ProductTracking/SampleCoordinateLibrary';

type Props = {
  // In original list, the first property must be the primary key
  originalList: singleRecord[];
  // things to show when there is not data
  NoData: React.ReactElement;
  collapse?: {
    // component to toggle upon row click
    details: React.ReactElement;
    // function to call with the row's primary key upon click
    onSelect: Function;
  };
  // function to call when select records
  onSelect?: Function;
};
const Table = ( props: Props ) => {
  const {
    originalList,
    NoData,
    collapse,
    onSelect,
  } = props;

  /* ------------------------------------- models ------------------------------------- */
  // keys of the given data (primary key + all column name)
  const [keys, setKeys] = useState<string[]>( [] );
  // how many elements to show on each page
  const [paginationBase, setPaginationBase] = useState<number>( 10 );
  // the current page number
  const [pageNumber, setPageNumber] = useState<number>( 1 );
  // the total possible page number
  const [totalPageNumber, setTotalPageNumber] = useState<number>( 0 );
  // an array of the array of records on each page
  const [pagesGroup, setPagesGroup] = useState<singleRecord[][]>( [] );
  // the current list in pagination
  const [listInCurrentPage, setListInCurrentPage] = useState<singleRecord[]>( [] );
  // the list to display (after either select a page or sorting or/and filtering)
  const [displayList, setDisplayList] = useState<singleRecord[]>( [] );
  // list for selection use
  const [listSelected, setListSelected] = useState<singleRecord[]>( [] );
  // values for filtering the table
  const [filters, setFilters] = useState<singleRecord>( {} );
  // the marker of which row should show in collapse mode
  const [selected, setSelected] = useState<string>( '' );
  // the table body display - Array
  const [bodyDisplay, setBodyDisplay] = useState<any[]>( [] );
  // the table header display - Array
  const [headerDisplay, setHeaderDisplay] = useState<any>( null );
  // the table header search box display - Array
  const [searchDisplay, setSearchDisplay] = useState<any>( null );
  // the page number display
  const [pageNumberDisplay, setPageNumberDisplay] = useState<any>( null );

  /* ------------------------------------- controls ------------------------------------- */
  // update pagination config when original data or pagination base changes
  const updatePaginationModel = useCallback( () => {
    if ( originalList.length === 0 ) {
      setTotalPageNumber( 1 );
      setPagesGroup( [] );
      setPageNumber( 1 );
    } else {
      // update total page number;
      const newTotalNumber = Math.ceil( originalList.length / paginationBase );
      setTotalPageNumber( newTotalNumber );
      // update data on every page
      const pages = [];
      for ( let i = 0; i < newTotalNumber; i += 1 ) {
        pages.push( originalList.slice( i * paginationBase, ( i + 1 ) * paginationBase ) );
      }
      setPagesGroup( pages );
      // return to page 1
      setPageNumber( 1 );
    }
  }, [originalList, paginationBase] );

  useEffect( updatePaginationModel, [originalList, paginationBase] );

  // update keys & config filters schema when original list changes
  const updateFilters = useCallback( () => {
    if ( originalList[0] ) {
      const newKeys = Object.keys( originalList[0] );
      setKeys( newKeys );
      const newFilters: singleRecord = {};
      for ( let i = 1; i < newKeys.length; i += 1 ) {
        const prop = newKeys[i];
        newFilters[prop] = '';
      }
      setFilters( newFilters );
    }
  }, [originalList] );

  useEffect( updateFilters, [originalList] );

  // update listInCurrentPage, displayList based on pagesGroup and the current page number
  const updateListInCurrentPage = useCallback( () => {
    if ( pagesGroup[pageNumber - 1] ) {
      setListInCurrentPage( pagesGroup[pageNumber - 1] );
      setDisplayList( pagesGroup[pageNumber - 1] );
    }
  }, [pagesGroup, pageNumber] );

  useEffect( updateListInCurrentPage, [pagesGroup, pageNumber] );

  // do multifactor filtering agains the current page and update displayList
  const doFilter = useCallback( () => {
    // do filtering
    let newList = [...listInCurrentPage];
    // filter the newList with all filters out there
    for ( let j = 1; j < keys.length; j += 1 ) {
      const innerProp = keys[j];
      if ( filters[innerProp] ) {
        const regex = filters[innerProp].toLowerCase();
        newList = newList.filter(
          ( item: singleRecord ) => JSON.stringify(
            item[innerProp],
          ).toLowerCase().search( regex ) > -1,
        );
      }
    }
    setDisplayList( newList );
  }, [filters, keys, listInCurrentPage] );

  useEffect( doFilter, [filters, keys, listInCurrentPage] );

  // update page number panel display, when total page number and current page number changes
  const updatePageNumbersDisplay = useCallback( () => {
    // all posible page numbers from 1 to totalPageNumber
    const pageNumbers = Array.from( new Array( totalPageNumber ).keys(), ( key: number ) => key + 1 );

    setPageNumberDisplay( (
      <PaginationWrapper>
        <PaginationButton
          type="button"
          disabled={pageNumber === 1}
          onClick={() => {
            setPageNumber( pageNumber - 1 );
          }}
        >
          <AntdIcon type={LeftOutline} style={{ verticalAlign: "middle" }} /> Prev
        </PaginationButton>
        {( pageNumber > 5 && pageNumber != 5 ) && ( <><PageNumber
          selected={pageNumber === 1}
          key={1}
          type="button"
          onClick={() => {
            setPageNumber( 1 );
          }}
        >
          {1}
        </PageNumber>
          <PageNumber
            selected={pageNumber === 2}
            key={2}
            type="button"
            onClick={() => {
              setPageNumber( 2 );
            }}
          >
            {2}
          </PageNumber>
          <PageNumber
            type="button"
            selected={false}
            onClick={() => {
              let q = pageNumber % 5
              q == 0 ? setPageNumber( pageNumber - 5 ) : setPageNumber( pageNumber - q );
            }}

          >
            ...
          </PageNumber></> )

        }
        <PageNumberWrapper>
          {
            // only show at most 5 numbers including the current one
            pageNumbers.map( ( number ) => {
              // check which group of length 5 the current page number belongs to
              const group = Math.ceil( pageNumber / 5 );
              // only show numbers in this group
              if ( number <= ( group - 1 ) * 5 || number > group * 5 ) return null;
              return (
                <PageNumber
                  selected={pageNumber === number}
                  key={number}
                  type="button"
                  onClick={() => {
                    setPageNumber( number );
                  }}
                >
                  {number}
                </PageNumber>
              );
            } )
          }
          {( pageNumber !== pageNumbers.length && pageNumber !== pageNumbers.length - 1 && Math.ceil( pageNumbers.length / 5 ) !== Math.ceil( pageNumber / 5 ) ) && <PageNumber
            type="button"
            selected={false}
            onClick={() => {
              let q = pageNumber % 5
              q == 0 ? setPageNumber( pageNumber + 1 ) : setPageNumber( pageNumber + 5 );
            }}


          >
            ...
          </PageNumber>}
          {( pageNumber !== pageNumbers.length && pageNumber != ( pageNumbers.length - 1 ) && Math.ceil( pageNumbers.length / 5 ) !== Math.ceil( pageNumber / 5 ) ) && pageNumbers.slice( 1 ).slice( -2 ).map( number => {
            return (
              <PageNumber
                selected={pageNumber === number}
                key={number + "page"}
                type="button"
                onClick={() => {
                  setPageNumber( number );
                }}
              >
                {number}
              </PageNumber>
            );
          } )}
        </PageNumberWrapper>
        <PaginationButton
          type="button"
          disabled={pageNumber === totalPageNumber}
          onClick={() => {
            setPageNumber( pageNumber + 1 );
          }}
        >
          Next  <AntdIcon type={RightOutline} style={{ verticalAlign: "middle" }} />
        </PaginationButton>
      </PaginationWrapper>
    ) );
  }, [totalPageNumber, pageNumber] );

  useEffect( updatePageNumbersDisplay, [totalPageNumber, pageNumber] );

  // initialize or update header display
  const updateHeaderDisplay = useCallback( () => {
    // two seperate lines
    const headers = [];
    const searchers = [];
if(originalList.length){
    // only add select/deselect all in select mode
    if ( onSelect ) {
      // union and difference to assist select/deselect all items in displayList
      const union = listSelected.concat( displayList.filter( ( item: singleRecord ) => (
        !listSelected.map( ( record: singleRecord ) => record[keys[0]] ).includes( item[keys[0]] )
      ) ) );
      const difference = listSelected.concat( displayList ).filter( ( item: singleRecord ) => (
        !displayList.map( ( record: singleRecord ) => record[keys[0]] ).includes( item[keys[0]] )
      ) );

      headers.push(
        <Th
          key={`${keys[0]}select-all-line-first`}
        >
          <Button
            type="button"
            onClick={() => {
              if ( union.length > listSelected.length ) setListSelected( union ); // select all
              else setListSelected( difference ); // deselect all
            }}
          >
            <Box>
              {
                union.length === 0 || union.length > listSelected.length
                  ? null
                  : <AntdIcon type={CheckOutline} />
              }
            </Box>
          </Button>
        </Th>,
      );
      searchers.push(
        <FullSizedTh
          key={`${keys[0]}select-all-line-second`}
        >
          <TextInput
            className="my-input"
            placeholder=""
            disable
          />
        </FullSizedTh>,
      );
    }
    // in all modes
    for ( let i = 1; i < keys.length; i += 1 ) {
      // if searchable & sortable (the content's type is string)
      if ( typeof originalList[0][keys[i]] === 'string' ) {
        headers.push(
          <Th
            key={`header${keys[i]}`}
          >
            <TableSortButton
              originalList={displayList} // sort against the displayList
              sorter={keys[i]}
              onUpdate={( newList: any ) => {
                setDisplayList( [...newList] );
              }}
            />
          </Th>,
        );
        searchers.push(
          <FullSizedTh key={keys[i]}>
            <MyIcon>
              <AntdIcon type={SearchOutline} />
            </MyIcon>
            <TextInput
              className="my-input"
              placeholder=""
              onChange={( input: string ) => {
                // update filters array
                const newFilters = {
                  ...filters,
                };
                newFilters[keys[i]] = input;
                setFilters( newFilters );
              }}
            />
          </FullSizedTh>,
        );
      } else {
        // if not sortable or searchable
        headers.push(
          ( keys[i] !== "email" && keys[i] !== "userStatus" ) ? <Th
            key={`header${keys[i]}`}
          >
            {FormatTableHeader( keys[i] )}
          </Th> : <Th
            key={`header${keys[i]}`}
          >
              {FormatTableHeader( keys[i] )}
            </Th>,
        );
        searchers.push(
          <FullSizedTh
            key={keys[i]}
          >
            <TextInput
              className="my-input"
              placeholder=""
              disable
            />
          </FullSizedTh>,
        );
      }
    }
    setHeaderDisplay( headers );
    setSearchDisplay( searchers );
  }
  }, [
    keys,
    displayList,
    filters,
    originalList,
    onSelect,
    listSelected,
  ] );

  useEffect( updateHeaderDisplay, [
    keys,
    displayList,
    filters,
    onSelect,
    listSelected,
  ] );

  // update body display
  const updateBodyDisplay = useCallback( () => {
    // tool, deal with record select/unselect
    const onRecordSelect = ( record: singleRecord ) => {
      const listWithoutCurrentObject = listSelected.filter( ( item: singleRecord ) => (
        item[keys[0]] !== record[keys[0]]
      ) );
      // if the current object has already exist, kick it out
      if ( listWithoutCurrentObject.length < listSelected.length ) {
        setListSelected( listWithoutCurrentObject );
      } else {
        // if the current object hasn't been selected, add it in
        setListSelected( [...listSelected, record] );
      }
    };

    setBodyDisplay( displayList.map( ( record: singleRecord ) => {
      // prepare one row
      const oneRecord = [];
      // add selector only in select mode
      if ( onSelect && record.key != "" ) {
        let display: any = null;
        if ( listSelected.filter( ( item: singleRecord ) => (
          item[keys[0]] === record[keys[0]]
        ) ).length > 0 ) {
          display = <AntdIcon type={CheckOutline} />;
        }
        oneRecord.push(
          <Td
            key={`selectButton${record[keys[0]]}`}
            onClick={( event ) => {
              event.stopPropagation();
              onRecordSelect( record );
            }}
          >
            <Button
              type="button"
            >
              <Box>
                {display}
              </Box>
            </Button>
          </Td>,
        );
      }

      // in all mode

      for ( let i = 1; i < keys.length; i += 1 ) {
        if ( record[keys[i]] || keys[i] !== "detail" ) {
          oneRecord.push(
            ( keys[i] !== "email" && keys[i] !== "userStatus" ) ? <Td
              key={`record-${keys[i]}`}
            >
              {( record as any )[keys[i]]}
            </Td> : <Td
              key={`record-${keys[i]}`}
            >
                {( record as any )[keys[i]]}
              </Td> ,
          );
        }
      }
      // in collapse mode, add collapsible component
      const key = `tr-record-${record[keys[0]]}`;
      if ( collapse && record.key != "" ) {
        return (
          <Tr
            key={key}
          >
            <TrBrief
              onClick={() => {
                if ( selected === key ) {
                  // toggle to deselected
                  setSelected( '' );
                } else {
                  // toggle to selected
                  setSelected( key );
                  collapse.onSelect( record[keys[0]] );
                }
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              {oneRecord.filter( rec => rec.key != "record-detail" )}
            </TrBrief>
            {
              // show when selected, collapse when deselect
              selected === key
                ? (
                  <DetailsWrapper>
                    {collapse.details}
                  </DetailsWrapper>
                )
                : null
            }
          </Tr>
        );
      }
      if ( record.key != "" ) {
        // in none-collapse mode
        return (
          <Tr
            key={key}
          >
            <TrBrief>
              {oneRecord}
            </TrBrief>
          </Tr>
        );
      }
    } ) );
  }, [
    displayList,
    keys,
    setBodyDisplay,
    selected,
    collapse,
    onSelect,
    listSelected,
  ] );

  useEffect( updateBodyDisplay, [
    displayList,
    keys,
    setBodyDisplay,
    selected,
    collapse,
    onSelect,
    listSelected,
  ] );

  // run props.onSelect function when rows are selected
  const doOnSelect = useCallback( () => {
    if ( onSelect ) {
      onSelect( listSelected );
    }
  }, [listSelected, onSelect] );

  useEffect( () => {
    doOnSelect();
  }, [listSelected, doOnSelect] );
  return (
    <Wrapper>
      {
        originalList.length > 0
          ? (
            <TableDisplay>
              <TbodyWrapper>
                <Tbody>

                  <Thead>
                    <Tr>
                      <TrBrief>
                        {headerDisplay}
                      </TrBrief>
                    </Tr>
                    {/* <Tr>
                  <TrBrief>
                    {searchDisplay}
                  </TrBrief>
                </Tr> */}
                  </Thead>
                  {bodyDisplay}
                </Tbody>
              </TbodyWrapper>
              <Tfoot>
                {/* <DropDownWrapper>
                  <Selection
                    list={baseList}
                    onClick={(item: subItem) => {
                      setPaginationBase(parseInt(item.key, 10));
                    }}
                    dropdown
                    upwards
                  />
                </DropDownWrapper> */}
                {pageNumberDisplay}
              </Tfoot>
            </TableDisplay>
          )
          : (
            <NoDataMessage>
              {NoData}
            </NoDataMessage>
          )
      }
    </Wrapper>
  );
};

export default Table;
