import styled from 'styled-components';
import { Button } from '../../../statics/styles/StyledComponents';

export const Wrapper = styled.div`
  background: rgb(75, 75, 75);
  padding: 1rem;
  margin-left:0px !important;
  ${( props ) => {
    if ( props.theme.mobile ) {
      return `
        display: block;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        ${props.theme.myScrollbar}
      `;
    }
    return `
      width: 100%;
    `;
  }}
`;

export const Left = styled.div`
  flex: 50%;
  height: 100%;
  padding: 0.5rem;
  background: rgb(100, 100, 100);
`;

export const Right = styled.div`
  flex: 50%;
  height: 100%;
  padding: 0.5rem;
`;

export const InnerWrapper = styled.div`
width: 100%;
height: 100%;
overflow-y: scroll;
${( props ) => props.theme.myScrollbar}
`;

export const Record = styled.div`
display: flex;
backgroung:#383838;
&:last-child{
  &>div:first-child:after{
    display: none;
  }
}

&>*{
  padding: 0rem 1rem;
}
`;

export const RecordLeft = styled.div`
position: relative;
height: auto;
width: 10em;
text-align: right;
padding-right: 2rem;
display: flex;

&:before{
  content: '';
  position: absolute;
  right: 0.3rem;
  top: 2.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: ${( props ) => props.theme.main};
}

&:after{
  content: '';
  position: absolute;
  right: 0.7rem;
  top: 3.5rem;
  width: 0;
  height:5em;
  border: 0.1rem solid ${( props ) => props.theme.main};
}
`;

export const RecordLeftInnerWrapper = styled.div`
margin: auto;
`;

export const LeftLineFirst = styled.div`
line-height: auto;
font-family: Gotham book;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 130%;
text-align: right;
color: #FFFFFF;
`;

export const LeftLineSecond = styled.div`
  font-family: Gotham light;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  text-align: right;
  color: #FFFFFF;
  opacity: 0.6;
`;

export const RecordRight = styled.div`
flex: 85%;
padding: 0.5rem 1rem 0.5rem 2rem;
min-width: 24rem;
min-height:7rem;
position: relative;
&:before{
  content: ' ';
  position: absolute;
  left: 0rem;
  top: 2rem;
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-right: 1rem solid rgb(75,75,75);
  border-bottom: 1rem solid transparent;
  border-left: 1rem solid transparent;
}
`;

export const RecordRightBackground = styled( Button )`
  background: #333333;
  width: 100%;
  padding: 0.5rem;
  position: relative;
  border: 2px solid rgb(75,75,75);
  border-radius: 2px;
  overflow: scroll;
`;

export const RightLineFirst = styled.div`
&>*{
  display: inline-block;
  font-family: Gotham book;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #FFFFFF;
}
`;

export const RightLineSecond = styled.div`
  font-family: Gotham Book;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #FFFFFF !important;
  opacity: 0.6;
`;

export const Action = styled.div`
display: inline-block;
padding-right: 2rem;
position: relative;
&:after{
  content: '';
  position: absolute;
  padding-left: 0.75rem;
}
`;

export const Company = styled.div``;

export const Location = styled.div`
    font-family: Gotham Light;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF !important;
    opacity: 0.6;
`;

export const Loading = styled.div``;

export const Marker = styled.div`
  position: absolute;
  overflow: visible;
  left: -0.8rem;
  width: 1.5rem;
  height: 1.5rem;
  bottom: 0;

  ${( { position }: { position: 'start' | 'end' | 'middle' } ) => {
    switch ( position ) {
      case 'start': return `
        z-index: 2;
        left: -0.3rem;
        path{
          color: red;
        }
      `;
      case 'end': return `
        z-index: 1;
        width: 1.7rem;
        height: 1.7rem;
        bottom: -1rem;
        path{
          color: red;
        }
      `;
      case 'middle':
      default: return `
        z-index: 0;
        bottom: 0.45rem;
        path{
          color: green;
        }
      `;
    }
  }};

  &:hover{
    *{
      display: block;
    }
  }

  &>div{
    position: absolute;
    display: none;
    bottom: 2rem;
    right: -2.9rem;
    width: 7.5rem;
    padding: 0.5rem;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    
    &:after{
      content: '';
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      transform: rotate(45deg);
      background: white;
      left: 3.25rem;
    }
    
    *{
      color: black;  
    }

    h1{
      font-size:1.2rem;
      margin: 0;
      padding: 0.5rem 0;
    }

    p{
      margin: 0;
    }
  }
`;

export default null;
