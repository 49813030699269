/**
 * Action generating functions
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import { types } from './types';
import {
  Payload,
  clearAlertsDataType,
  KeyCreateSendPayload,
  KeyActivatePayload,
} from '../../utilities/types';

export const actions = {
  openSocket: () => (
    {
      type: types.SAGA_OPEN_SOCKET,
      payload: {},
    }),
  sendMessage: (
    data: clearAlertsDataType[] | KeyCreateSendPayload | KeyActivatePayload,
    action: string,
  ) => (
    {
      type: types.SAGA_SEND_MESSAGE,
      payload: {
        action,
        data,
      },
    }),
  closeSocket: () => (
    {
      type: types.SAGA_CLOSE_SOCKET,
    }),
  // setAlerts: (alerts: Alerts) => (
  //   {
  //     type: types.GET_ALERTS,
  //     payload: alerts,
  //   }),
  // setError: (error: Error) =>(
  // {
  //    type:types.GET_ALERT_NUMBER,
  //    error: error
  // }),
  // setRealTimeData: (data: any) => (
  //   {
  //     type: types.GET_REALTIME_DATA,
  //     payload: data,
  //   }),
  setData: (data: Payload) => (
    {
      type: types.SET_DATA,
      payload: data,
    }),
  clearResults: () => (
    {
      type: types.CLEAR_RESULTS,
    }),
};

export default null;
