/**
 * This is the Selection component with two mode
 *  - subnavigations when dropdown=false
 *  - dropdown list when dropdown=true
 * It calls a callback function with the current item on each click
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { CaretDownFill, CaretUpFill } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import { subItem } from '../../utilities/types';
import { Button } from '../../statics/styles/StyledComponents';


type Props = {
  list: subItem[];
  onClick?: Function;
  dropdown?: boolean;
  upperCase?: boolean;
  upwards?: boolean;
  selectedValue?:any;
};

type State = {
  selected: subItem;
  collapse: boolean;
  selectMode: boolean;
};

const TagWrapper = styled.div`
  width: 100%;
  background:#222222
  margin: 40px 0px 40px 0px
`;

const Tag = styled( Button )`
  background: ${( { selected, ...props }: { selected: boolean;[props: string]: any } ) => {
    if ( selected ) return props.theme.main;
    return 'none';
  }};
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  width: 20rem;
  text-align: center;
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0 0 -1px -1px;
  border-radius: 5px 5px 0 0;
`;
const Title = styled.div`
  color: white;
  font-family: Gotham bold;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 17.8px;
  cursor: pointer;
  width: 35rem;
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0 0 -1px -1px;
  border-radius: 5px 5px 0 0;
`;

const DropDownWrapper = styled.div`
  position: relative;
  width: 100%;
  hight:70px;
`;

const DropDownLabel = styled( Button )`
  cursor: pointer;
  font-size: 1.4rem;
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span:last-child{
    margin-top: 0.2rem;
  }
`;

const DropDown = styled.div`
  position: absolute;
  z-index: 10000;
  left: 0;
  width: 100%;
  background: #383838;
  box-sizing: content-box;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid gray;
  
  ${( { upwards }: { collapse: boolean; upwards: boolean } ) => {
    if ( upwards ) {
      return `
        bottom: 3rem;
        left: -1rem;
        width: 120%;
      `;
    }
    return '';
  }}

  display: ${( { collapse }: { collapse: boolean; upwards: boolean } ) => {
    if ( collapse ) return 'none';
    return 'block';
  }};
`;

const DropDownItem = styled( Button )`
  background: ${( { selected, ...props }: { selected: boolean;[prop: string]: any } ) => {
    if ( selected ) return props.theme.primary;
    return 'none';
  }};
  padding: 0.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
`;

class Selection extends Component<Props, State> {
  constructor( props: Props ) {
    super( props );
    const { list,selectedValue } = this.props;
  
    this.state = {
      selected: selectedValue ? selectedValue :list[0] ,
      collapse: true,
      selectMode: false,
    };
  }

  componentDidMount() {
    const { selected } = this.state;
    // initialization
    this.handleItemClick( selected );
  }
componentWillReceiveProps(nextProps:any){
  if(nextProps.selectedValue!==this.props.selectedValue){
    this.setState({
      selected:nextProps.selectedValue
    })
  }
}
  generateList = ( list: subItem[] ): JSX.Element => {
    const { selected } = this.state;
    const { upperCase } = this.props;
    return (
      <TagWrapper data-test="list">
        {
          list.map( ( anItem ) => (
            <Title
              onClick={() => { this.handleItemClick( anItem ); }}
              data-test="item"
            >
              {upperCase ? anItem.label.toUpperCase() : anItem.label}
            </Title>
          ) )
        }
      </TagWrapper>
    );
  };

  generateDropdown = ( list: subItem[] ): JSX.Element => {
    const { selected, collapse } = this.state;
    const { upperCase, upwards } = this.props;
    return (
      <DropDownWrapper data-test="dropdown">
        <DropDownLabel
          type="button"
          onClick={() => { this.handleLabelClick(); }}
          onBlur={() => {
            // for dropdown, only collapse on blur when not in selectMode
            const { selectMode } = this.state;
            if ( !selectMode ) {
              this.setState( {
                collapse: true,
              } );
            }
          }}
        >
          <span>
            {upperCase ? selected.label.toUpperCase() : selected.label}
          </span>
          <span>
            <AntdIcon type={
              upwards
                ? CaretUpFill
                : CaretDownFill
            }
            />
          </span>
        </DropDownLabel>
        <DropDown
          collapse={collapse}
          onMouseOver={() => {
            this.setState( {
              selectMode: true,
            } );
          }}
          onMouseOut={() => {
            this.setState( {
              selectMode: false,
            } );
          }}
          onFocus={() => { }}
          onBlur={() => { }}
          upwards={Boolean( upwards )}
        >
          {list.map( ( anItem ) => (
            <DropDownItem
              key={anItem.key}
              type="button"
              data-test="item"
              onClick={() => { this.handleItemClick( anItem ); }}
              selected={selected.key === anItem.key}
            >
              {upperCase ? anItem.label.toUpperCase() : anItem.label}
            </DropDownItem>
          ) )}
        </DropDown>
      </DropDownWrapper>
    );
  };

  handleLabelClick = () => {
    const { collapse } = this.state;
    this.setState( { collapse: !collapse } );
  };

  handleItemClick = ( anItem: subItem ): any => {
    const { onClick } = this.props;
    this.setState( {
      selected: anItem,
      collapse: true,
    } );
    if ( onClick ) { onClick( anItem ); }
  };

  render() {
    const { list, dropdown } = this.props;
    let display: any;
    if ( dropdown ) {
      display = this.generateDropdown( list );
    } else {
      display = this.generateList( list );
    }
    return display;
  }
}

export default Selection;
