/**
 * This is the UserManagement page
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { UserManagementInformation, api } from '../../../utilities/types';
import { KEYBOARD_CODES, SESSION_STORAGE_KEYS } from '../../../utilities/Functions/CONSTANTS';
import Table from '../../../components/Table';
import UserCreation from './UserCreation';
import UserDelete from './UserDelete';
import Loading from '../../../components/Loading';
import Selection from '../../../components/Selection';
import {
  WhiteButton as MyButton,
  PageWrapper,
  Header,
  Body,
} from '../../../statics/styles/StyledComponents';
import SearchBox from '../../../components/SearchBox';
import AntdIcon from '@ant-design/icons-react'
import { isMobile } from 'react-device-detect';

import { DeleteOutline, EditOutline } from '@ant-design/icons'
import { onKeyEvent } from '../../../utilities/Functions/Functions';
type PropsUserManagement = {
  apis: api[];
  writable?: boolean;
};

// const StyledButton = styled(WhiteButton)`
//   margin-bottom: 0.5rem;
// `;

const StyledButton = styled( MyButton )`
  display: inline-block;
  margin: 0 1.2rem 0.5rem 0;
  width:20%;
  }};
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height:50px;
  font-size:18px;
  text-transform:Capitalize;
  font-family:gotham bold;
`;
const StyledButtonMobile = styled( MyButton )`
  display: inline-block;
  margin: 0px 10px 0 10px;
  width:94%;
  }};
  background: #383838;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height:50px;
  font-size:18px;
  text-transform:Capitalize;
  font-family:gotham bold;
`;
const UpdateBtn = styled( MyButton )`
  background: rgba(255, 255, 255, 0) !important;
  border:0px !important;
  display:inline !important;
  width:40px;
`

const SearchContainer = styled.div`
    margin: 10px 0px 10px 0px
`
const UserManagement = ( props: PropsUserManagement ) => {
  const {
    apis,
    writable,
  } = props;
  const [listingUsersApi, createUserApi, deleteUserApi, amendUserApi] = apis;
  const [showCreateUser, setShowCreateUser] = useState( false );
  const [loading, setLoading] = useState( false );
  const [usersList, setUsersList] = useState( [] as UserManagementInformation[] );
  const [editData, setEditData] = useState<any>( null )
  const [isEdit, setIsEdit] = useState( false )
  const [filteredUser, setFilteredUser] = useState( [] as UserManagementInformation[] )
  const [searchText, setSearchText] = useState( "" )
  // const [mounted, setMounted] = useState(false);
  const update = () => {
    setLoading( true );
    axios.get( listingUsersApi.url ).then( ( response: any ) => {
      const { data } = response;
      if ( data ) {
        const { Res } = data;
        if ( Res ) {
          let formatedData: UserManagementInformation[];
          if ( writable ) {
            const currentUserName = `${sessionStorage.getItem( SESSION_STORAGE_KEYS.USER_NAME )}`;
            console.log( "user", Res, currentUserName )

            formatedData = Res.map( ( user: any ): UserManagementInformation => (
              {
                key: user.user_name,
                uname: user.preferred_name,
                userName: user.nick_name,
                userCreateDate: moment( user.user_create_date ).format( 'D MMM YYYY' ),
                userRole: `${user.user_role}`.toUpperCase(),
                userStatus: user.user_status,
                phone: user.phone_number,
                email: user.email,
                actions: (
                  <React.Fragment>
                    {currentUserName === user.nick_name ? null : <UpdateBtn
                      onClick={() => {
                        setEditData( user );
                        setIsEdit( true )
                        setShowCreateUser( true )
                      }}

                    >
                      <AntdIcon type={EditOutline}
                      />
                    </UpdateBtn>}
                    <UserDelete
                      deleteUserApi={deleteUserApi}
                      update={update}
                      setLoading={setLoading}
                      userName={user.nick_name}
                      currentUserName={currentUserName}
                      userId={user.user_id}

                    />


                  </React.Fragment>

                ),
              }
            ) );
          } else {
            formatedData = Res.map( ( user: any ): UserManagementInformation => (
              {
                key: user.user_name,
                userName: user.user_name,
                userCreateDate: moment( user.user_create_date ).format( 'D MMM YYYY' ),
                userRole: user.user_role,
                userStatus: user.user_status,
                uname: user.nick_name,
                phone: user.phone_number,
                email: user.email,
              }
            ) );
          }
          setUsersList( formatedData );
          setFilteredUser( formatedData )
          // setOriginalList(formatedData);
        }
      }
    } ).catch( ( e ) => {
      console.log( e.message );
    } ).finally( () => {
      setLoading( false );
    } );
  };
  const onSearch = () => {
    if ( searchText && searchText !== "" && usersList.length ) {
      let userFiltered = usersList.filter( ( rec: any ) => {
        return rec.uname.includes( searchText ) || rec.userName.includes( searchText )
      } )
      if ( userFiltered.length ) {

        setFilteredUser( userFiltered )

      } else {
       
        setFilteredUser([])
      }
    } else {
      setFilteredUser( usersList )
    }
  }

  const initialize = useCallback( update, [] );

  // initialization
  useEffect( () => {
    initialize();
  }, [initialize] );

  let createUserButton: any;
  let createUserForm: any;
  if ( writable ) {
    createUserButton = (
      isMobile ? <StyledButtonMobile
        type="button"
        onClick={() => { setShowCreateUser( true ); }}
        key="create-user-button"
      > Create New User </StyledButtonMobile>
        : <StyledButton
          type="button"
          onClick={() => { setShowCreateUser( true ); }}
          key="create-user-button"
        >
          Create New User
      </StyledButton>

    );
    createUserForm = (
      <UserCreation
        createUserApi={createUserApi}
        show={showCreateUser}
        isEdit={isEdit}
        onClose={() => {
          setShowCreateUser( false );
          setEditData( null );
          setIsEdit( false );
        }}
        initialize={() => { initialize(); }}
        editData={editData}
        key="create-user-form"
        amendUserApi={amendUserApi}
      />
    );
  } else {
    createUserButton = null;
    createUserForm = null;
  }
  return (
    <PageWrapper>
      <Loading show={loading} />
      <Header>
        <Selection
          list={[
            {
              key: 'user-managament',
              label: 'User Management',
            },
          ]}
          upperCase
        />
      </Header>
      <Body>
        <SearchContainer >
          <SearchBox
            placeHolder="Search User by Name, or User ID"
            onChange={( input: string ) => {
              setSearchText( input );
            }}
            onKeyEvent={( e: any ) => {
              onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
                onSearch();
              } );
            }}
          />
          {[createUserButton, createUserForm]}
        </SearchContainer>
        <Table
          originalList={filteredUser.length ?filteredUser : []}
          NoData={<p>No User</p>}
        />
      </Body>
    </PageWrapper>
  );
};

export default UserManagement;
