import styled from 'styled-components';

export const ItrazokeysChildrenWrapper = styled.div``;

export const ItrazokeysChildren = styled.div`
  height: auto;
  width: 100%;
  border: 1px gray solid;
  padding: 3rem 5rem;
  border-radius: 5px;
  background: rgb(8,8,8);

  &>*{
    display: inline-block;
    vertical-align: top;

    &:first-child{
      input[type="file"]{
        display: none;
      }

      &>*{
        display: block;
        width: 20rem;
        margin: 0.5rem 0 0.5rem 0;
      }
    }

    &:last-child{
      width: 20rem;
      height: 20rem;
      margin-left: 5rem;
    }
  }
`;

export default null;
