import styled from 'styled-components';
import { Button } from '../../statics/styles/StyledComponents';
import MyInput from '../Input/TextInput';

export const TextInput = styled(MyInput)`
  padding-left: 4rem;
`;

export const MyIcon = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 2rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: rgb(126, 126, 126);
`;

export const Wrapper = styled.div`
  height: 100vh;
  margin-bottom:10px;
  width: 100%;
`;

export const TableDisplay = styled.div`
  background: #222222;
  width: auto;
  width: 98%;
  font-size: 1.2rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin:10px 10px 10px 10px;

  *{
    color: white;
  }
}
`;

export const Thead = styled.div`
  flex: 1 1 auto;
  width: 100%;
  min-width: 100%;

  &>*{
    &:first-child{
      background: #1A1A1A, 100%;
      &>div{
        background: #1A1A1A;
        height: 68px !important;
        padding:1.5rem 1.5rem 1.5rem 0rem !important;
        text-transform: Capitalize;
      }
    }
  }
`;

export const TbodyWrapper = styled.div`
  flex: 1 1 auto;
  height: 100%;

  ${(props) => {
    // if (!props.theme.mobile) {
      return `
      overflow-y: auto;
      ${props.theme.myScrollbar}
    `;
    // }
    // return '';
  }}
`;
export const Tbody = styled.div`
  flex: 1 1 auto;
  height: 100%;
  width: max-content;
  min-width: 100%;
  ${(props) => {
    if (!props.theme.mobile) {
      return `
      overflow-y: auto;
      ${props.theme.myScrollbar}
    `;
    }
    return '';
  }}
`;

export const Tfoot = styled.div`
  flex: 1 1 auto;
  height: 100%;
  padding: 1rem;
  border-radius: 5px;
  width: 100%;
  min-width: 100%;
  font-size: 1.4rem
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  color: white;
  margin-bottom: 50px;

`;

export const DropDownWrapper = styled.div`
  width: 6rem;
  position: relative;
`;

export const PaginationWrapper = styled.div`
background: #333333;
height:50px;
display:flex;
justify-content:center;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
border-radius: 5px;
align-items:center;
padding:0px 10px 0px 10px;
`;

export const PageNumberWrapper = styled.div`
  display: inline-block;
  padding:0px 15px 0px 15px;

`;

export const PageNumber = styled(Button)`
  display: inline-block;
  width: 32px;;
  height: 50px;
  background: ${({ selected, ...props }: {selected: boolean; [prop: string]: any}) => {
    if (selected) return '#454545';
    return '#333333';
  }};
  font-size: 1.4rem;
  color: white
`;

export const PaginationButton = styled(Button)`
  display: inline-block;
  width: auto;
  font-size: 1.4rem;
  color: white;
`;


export const Th = styled.div`
  position: relative;
  flex: 1;
  overflow:visible;
  font-size:14px;
  font-family:gotham bold;
  font-weight:bold;
  white-space: nowrap;
  line-height:18.2px;
  padding: ${(props) => {
    if (props.theme.mobile) return '0.5rem';
    return '1rem 1.5rem';
  }};
`;

export const FullSizedTh = styled.div`
  position: relative;
  flex: 1;
  padding: 0;
`;

export const Tr = styled.div`
  font-family:gotham light;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  overflow:visible;
  &:nth-child(odd){
    background: #383838;
  }
  &:nth-child(even){
    background: #333333;
  }
 
`;

export const TrBrief = styled.div`
  display: flex;
  overflow:visible;
`;

export const Td = styled.div`
  flex: 1;
  overflow:visible;
  white-space: nowrap;
  width:270px;
  padding: ${(props) => {
    if (props.theme.mobile) return '2.5rem';
    return '2.5rem 1.5rem 2rem 1.5rem';
  }};
`;

export const DetailsWrapper = styled.div`
  width: 100%;
`;

export const NoDataMessage = styled.div`
  font-size: 1.5rem;
  padding: 1rem;
  color: gray;
`;

export const Box = styled.div`
  border: 0.5px solid gray;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export default null;
