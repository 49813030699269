/**
 * This is the app entrance.
 *
 * It requires user to sign-in first.
 * When successfully signed-in, user will be navigated to the authorized app
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { Component } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import axios from 'axios';
import Amplify, { Auth } from 'aws-amplify';
import styled, { ThemeProvider } from 'styled-components';
import EmailValidator from 'email-validator';
import AuthorizedApp from './Routing';
import Loading from '../../components/Loading';
import Logo from '../../components/Logo';
import TextInput from '../../components/Input/TextInput';
import { SignOutContext } from '../../components/GlobalButtons/LogOutBotton';
import {
  SESSION_STORAGE_KEYS, CHALLENGE_NAMES, AMPLIFY_ERROR_CODE, KEYBOARD_CODES,
} from '../../utilities/Functions/CONSTANTS';
import { onKeyEvent } from '../../utilities/Functions/Functions';
import {
  theme,
  Button,
} from '../../statics/styles/StyledComponents';
import DialogProvider from '../../components/Dialog';
import { CheckOutline } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import { isMobile } from 'react-device-detect'
var CryptoJS = require("crypto-js");
type State = {
  redirect: 'SignIn' | 'SetNewPassword' | 'AuthorizedApp' | 'ForgetPassword';
  showPassword: boolean;
  email: string;
  password: string;
  reset: string;
  confirm: string;
  message: string;
  type: 'warning' | 'error' | 'success';
  securityCode: string;
  focusEmail: boolean;
  focusPassword: boolean;
  focusSecure: boolean;
  focusReset: boolean;
  focusConfirm: boolean;
  auth: any;
  loading: boolean;
  remember: boolean;
};

type MessageType = 'warning' | 'error' | 'success';
const MessageForPassword = styled.p`
font-family: Gotham light;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 150%;
color: #FFFFFF;
opacity:0.7
margin-top:30px;
margin-bottom:30px;
`

const CheckBox = styled.span`
    width: 102px;
    height: 18px;
    font-family: Gotham xlight;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    line-height: 130%;
    /* or 18px */
    color: #FFFFFF;
`
const Grid = styled.div`
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
`
const LoginText = styled.p`
    width: 86px;
    font-family: Gotham Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 29px;
    margin-left: auto;
    margin-right: auto;
    margin-top:0px;
    text-align: center;
    color: #FFFFFF;
    margin-top:20px;
    margin-bottom:45px;


`
const StyledButton = styled( Button )`
  position: absolute;
  top: 12rem;
  right: 0;
  font-size: 1.16rem;
  width: 25%;
  height: 5rem;
  padding: 1rem;
  color: white;
  border-radius: 0.5rem;
  background: ${( props ) => props.theme.main};
`;

const StyledButtonLarge = styled( Button )`
  font-size: 16px;
  width: 100%;
  font-family:gotham bold;
  weight:bold;
  padding: 1em;
  height:65px;
  color: white;
  border-radius: 0.5rem;
  background: ${( props ) => props.theme.main};
  margin-bottom:30px
`;

const SignInPage = styled.div`
  height: 100vh;
  width: 100vw;
  max-height: $max-height;
  max-width: $max-width;
  overflow-y: scroll;
  padding: 5rem 0;
`;


const PositionWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled( TextInput )`
  display: ${( { display }: {
  display?: string;
  width?: string;
} ) => {
    if ( display ) return display;
    return 'block';
  }};
  margin: 3rem 0;
  border-radius: 5px;
  height:65px;;
  width: ${( { width }: {
    display?: string;
    width?: string;
  } ) => {
    if ( width ) return width;
    return '100%';
  }};
  padding: 0.5em 0.75em;
  outline: none;
  font-size: 1.4rem;

`;

const ForgetPassword = styled( Button )`
  color: red;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  font-family:Gotham xlight;
  text-align:end;
`;

const ShowPassword = styled( Button )`
  color: white;
  font-size: 1.2rem;
  position: absolute;
  bottom: ${( { bottom }: { bottom?: string } ) => {
    if ( bottom ) return bottom;
    return '0';
  }};
  right: 35px;
`;

const Message = styled.div`
  margin-top: 0.5rem;
  font-size: 1.4rem;

  font-weight: 300;
  padding: 1rem;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  color: ${( { type }: { type: MessageType } ) => {
    switch ( type ) {
      case 'warning': {
        return 'rgb(255, 241, 201)';
      }
      case 'success':
      case 'error':
      default: return 'red';
    }
  }};
  
`;

const Reminder = styled.ul`
  color: white;
  padding-left: 1.5rem;
`;
const CheckBoxContainer = styled.div`
    display: inline-block;
    background: white;
    width: 15px;
    height: 13px;
    border-radius: 2px;
    margin-right: 8px;
    color:black;
    cursor:pointer;
    background:${( { background }: { background?: string } ) => {
    if ( background ) return background;
    return '#191919';
  }};
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    padding-left:1px;

`


const amplifyConfig = {
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: `${process.env.REACT_APP_COGNITO_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_CLIENT_KEY}`,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
};

class App extends Component<{}, State> {
  // timer to automatically sign out user when session time out
  // need to be clear when logout, timeout, and unmount page
  timer: any = null;

  constructor( props: any ) {
    super( props );
    this.state = {
      redirect: 'SignIn',
      showPassword: false,
      email: '',
      password: '',
      securityCode: '',
      reset: '',
      confirm: '',
      message: '',
      type: 'warning',
      focusEmail: true,
      focusPassword: false,
      focusSecure: false,
      focusReset: false,
      focusConfirm: false,
      auth: undefined,
      loading: false,
      remember: false,
    };
    let cacheCleared = localStorage.getItem("cacheCleared")
    if(!cacheCleared){
      localStorage.clear()
      localStorage.setItem("cacheCleared","true")
    }
  }

  componentDidMount() {
  console.log("process value",process.env.REACT_APP_COGNITO_CLIENT_KEY)
  console.log("process test value",process.env.REACT_APP_TEST)

    this.checkStorage();
    Amplify.configure( amplifyConfig );
  }
  componentWillMount() {
    const idToken = JSON.parse( `${sessionStorage.getItem( SESSION_STORAGE_KEYS.IDTOKEN )}` );
    if ( !idToken ) {
      let credentials = localStorage.getItem( "credentials" )
      if ( credentials ) {
     
        var bytes  = CryptoJS.AES.decrypt(credentials, 'secret key 123');
        let dataOne = JSON.parse( bytes.toString(CryptoJS.enc.Utf8) ? bytes.toString(CryptoJS.enc.Utf8) : "" )      
        // let data = JSON.parse( credentials ? credentials : "" )
        let data = JSON.parse(dataOne ? dataOne : "")
        if ( data && data.email ) {
          this.setState( {
            email: data.email,
            remember:true,
          } )
        }
        if ( data && data.password ) {
          this.setState( {
            password: data.password
          } )
        }
      }
    }

  }

  // componentWillUnmount() {
  //   this.signout();
  // }

  automaticTimeout = ( time: number ) => {
    this.timer = setTimeout( () => {
      // eslint-disable-next-line no-alert
      alert( 'Session time out, please sign in again' );
      this.signout();
    }, time );
  };

  configAxio = ( jwtToken: string ) => {
    axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
  };

  checkStorage = () => {
    const tokenString = sessionStorage.getItem( SESSION_STORAGE_KEYS.IDTOKEN );
    // if there is a token in local storage and not expired
    if ( tokenString ) {
      const token = JSON.parse( tokenString );
      const { jwtToken, payload } = token;
      const { exp } = payload;
      const difference = exp * 1000 - new Date().getTime();
      if ( difference > 0 ) {
        this.automaticTimeout( difference );
        this.configAxio( jwtToken );
        this.setState( {
          redirect: 'AuthorizedApp',
          email: '',
          password: '',
          securityCode: '',
          reset: '',
          confirm: '',
        } );
      }
    }
  };

  // checkPassword = (password: string) => {
  //   const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{6,}$/;
  //   return regularExpression.test(password);
  // };

  signout = () => {
    this.setState( {
      loading: true,
    } );
    // {global: true}
    Auth.signOut().then( () => {
      clearTimeout( this.timer );
      sessionStorage.clear();
      // reset page location
      document.location.replace( '' );
    } ).catch( ( e ) => {
      console.log( `Sign out failed. Please try again.\n${e.message}` );
    } ).finally( () => {
      this.setState( {
        loading: false,
      } );
    } );
  };

  onReady = ( response: any ) => {
    const { signInUserSession, username } = response;
    const { idToken } = signInUserSession;
    const { jwtToken, payload } = idToken;
    const { exp } = payload;
    const difference = exp * 1000 - new Date().getTime();
    this.automaticTimeout( difference );
    this.configAxio( jwtToken );
    sessionStorage.setItem( SESSION_STORAGE_KEYS.IDTOKEN, JSON.stringify( idToken ) );
    sessionStorage.setItem( SESSION_STORAGE_KEYS.USER_NAME, signInUserSession.idToken.payload["cognito:username"] );
    if ( this.state.remember ) {
      localStorage.setItem( "credentials", CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify( { email: this.state.email, password: this.state.password } ) ), 'secret key 123').toString() ) 
     }else{
      localStorage.clear()
    }
    this.setState( {
      redirect: 'AuthorizedApp',
      email: '',
      password: '',
      securityCode: '',
      reset: '',
      confirm: '',
    } );
  };

  amplifySignIn = ( email: string, password: string ) => {
    this.setState( {
      loading: true,
    } );
    Auth.signIn( email, password ).then(
      ( response: any ) => {
        this.setState( {
          auth: response,
        } );
        switch ( response.challengeName ) {
          case CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED: {
            this.setState( {
              message: 'First time log in. Please reset your password',
              type: 'success',
              focusReset: true,
              focusConfirm: false,
              focusEmail: false,
              focusPassword: false,
              focusSecure: false,
              redirect: 'SetNewPassword',
              email: '',
              password: '',
              securityCode: '',
              reset: '',
              confirm: '',
              showPassword: false,
            } );
            break;
          }
          case undefined: {
            this.onReady( response );
            break;
          }
          default: {
            console.log( 'Challenge', response.challengeName );
          }
        }
      },
    ).catch( ( e: any ) => {
      console.log( e );
      const { code } = e;
      switch ( code ) {
        case AMPLIFY_ERROR_CODE.PasswordResetRequiredException: {
          this.setState( {
            message: e.message,
            type: 'error',
            redirect: 'ForgetPassword',
            email: '',
            password: '',
            securityCode: '',
            reset: '',
            confirm: '',
            focusReset: false,
            focusConfirm: false,
            focusEmail: true,
            focusPassword: false,
            focusSecure: false,
            showPassword: false,
          } );
          break;
        }
        default: {
          this.setState( {
            message: e.message,
            type: 'error',
            focusEmail: false,
            focusPassword: false,
            focusSecure: false,
            focusReset: false,
            focusConfirm: false,
          } );
        }
      }
    } ).finally( () => {
      this.setState( {
        loading: false,
      } );
    } );
  };

  signin = () => {
    const { email, password } = this.state;
    // validate Email
    if ( !email ) {
      this.setState( {
        message: 'Please enter a valid email/username ',
        type: 'warning',
        focusEmail: true,
        focusPassword: false,
        focusSecure: false,
        focusReset: false,
        focusConfirm: false,
      } );
      return;
    }

    // validate password
    if ( !password ) {
      this.setState( {
        message: 'Please enter your password',
        type: 'warning',
        focusEmail: false,
        focusPassword: true,
        focusSecure: false,
        focusReset: false,
        focusConfirm: false,
      } );
      return;
    }

    this.amplifySignIn( email, password );
  };

  resetPassword = () => {
    const {
      reset, confirm, auth,
    } = this.state;
    // validate password
    // if (!this.checkPassword(reset)) {
    //   this.setState({
    //     message: 'Invalid Password',
    //     type: 'warning',
    //     focusReset: true,
    //     focusConfirm: false,
    //   });
    //   return;
    // }

    // validate password
    if ( !( reset === confirm ) ) {
      this.setState( {
        message: 'Passwords do not match',
        type: 'warning',
        focusReset: false,
        focusConfirm: true,
        focusEmail: false,
        focusPassword: false,
        focusSecure: false,
      } );
      return;
    }

    this.setState( {
      loading: true,
    } );

    Auth.completeNewPassword( auth, reset, {} ).then(
      ( response: any ) => {
        console.log( 'ResetPassword', response );
        this.onReady( response );
      },
    ).catch( ( e ) => {
      this.setState( {
        message: e.message,
        type: 'error',
        focusEmail: false,
        focusPassword: false,
        focusSecure: false,
        focusReset: true,
        focusConfirm: false,
      } );
    } ).finally( () => {
      this.setState( {
        loading: false,
      } );
    } );
  };

  sendSecurityCode = () => {
    const { email } = this.state;

    // validate Email
    if ( !EmailValidator.validate( email ) ) {
      this.setState( {
        message: 'Please enter a valid email address',
        type: 'warning',
        focusEmail: true,
        focusReset: false,
        focusConfirm: false,
        focusPassword: false,
        focusSecure: false,
      } );
      return;
    }

    this.setState( {
      loading: true,
    } );

    Auth.forgotPassword( email ).then( () => {
      this.setState( {
        message: 'Security code has been sent to your email. Please check.',
        type: 'success',
        focusReset: false,
        focusConfirm: false,
        focusEmail: false,
        focusPassword: false,
        focusSecure: true,
      } );
    } ).catch( ( e: any ) => {
      this.setState( {
        message: e.message,
        type: 'error',
        focusReset: false,
        focusConfirm: false,
        focusEmail: true,
        focusPassword: false,
        focusSecure: false,
      } );
    } ).finally( () => {
      this.setState( {
        loading: false,
      } );
    } );
  };

  forgetPasswordSubmit = () => {
    const { email, password, securityCode } = this.state;
    // validate Email
    if ( !EmailValidator.validate( email ) ) {
      this.setState( {
        message: 'Please enter a valid email address',
        type: 'warning',
        focusReset: false,
        focusConfirm: false,
        focusEmail: true,
        focusPassword: false,
        focusSecure: false,
      } );
      return;
    }

    // validate password
    if ( !password ) {
      this.setState( {
        message: 'Please enter your password',
        type: 'warning',
        focusEmail: false,
        focusPassword: true,
        focusSecure: false,
        focusReset: false,
        focusConfirm: false,
      } );
      return;
    }

    this.setState( {
      loading: true,
    } );

    Auth.forgotPasswordSubmit(
      email,
      securityCode,
      password,
    ).then( ( response: any ) => {
      this.setState( {
        focusConfirm: false,
        focusEmail: false,
        focusPassword: false,
        focusReset: false,
        focusSecure: false,
        message: 'Reset Success, please wait for log in ...',
        type: 'success',
      } );
      this.amplifySignIn( email, password );
    } ).catch( ( e ) => {
      this.setState( {
        message: e.message,
        type: 'error',
      } );
    } ).finally( () => {
      this.setState( {
        loading: false,
      } );
    } );
  };

  onEmailChange = ( email: string ) => {
    this.setState( {
      focusReset: false,
      focusConfirm: false,
      focusEmail: true,
      focusPassword: false,
      focusSecure: false,
      email: email.toLowerCase(),
      // email,
    } );
  };

  onPasswordChange = ( password: string ) => {
    this.setState( {
      focusReset: false,
      focusConfirm: false,
      focusEmail: false,
      focusPassword: true,
      focusSecure: false,
      password,
    } );
  };

  onSecurityCodeChange = ( securityCode: string ) => {
    this.setState( {
      focusReset: false,
      focusConfirm: false,
      focusEmail: false,
      focusPassword: false,
      focusSecure: true,
      securityCode,
    } );
  };

  onResetChange = ( reset: string ) => {
    this.setState( {
      focusReset: true,
      focusConfirm: false,
      focusEmail: false,
      focusPassword: false,
      focusSecure: false,
      reset,
    } );
  };

  onConfirmChange = ( confirm: string ) => {
    this.setState( {
      focusReset: false,
      focusConfirm: true,
      focusEmail: false,
      focusPassword: false,
      focusSecure: false,
      confirm,
    } );
  };


  togglePasswordDisplay = () => {
    const { showPassword } = this.state;
    this.setState(
      {
        showPassword: !showPassword,
      },
    );
  };

  render() {
    const containerClass = "container-xxxs pad-lg-h mrg-xl-top";
console.log("isMobile==>",isMobile)
    const {
      redirect,
      showPassword,
      message,
      type,
      focusEmail,
      focusPassword,
      focusReset,
      focusConfirm,
      focusSecure,
      loading,
    } = this.state;
    let display: any;
    switch ( redirect ) {
      case 'AuthorizedApp': {
        display = (
          <Router>
            <SignOutContext.Provider value={() => { this.signout(); }}>
              <AuthorizedApp />
            </SignOutContext.Provider>
          </Router>
        );
        break;
      }
      case 'SetNewPassword': {
        display = (
          <div className={containerClass}>
            { message ? <Message type={type}>{message}</Message> : null}
            <Input
              className="my-input mrg-md-bottom"
              key="resetPassword"
              focus={focusReset}
              placeholder="Password"
              password={!showPassword}
              onChange={( value: string ) => this.onResetChange( value )}
              onKeyEvent={( e: any ) => {
                onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
                  this.resetPassword();
                } );
              }}
            />
            <Input
              className="my-input mrg-md-bottom"
              key="confirmPassword"
              focus={focusConfirm}
              placeholder="Confirm Password"
              password={!showPassword}
              onChange={( value: string ) => this.onConfirmChange( value )}
              onKeyEvent={( e: any ) => {
                onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
                  this.resetPassword();
                } );
              }}
            />
            <ShowPassword
              type="button"
              onClick={() => { this.togglePasswordDisplay(); }}
              bottom="2.0em"
            >
              {showPassword ? 'Hide' : 'Show'}

            </ShowPassword>
            <Reminder>
              <li>at least 6 characters</li>
              <li>must include uppercase, numeric, and special characters</li>
            </Reminder>
            <StyledButtonLarge
              className=""
              type="button"
              onClick={() => { this.resetPassword(); }}
            >
              Reset
            </StyledButtonLarge>
          </div>
        );
        break;
      }
      case 'ForgetPassword': {
        display = (
          <div className={containerClass}>
            { message ? <Message type={type}>{message}</Message> : null}
            <PositionWrapper>
              <Input
                className="my-input mrg-md-bottom"
                key="forgetEmail"
                focus={focusEmail}
                placeholder="Email Address"
                onChange={( value: string ) => this.onEmailChange( value )}
                onKeyEvent={( e: any ) => {
                  onKeyEvent( e, KEYBOARD_CODES.ENTER, () => { this.sendSecurityCode(); } );
                }}

              />
              <Input
                className="my-input mrg-md-bottom"
                key="forgetSecurityCode"
                focus={focusSecure}
                placeholder="Security Code"
                onChange={( value: string ) => this.onSecurityCodeChange( value )}
                onKeyEvent={( e: any ) => {
                  onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
                    this.forgetPasswordSubmit();
                  } );
                }}
                display="inline-block"
                width="70%"
              />
              <Input
                className="my-input mrg-md-bottom"
                key="forgetPassword"
                focus={focusPassword}
                placeholder="New Password"
                password={!showPassword}
                onChange={( value: string ) => this.onPasswordChange( value )}
                onKeyEvent={( e: any ) => {
                  onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
                    this.forgetPasswordSubmit();
                  } );
                }}
              />
              <ShowPassword
                type="button"
                onClick={() => { this.togglePasswordDisplay(); }}
                bottom="2.0em"
              >
                {showPassword ? 'Hide' : 'Show'}
              </ShowPassword>
              <StyledButton
                type="button"
                onClick={() => {
                  this.sendSecurityCode();
                }}
              >
                Send Code
              </StyledButton>
            </PositionWrapper>
            <StyledButtonLarge
              type="button"
              onClick={() => { this.forgetPasswordSubmit(); }}
            >
              Submit
            </StyledButtonLarge>
          </div>
        );
        break;
      }
      case 'SignIn':
      default: {
        display = (
          <div className={containerClass}>
            <Logo />

            <div className="relative logincard">
              <LoginText>Login</LoginText>
              {message ? <Message type={type}>{message}</Message> : null}
              <Input
                className="my-input mrg-md-bottom"
                key="signinEmail"
                focus={focusEmail}
                placeholder="Email Address / User Name"
                onChange={( value: string ) => this.onEmailChange( value )}
                onKeyEvent={( e: any ) => {
                  onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
                    this.signin();
                  } );
                }}
                value={this.state.email ? this.state.email : ""}
              />
              <Input
                className="my-input mrg-md-bottom"
                key="signinPassword"
                focus={focusPassword}
                placeholder="Password"
                password={!showPassword}
                onChange={( value: string ) => this.onPasswordChange( value )}
                onKeyEvent={( e: any ) => {
                  onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
                    this.signin();
                  } );
                }}
                value={this.state.password ? this.state.password : ""}
              />
              <MessageForPassword>Password must be a minimum of 8 characters and contain a capital letter and alphanumeric character​</MessageForPassword>
              <Grid>

                <div>
                  <CheckBoxContainer
                    background={this.state.remember ? "#fff" : ""}
                    onClick={() => this.setState( ( prevState ) => ( { remember: !prevState.remember } ) )}
                  >
                    {this.state.remember && <AntdIcon type={CheckOutline} />}
                  </CheckBoxContainer>



                  <CheckBox>Remember Me
                </CheckBox>
                </div>
                <ForgetPassword
                  className="color-9"
                  type="button"
                  onClick={() => this.setState( {
                    redirect: 'ForgetPassword',
                    email: '',
                    password: '',
                    securityCode: '',
                    reset: '',
                    confirm: '',
                    message: '',
                    focusReset: false,
                    focusConfirm: false,
                    focusEmail: true,
                    focusPassword: false,
                    focusSecure: false,
                    showPassword: false,
                  } )}
                >
                  Forgot Password?
            </ForgetPassword>
              </Grid>
              <ShowPassword
                type="button"
                onClick={() => { this.togglePasswordDisplay(); }}
                bottom={isMobile ? "25.8em" : "23.8em"}
              >
                {showPassword ? 'Hide' : 'Show'}
              </ShowPassword>
              <div className="mrg-lg-top">
                <StyledButtonLarge
                  type="button"
                  onClick={() => { this.signin(); }}
                >
                  Login
            </StyledButtonLarge>
              </div>
            </div>

          </div>
        );
        break;
      }
    }
    return (
      <ThemeProvider theme={theme}>
        <DialogProvider>
          <SignInPage>
            <Loading show={loading} />
            {display}
          </SignInPage>
        </DialogProvider>
      </ThemeProvider>
    );
  }
}

export default App;
