/**
 * This is a demo version of the event history details component
 * as the requirement is not clear now.
 *
 * This demo version receives no coordinates data, which is required,
 * so a local library is created to match demo data's address string to coords
 *
 * When location exists in this library:
 *  - the map in the right show the travel history of a product as markers
 *    connected by simple lines;
 *  - when click on an event on the left, map will automatically center to the
 *    event's location and zoom in
 *  - when hover on a marker, details box show up
 *  - the start point and end point use different markers from the other points
 *
 * TODO: once data scheme is set by backend, use coords from backend to locate points
 *       and remove library
 *
 * @author Dapeng Zhang
 * @version DEMO
 * @Date 16 Dec 2019
 */

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import axios from 'axios';
import GoogleMap from 'google-map-react';
import moment from 'moment';
import { isUndefined } from 'util';
import { eventHistoryItem } from '../../../../utilities/types';
import {
  Wrapper,
  Left,
  Right,
  InnerWrapper,
  Record,
  RecordLeft,
  RecordLeftInnerWrapper,
  LeftLineFirst,
  LeftLineSecond,
  RecordRight,
  RecordRightBackground,
  RightLineFirst,
  RightLineSecond,
  Action,
  Company,
  Location,
  Loading,
  Marker,
} from '../styles';
import coordinateLibrary from '../SampleCoordinateLibrary';
import { ReactComponent as MapMarker } from '../../../../statics/images/map-marker.svg';
import { ReactComponent as EndLogo } from '../../../../statics/images/truck-solid.svg';
import { ReactComponent as StartLogo } from '../../../../statics/images/flag-solid.svg';
import { compareEventHistoryItems } from '../../../../utilities/Functions/SortFunctions';
import styles from '../../../../statics/styles/GoogleMapThemes/GoogleMapStylesPurple.json';

type Props = {
  url: string;
};

const MyMarker = ({ action, time, position }: {
  action: string;
  time: string;
  position: 'start' | 'middle' | 'end';
  [prop: string]: any;
}) => {
  let marker;
  switch (position) {
    case 'start': {
      marker = <StartLogo />;
      break;
    }
    case 'end': {
      marker = <EndLogo />;
      break;
    }
    default: marker = <MapMarker />;
  }
  return (
    <Marker
      position={position}
    >
      {marker}
      <div>
        <h1>{action}</h1>
        <p>{time}</p>
      </div>
    </Marker>
  );
};

const defaultCenter = { lat: -25.274398, lng: 133.775136 };

const EventHistory = (props: Props) => {
  const { url } = props;
  // const [validUrl, setValidUrl] = useState<string>('');
  const [data, setData] = useState<eventHistoryItem[]>([]);
  const [historyDisplay, setHistoryDisplay] = useState<React.ReactElement[]>([<Loading key="event-details-loading">Loading...</Loading>]);
  const [locationsDisplay, setLocationsDisplay] = useState<any[]>([]);
  const [center, setCenter] = useState<{lat: number; lng: number}>(defaultCenter);
  const [zoom, setZoom] = useState<number>(4);
  // const [path, setPath] = useState<{lat: number; lng: number}[]>([]);
  const initialize = useCallback(() => {
    const list = [{
      Key:8434843,
      action:"Grower",
      time:"2021-01-05T20:17:46.384Z",
      location:"Costa Atherton - 104 Beantree Rd, Atherton QLD 48",
      company:""
    },
    {
      Key:84348992,
      action:"Processor",
      time:"2021-01-06T20:17:46.384Z",
      location:"385 Sherwood Rd, Rocklea QLD 4106",
      company:""
    },
    // {
    //   Key:843484993,
    //   action:"Logistics Linfox",
    //   time:"2021-02-08T07:17:46.384Z",
    //   location:"     ",
    //   company:""
    // } ,
    {
      Key:8434843777,
      action:"Distribution Center",
      time:"2021-01-08T05:17:46.384Z",
      location:"Woolworths 522 Wellington Rd, Mulgrave VIC 3170",
      company:""
    }
    ,
    {
      Key:84348439777,
      action:"Retail",
      time:"2021-01-09T23:17:46.384Z",
      location:"Woolworths - 113 Cecil St, South Melbourne VIC 3205",
      company:""
    }
    ,
    {
      Key:8434847739777,
      action:"Consumer",
      time:"2021-01-10T10:17:46.384Z",
      location:"6 Camolina Way, Clyde North VIC 3978",
      company:""
    }
  ];
      if (list instanceof Array) {
        setData(list.map((item: any): eventHistoryItem => ({
          key: item.Key,
          action: item.action,
          time: item.time,
          location: item.location,
          company: item.company,
        })).sort(compareEventHistoryItems));
      }
    // axios.get(url).then((response) => {
    //   const list = response.data;
    //   if (list instanceof Array) {
    //     setData(list.map((item: any): eventHistoryItem => ({
    //       key: item.event_id,
    //       action: item.action,
    //       time: item.full_date,
    //       location: item.location,
    //       company: item.company,
    //     })).sort(compareEventHistoryItems));
    //   }
    // });
  }, [url]);

  useEffect(() => {
    // if url ready (not end with '/')
    if (url[url.length - 1] !== '/') {
      initialize();
    }
  }, [url, initialize]);

  const doHistoryDisplay = useCallback(() => {
    if (data[0]) {
      console.log("time==>",data)
      setHistoryDisplay(data.map((item: eventHistoryItem): any => (
        <Record key={item.key}>
          <RecordLeft>
            <RecordLeftInnerWrapper>
              <LeftLineFirst>{moment(item.time).format('D MMM YYYY')}</LeftLineFirst>
              <LeftLineSecond>{moment(item.time).format('HH[:]mm[:]ss')}</LeftLineSecond>
            </RecordLeftInnerWrapper>
          </RecordLeft>
          <RecordRight>
            <RecordRightBackground
              type="button"
              onClick={() => {
                // only center those in our coords lib
                if (coordinateLibrary[item.location]) {
                  const { lat, lng } = coordinateLibrary[item.location];
                  setCenter({ lat, lng });
                  setZoom(16);
                }
              }}
            >
              <RightLineFirst>
                <Action>{item.action}</Action>
                <Company>{item.company}</Company>
              </RightLineFirst>
              <RightLineSecond>
                <Location>{item.location}</Location>
              </RightLineSecond>
            </RecordRightBackground>
          </RecordRight>
        </Record>
      )));
    }
  }, [data]);

  const Google = useRef<any>();

  const handleGoogleMapApi = (path: {lat: number; lng: number}[]) => {
    const core = (google: any) => {
      // draw lines
      const flightPath = new google.maps.Polyline({
        path,
        geodesic: true,
        strokeColor: 'green',
        strokeOpacity: 1,
        strokeWeight: 1,
      });

      flightPath.setMap(google.map);

      // auto-center and auto-zoom
      const bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < path.length; i += 1) {
        bounds.extend(new google.maps.LatLng(path[i].lat, path[i].lng));
      }
      // auto zoom
      google.map.fitBounds(bounds);
      // auto center
      google.map.panToBounds(bounds);
    };


    const myGoogle = Google.current;
    if (myGoogle) {
      core(myGoogle);
    } else {
      const timer = setInterval(() => {
        const newGoogle = Google.current;
        if (newGoogle) {
          core(newGoogle);
          clearInterval(timer);
        }
      }, 1000);
    }
  };

  const doLocationDisplay = useCallback(() => {
    if (data[0]) {
      // data control for demo purpose, only work when there is data in our lib
      if (data.find((item) => isUndefined(coordinateLibrary[item.location]))) {
        return;
      }
      setLocationsDisplay(data.map((item, index) => {
        const { lat, lng } = coordinateLibrary[item.location];
        let position: 'start' | 'middle' | 'end';
        switch (true) {
          case index === 0: {
            position = 'end';
            break;
          }
          case index === data.length - 1: {
            position = 'start';
            break;
          }
          default: {
            position = 'middle';
          }
        }

        return (
          <MyMarker
            lat={lat}
            lng={lng}
            key={item.key}
            action={item.action}
            time={moment(item.time).format('D MMM YYYY')}
            position={position}
          />
        );
      }));
      handleGoogleMapApi(data.map((item) => coordinateLibrary[item.location]));
    }
  }, [data]);

  useEffect(() => {
    doHistoryDisplay();
    doLocationDisplay();
  }, [data, doHistoryDisplay, doLocationDisplay]);

  return (
    <Wrapper className="row mrg-0 pad-0-v pad-md-h">
      <Left className="col-xl-6 pad-0 height-45vh">
      <GoogleMap
          bootstrapURLKeys={{
            key: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={(google) => {
            Google.current = google;
          }}
          defaultCenter={defaultCenter}
          center={center}
          zoom={zoom}
          options={
            {
              minZoomOverride: true,
              // minZoom: minimumZoom,
              styles
            }
          }
          onChange={(item) => {
            setZoom(item.zoom);
            setCenter(item.center);
          }}
        >
          {locationsDisplay}
        </GoogleMap>
     
      </Left>
      <Right className="col-xl-6 pad-0 height-45vh">
      <InnerWrapper>
          {historyDisplay}
        </InnerWrapper>
      </Right>
    </Wrapper>
  );
};

export default EventHistory;
