/**
 * functions to format object keys as titles or table headers
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

export const FormatTableHeader = (input: string) => {
  /* exceptions */
  if (input.toLowerCase() === 'adikey') {
    return 'ADI Key';
  }
  if (input.toLowerCase() === 'aditype') {
    return 'ADI Type';
  }
  if (input.toLowerCase() === 'itrazokey') {
    return 'ADI Number';
  }
  if (input.toLowerCase() === 'productdate') {
    return 'Production Date';
  }

  if (input.toLowerCase() === 'ipaddress') {
    return 'IP Address';
  }

  if (input.toLowerCase() === 'productid') {
    return 'Product ID';
  }

  if (input.toLowerCase() === 'gpsallow') {
    return 'GPS Allow';
  }

  if (input.toLowerCase() === 'usercreatedate') {
    return 'Date Created';
  }

  if (input.toLowerCase() === 'username') {
    return 'User ID';
  }
  if (input.toLowerCase() === 'name') {
    return 'Product Name';
  }
  if (input.toLowerCase() === 'uname') {
    return 'Name';
  }
  if (input.toLowerCase() === 'lastLocation') {
    return 'Last Location';
  }
 
  if (input.toLowerCase() === 'actions') {
    return '';
  }


  /* normal */

  return input.split(/(?=[A-Z])/).join(' ');
};

export const formatRoute = (path: string) => {
  const firstStep = path.split('/app/')[1];
  if (firstStep) return firstStep.split('-').join(' ').toUpperCase();
  return 'iTrazo';
};

export default null;
