/**
 * reusable functions
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

/* for key event listening */

export const
  onKeyEvent = (e: any, keyCode: number, callback: Function, unequal = false) => {
    if (unequal && e.keyCode !== keyCode) {
      callback();
    } else if (!unequal && e.keyCode === keyCode) {
      callback();
    }
  };

export default null;
