const Path = require('path');

var Utils = {
    isSubPath(targetPath, parentPath) {
        var relative = Path.relative(parentPath, targetPath);
        return relative != null && !relative.startsWith('..') && !Path.isAbsolute(relative) && !Utils.isSamePath(targetPath, parentPath);
    },
    isSamePath(targetPath, parentPath) {
        var p1 = Path.parse(targetPath);
        var p2 = Path.parse(parentPath);
        return p1.dir === p2.dir && p1.base === p2.base;
    },
    isSameOrSubPath(targetPath, parentPath) {
        var relative = Path.relative(parentPath, targetPath);
        return relative != null && !relative.startsWith('..') && !Path.isAbsolute(relative);
    },
    normalisePath(targetPath) {
        var p = Path.parse(targetPath);
        return p.dir + "/" + p.base;
    }
};

module.exports = Utils;
