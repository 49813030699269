type country = {
  lat: number;
  lng: number;
  country: string;
};
const countrys: country[] = [
  {
    lat: 42.546245,
    lng: 1.601554,
    country: 'Andorra',
  },
  {
    lat: 23.424076,
    lng: 53.847818,
    country: 'United Arab Emirates',
  },
  {
    lat: 33.93911,
    lng: 67.709953,
    country: 'Afghanistan',
  },
  {
    lat: 17.060816,
    lng: -61.796428,
    country: 'Antigua and Barbuda',
  },
  {
    lat: 18.220554,
    lng: -63.068615,
    country: 'Anguilla',
  },
  {
    lat: 41.153332,
    lng: 20.168331,
    country: 'Albania',
  },
  {
    lat: 40.069099,
    lng: 45.038189,
    country: 'Armenia',
  },
  {
    lat: 12.226079,
    lng: -69.060087,
    country: 'Netherlands Antilles',
  },
  {
    lat: -11.202692,
    lng: 17.873887,
    country: 'Angola',
  },
  {
    lat: -75.250973,
    lng: -0.071389,
    country: 'Antarctica',
  },
  {
    lat: -38.416097,
    lng: -63.616672,
    country: 'Argentina',
  },
  {
    lat: -14.270972,
    lng: -170.132217,
    country: 'American Samoa',
  },
  {
    lat: 47.516231,
    lng: 14.550072,
    country: 'Austria',
  },
  {
    lat: -25.274398,
    lng: 133.775136,
    country: 'Australia',
  },
  {
    lat: 12.52111,
    lng: -69.968338,
    country: 'Aruba',
  },
  {
    lat: 40.143105,
    lng: 47.576927,
    country: 'Azerbaijan',
  },
  {
    lat: 43.915886,
    lng: 17.679076,
    country: 'Bosnia and Herzegovina',
  },
  {
    lat: 13.193887,
    lng: -59.543198,
    country: 'Barbados',
  },
  {
    lat: 23.684994,
    lng: 90.356331,
    country: 'Bangladesh',
  },
  {
    lat: 50.503887,
    lng: 4.469936,
    country: 'Belgium',
  },
  {
    lat: 12.238333,
    lng: -1.561593,
    country: 'Burkina Faso',
  },
  {
    lat: 42.733883,
    lng: 25.48583,
    country: 'Bulgaria',
  },
  {
    lat: 25.930414,
    lng: 50.637772,
    country: 'Bahrain',
  },
  {
    lat: -3.373056,
    lng: 29.918886,
    country: 'Burundi',
  },
  {
    lat: 9.30769,
    lng: 2.315834,
    country: 'Benin',
  },
  {
    lat: 32.321384,
    lng: -64.75737,
    country: 'Bermuda',
  },
  {
    lat: 4.535277,
    lng: 114.727669,
    country: 'Brunei',
  },
  {
    lat: -16.290154,
    lng: -63.588653,
    country: 'Bolivia',
  },
  {
    lat: -14.235004,
    lng: -51.92528,
    country: 'Brazil',
  },
  {
    lat: 25.03428,
    lng: -77.39628,
    country: 'Bahamas',
  },
  {
    lat: 27.514162,
    lng: 90.433601,
    country: 'Bhutan',
  },
  {
    lat: -54.423199,
    lng: 3.413194,
    country: 'Bouvet Island',
  },
  {
    lat: -22.328474,
    lng: 24.684866,
    country: 'Botswana',
  },
  {
    lat: 53.709807,
    lng: 27.953389,
    country: 'Belarus',
  },
  {
    lat: 17.189877,
    lng: -88.49765,
    country: 'Belize',
  },
  {
    lat: 56.130366,
    lng: -106.346771,
    country: 'Canada',
  },
  {
    lat: -12.164165,
    lng: 96.870956,
    country: 'Cocos [Keeling] Islands',
  },
  {
    lat: -4.038333,
    lng: 21.758664,
    country: 'Congo [DRC]',
  },
  {
    lat: 6.611111,
    lng: 20.939444,
    country: 'Central African Republic',
  },
  {
    lat: -0.228021,
    lng: 15.827659,
    country: 'Congo [Republic]',
  },
  {
    lat: 46.818188,
    lng: 8.227512,
    country: 'Switzerland',
  },
  {
    lat: 7.539989,
    lng: -5.54708,
    country: "Côte d'Ivoire",
  },
  {
    lat: -21.236736,
    lng: -159.777671,
    country: 'Cook Islands',
  },
  {
    lat: -35.675147,
    lng: -71.542969,
    country: 'Chile',
  },
  {
    lat: 7.369722,
    lng: 12.354722,
    country: 'Cameroon',
  },
  {
    lat: 35.86166,
    lng: 104.195397,
    country: 'China',
  },
  {
    lat: 4.570868,
    lng: -74.297333,
    country: 'Colombia',
  },
  {
    lat: 9.748917,
    lng: -83.753428,
    country: 'Costa Rica',
  },
  {
    lat: 21.521757,
    lng: -77.781167,
    country: 'Cuba',
  },
  {
    lat: 16.002082,
    lng: -24.013197,
    country: 'Cape Verde',
  },
  {
    lat: -10.447525,
    lng: 105.690449,
    country: 'Christmas Island',
  },
  {
    lat: 35.126413,
    lng: 33.429859,
    country: 'Cyprus',
  },
  {
    lat: 49.817492,
    lng: 15.472962,
    country: 'Czech Republic',
  },
  {
    lat: 51.165691,
    lng: 10.451526,
    country: 'Germany',
  },
  {
    lat: 11.825138,
    lng: 42.590275,
    country: 'Djibouti',
  },
  {
    lat: 56.26392,
    lng: 9.501785,
    country: 'Denmark',
  },
  {
    lat: 15.414999,
    lng: -61.370976,
    country: 'Dominica',
  },
  {
    lat: 18.735693,
    lng: -70.162651,
    country: 'Dominican Republic',
  },
  {
    lat: 28.033886,
    lng: 1.659626,
    country: 'Algeria',
  },
  {
    lat: -1.831239,
    lng: -78.183406,
    country: 'Ecuador',
  },
  {
    lat: 58.595272,
    lng: 25.013607,
    country: 'Estonia',
  },
  {
    lat: 26.820553,
    lng: 30.802498,
    country: 'Egypt',
  },
  {
    lat: 24.215527,
    lng: -12.885834,
    country: 'Western Sahara',
  },
  {
    lat: 15.179384,
    lng: 39.782334,
    country: 'Eritrea',
  },
  {
    lat: 40.463667,
    lng: -3.74922,
    country: 'Spain',
  },
  {
    lat: 9.145,
    lng: 40.489673,
    country: 'Ethiopia',
  },
  {
    lat: 61.92411,
    lng: 25.748151,
    country: 'Finland',
  },
  {
    lat: -16.578193,
    lng: 179.414413,
    country: 'Fiji',
  },
  {
    lat: -51.796253,
    lng: -59.523613,
    country: 'Falkland Islands [Islas Malvinas]',
  },
  {
    lat: 7.425554,
    lng: 150.550812,
    country: 'Micronesia',
  },
  {
    lat: 61.892635,
    lng: -6.911806,
    country: 'Faroe Islands',
  },
  {
    lat: 46.227638,
    lng: 2.213749,
    country: 'France',
  },
  {
    lat: -0.803689,
    lng: 11.609444,
    country: 'Gabon',
  },
  {
    lat: 55.378051,
    lng: -3.435973,
    country: 'United Kingdom',
  },
  {
    lat: 12.262776,
    lng: -61.604171,
    country: 'Grenada',
  },
  {
    lat: 42.315407,
    lng: 43.356892,
    country: 'Georgia',
  },
  {
    lat: 3.933889,
    lng: -53.125782,
    country: 'French Guiana',
  },
  {
    lat: 49.465691,
    lng: -2.585278,
    country: 'Guernsey',
  },
  {
    lat: 7.946527,
    lng: -1.023194,
    country: 'Ghana',
  },
  {
    lat: 36.137741,
    lng: -5.345374,
    country: 'Gibraltar',
  },
  {
    lat: 71.706936,
    lng: -42.604303,
    country: 'Greenland',
  },
  {
    lat: 13.443182,
    lng: -15.310139,
    country: 'Gambia',
  },
  {
    lat: 9.945587,
    lng: -9.696645,
    country: 'Guinea',
  },
  {
    lat: 16.995971,
    lng: -62.067641,
    country: 'Guadeloupe',
  },
  {
    lat: 1.650801,
    lng: 10.267895,
    country: 'Equatorial Guinea',
  },
  {
    lat: 39.074208,
    lng: 21.824312,
    country: 'Greece',
  },
  {
    lat: -54.429579,
    lng: -36.587909,
    country: 'South Georgia and the South Sandwich Islands',
  },
  {
    lat: 15.783471,
    lng: -90.230759,
    country: 'Guatemala',
  },
  {
    lat: 13.444304,
    lng: 144.793731,
    country: 'Guam',
  },
  {
    lat: 11.803749,
    lng: -15.180413,
    country: 'Guinea-Bissau',
  },
  {
    lat: 4.860416,
    lng: -58.93018,
    country: 'Guyana',
  },
  {
    lat: 31.354676,
    lng: 34.308825,
    country: 'Gaza Strip',
  },
  {
    lat: 22.396428,
    lng: 114.109497,
    country: 'Hong Kong',
  },
  {
    lat: -53.08181,
    lng: 73.504158,
    country: 'Heard Island and McDonald Islands',
  },
  {
    lat: 15.199999,
    lng: -86.241905,
    country: 'Honduras',
  },
  {
    lat: 45.1,
    lng: 15.2,
    country: 'Croatia',
  },
  {
    lat: 18.971187,
    lng: -72.285215,
    country: 'Haiti',
  },
  {
    lat: 47.162494,
    lng: 19.503304,
    country: 'Hungary',
  },
  {
    lat: -0.789275,
    lng: 113.921327,
    country: 'Indonesia',
  },
  {
    lat: 53.41291,
    lng: -8.24389,
    country: 'Ireland',
  },
  {
    lat: 31.046051,
    lng: 34.851612,
    country: 'Israel',
  },
  {
    lat: 54.236107,
    lng: -4.548056,
    country: 'Isle of Man',
  },
  {
    lat: 20.593684,
    lng: 78.96288,
    country: 'India',
  },
  {
    lat: -6.343194,
    lng: 71.876519,
    country: 'British Indian Ocean Territory',
  },
  {
    lat: 33.223191,
    lng: 43.679291,
    country: 'Iraq',
  },
  {
    lat: 32.427908,
    lng: 53.688046,
    country: 'Iran',
  },
  {
    lat: 64.963051,
    lng: -19.020835,
    country: 'Iceland',
  },
  {
    lat: 41.87194,
    lng: 12.56738,
    country: 'Italy',
  },
  {
    lat: 49.214439,
    lng: -2.13125,
    country: 'Jersey',
  },
  {
    lat: 18.109581,
    lng: -77.297508,
    country: 'Jamaica',
  },
  {
    lat: 30.585164,
    lng: 36.238414,
    country: 'Jordan',
  },
  {
    lat: 36.204824,
    lng: 138.252924,
    country: 'Japan',
  },
  {
    lat: -0.023559,
    lng: 37.906193,
    country: 'Kenya',
  },
  {
    lat: 41.20438,
    lng: 74.766098,
    country: 'Kyrgyzstan',
  },
  {
    lat: 12.565679,
    lng: 104.990963,
    country: 'Cambodia',
  },
  {
    lat: -3.370417,
    lng: -168.734039,
    country: 'Kiribati',
  },
  {
    lat: -11.875001,
    lng: 43.872219,
    country: 'Comoros',
  },
  {
    lat: 17.357822,
    lng: -62.782998,
    country: 'Saint Kitts and Nevis',
  },
  {
    lat: 40.339852,
    lng: 127.510093,
    country: 'North Korea',
  },
  {
    lat: 35.907757,
    lng: 127.766922,
    country: 'South Korea',
  },
  {
    lat: 29.31166,
    lng: 47.481766,
    country: 'Kuwait',
  },
  {
    lat: 19.513469,
    lng: -80.566956,
    country: 'Cayman Islands',
  },
  {
    lat: 48.019573,
    lng: 66.923684,
    country: 'Kazakhstan',
  },
  {
    lat: 19.85627,
    lng: 102.495496,
    country: 'Laos',
  },
  {
    lat: 33.854721,
    lng: 35.862285,
    country: 'Lebanon',
  },
  {
    lat: 13.909444,
    lng: -60.978893,
    country: 'Saint Lucia',
  },
  {
    lat: 47.166,
    lng: 9.555373,
    country: 'Liechtenstein',
  },
  {
    lat: 7.873054,
    lng: 80.771797,
    country: 'Sri Lanka',
  },
  {
    lat: 6.428055,
    lng: -9.429499,
    country: 'Liberia',
  },
  {
    lat: -29.609988,
    lng: 28.233608,
    country: 'Lesotho',
  },
  {
    lat: 55.169438,
    lng: 23.881275,
    country: 'Lithuania',
  },
  {
    lat: 49.815273,
    lng: 6.129583,
    country: 'Luxembourg',
  },
  {
    lat: 56.879635,
    lng: 24.603189,
    country: 'Latvia',
  },
  {
    lat: 26.3351,
    lng: 17.228331,
    country: 'Libya',
  },
  {
    lat: 31.791702,
    lng: -7.09262,
    country: 'Morocco',
  },
  {
    lat: 43.750298,
    lng: 7.412841,
    country: 'Monaco',
  },
  {
    lat: 47.411631,
    lng: 28.369885,
    country: 'Moldova',
  },
  {
    lat: 42.708678,
    lng: 19.37439,
    country: 'Montenegro',
  },
  {
    lat: -18.766947,
    lng: 46.869107,
    country: 'Madagascar',
  },
  {
    lat: 7.131474,
    lng: 171.184478,
    country: 'Marshall Islands',
  },
  {
    lat: 41.608635,
    lng: 21.745275,
    country: 'Macedonia [FYROM]',
  },
  {
    lat: 17.570692,
    lng: -3.996166,
    country: 'Mali',
  },
  {
    lat: 21.913965,
    lng: 95.956223,
    country: 'Myanmar [Burma]',
  },
  {
    lat: 46.862496,
    lng: 103.846656,
    country: 'Mongolia',
  },
  {
    lat: 22.198745,
    lng: 113.543873,
    country: 'Macau',
  },
  {
    lat: 17.33083,
    lng: 145.38469,
    country: 'Northern Mariana Islands',
  },
  {
    lat: 14.641528,
    lng: -61.024174,
    country: 'Martinique',
  },
  {
    lat: 21.00789,
    lng: -10.940835,
    country: 'Mauritania',
  },
  {
    lat: 16.742498,
    lng: -62.187366,
    country: 'Montserrat',
  },
  {
    lat: 35.937496,
    lng: 14.375416,
    country: 'Malta',
  },
  {
    lat: -20.348404,
    lng: 57.552152,
    country: 'Mauritius',
  },
  {
    lat: 3.202778,
    lng: 73.22068,
    country: 'Maldives',
  },
  {
    lat: -13.254308,
    lng: 34.301525,
    country: 'Malawi',
  },
  {
    lat: 23.634501,
    lng: -102.552784,
    country: 'Mexico',
  },
  {
    lat: 4.210484,
    lng: 101.975766,
    country: 'Malaysia',
  },
  {
    lat: -18.665695,
    lng: 35.529562,
    country: 'Mozambique',
  },
  {
    lat: -22.95764,
    lng: 18.49041,
    country: 'Namibia',
  },
  {
    lat: -20.904305,
    lng: 165.618042,
    country: 'New Caledonia',
  },
  {
    lat: 17.607789,
    lng: 8.081666,
    country: 'Niger',
  },
  {
    lat: -29.040835,
    lng: 167.954712,
    country: 'Norfolk Island',
  },
  {
    lat: 9.081999,
    lng: 8.675277,
    country: 'Nigeria',
  },
  {
    lat: 12.865416,
    lng: -85.207229,
    country: 'Nicaragua',
  },
  {
    lat: 52.132633,
    lng: 5.291266,
    country: 'Netherlands',
  },
  {
    lat: 60.472024,
    lng: 8.468946,
    country: 'Norway',
  },
  {
    lat: 28.394857,
    lng: 84.124008,
    country: 'Nepal',
  },
  {
    lat: -0.522778,
    lng: 166.931503,
    country: 'Nauru',
  },
  {
    lat: -19.054445,
    lng: -169.867233,
    country: 'Niue',
  },
  {
    lat: -40.900557,
    lng: 174.885971,
    country: 'New Zealand',
  },
  {
    lat: 21.512583,
    lng: 55.923255,
    country: 'Oman',
  },
  {
    lat: 8.537981,
    lng: -80.782127,
    country: 'Panama',
  },
  {
    lat: -9.189967,
    lng: -75.015152,
    country: 'Peru',
  },
  {
    lat: -17.679742,
    lng: -149.406843,
    country: 'French Polynesia',
  },
  {
    lat: -6.314993,
    lng: 143.95555,
    country: 'Papua New Guinea',
  },
  {
    lat: 12.879721,
    lng: 121.774017,
    country: 'Philippines',
  },
  {
    lat: 30.375321,
    lng: 69.345116,
    country: 'Pakistan',
  },
  {
    lat: 51.919438,
    lng: 19.145136,
    country: 'Poland',
  },
  {
    lat: 46.941936,
    lng: -56.27111,
    country: 'Saint Pierre and Miquelon',
  },
  {
    lat: -24.703615,
    lng: -127.439308,
    country: 'Pitcairn Islands',
  },
  {
    lat: 18.220833,
    lng: -66.590149,
    country: 'Puerto Rico',
  },
  {
    lat: 31.952162,
    lng: 35.233154,
    country: 'Palestinian Territories',
  },
  {
    lat: 39.399872,
    lng: -8.224454,
    country: 'Portugal',
  },
  {
    lat: 7.51498,
    lng: 134.58252,
    country: 'Palau',
  },
  {
    lat: -23.442503,
    lng: -58.443832,
    country: 'Paraguay',
  },
  {
    lat: 25.354826,
    lng: 51.183884,
    country: 'Qatar',
  },
  {
    lat: -21.115141,
    lng: 55.536384,
    country: 'Réunion',
  },
  {
    lat: 45.943161,
    lng: 24.96676,
    country: 'Romania',
  },
  {
    lat: 44.016521,
    lng: 21.005859,
    country: 'Serbia',
  },
  {
    lat: 61.52401,
    lng: 105.318756,
    country: 'Russia',
  },
  {
    lat: -1.940278,
    lng: 29.873888,
    country: 'Rwanda',
  },
  {
    lat: 23.885942,
    lng: 45.079162,
    country: 'Saudi Arabia',
  },
  {
    lat: -9.64571,
    lng: 160.156194,
    country: 'Solomon Islands',
  },
  {
    lat: -4.679574,
    lng: 55.491977,
    country: 'Seychelles',
  },
  {
    lat: 12.862807,
    lng: 30.217636,
    country: 'Sudan',
  },
  {
    lat: 60.128161,
    lng: 18.643501,
    country: 'Sweden',
  },
  {
    lat: 1.352083,
    lng: 103.819836,
    country: 'Singapore',
  },
  {
    lat: -24.143474,
    lng: -10.030696,
    country: 'Saint Helena',
  },
  {
    lat: 46.151241,
    lng: 14.995463,
    country: 'Slovenia',
  },
  {
    lat: 77.553604,
    lng: 23.670272,
    country: 'Svalbard and Jan Mayen',
  },
  {
    lat: 48.669026,
    lng: 19.699024,
    country: 'Slovakia',
  },
  {
    lat: 8.460555,
    lng: -11.779889,
    country: 'Sierra Leone',
  },
  {
    lat: 43.94236,
    lng: 12.457777,
    country: 'San Marino',
  },
  {
    lat: 14.497401,
    lng: -14.452362,
    country: 'Senegal',
  },
  {
    lat: 5.152149,
    lng: 46.199616,
    country: 'Somalia',
  },
  {
    lat: 3.919305,
    lng: -56.027783,
    country: 'Suriname',
  },
  {
    lat: 0.18636,
    lng: 6.613081,
    country: 'São Tomé and Príncipe',
  },
  {
    lat: 13.794185,
    lng: -88.89653,
    country: 'El Salvador',
  },
  {
    lat: 34.802075,
    lng: 38.996815,
    country: 'Syria',
  },
  {
    lat: -26.522503,
    lng: 31.465866,
    country: 'Swaziland',
  },
  {
    lat: 21.694025,
    lng: -71.797928,
    country: 'Turks and Caicos Islands',
  },
  {
    lat: 15.454166,
    lng: 18.732207,
    country: 'Chad',
  },
  {
    lat: -49.280366,
    lng: 69.348557,
    country: 'French Southern Territories',
  },
  {
    lat: 8.619543,
    lng: 0.824782,
    country: 'Togo',
  },
  {
    lat: 15.870032,
    lng: 100.992541,
    country: 'Thailand',
  },
  {
    lat: 38.861034,
    lng: 71.276093,
    country: 'Tajikistan',
  },
  {
    lat: -8.967363,
    lng: -171.855881,
    country: 'Tokelau',
  },
  {
    lat: -8.874217,
    lng: 125.727539,
    country: 'Timor-Leste',
  },
  {
    lat: 38.969719,
    lng: 59.556278,
    country: 'Turkmenistan',
  },
  {
    lat: 33.886917,
    lng: 9.537499,
    country: 'Tunisia',
  },
  {
    lat: -21.178986,
    lng: -175.198242,
    country: 'Tonga',
  },
  {
    lat: 38.963745,
    lng: 35.243322,
    country: 'Turkey',
  },
  {
    lat: 10.691803,
    lng: -61.222503,
    country: 'Trinidad and Tobago',
  },
  {
    lat: -7.109535,
    lng: 177.64933,
    country: 'Tuvalu',
  },
  {
    lat: 23.69781,
    lng: 120.960515,
    country: 'Taiwan',
  },
  {
    lat: -6.369028,
    lng: 34.888822,
    country: 'Tanzania',
  },
  {
    lat: 48.379433,
    lng: 31.16558,
    country: 'Ukraine',
  },
  {
    lat: 1.373333,
    lng: 32.290275,
    country: 'Uganda',
  },
  {
    lat: 19.2823,
    lng: 166.6470,
    country: 'U.S. Minor Outlying Islands',
  },
  {
    lat: 37.09024,
    lng: -95.712891,
    country: 'United States',
  },
  {
    lat: -32.522779,
    lng: -55.765835,
    country: 'Uruguay',
  },
  {
    lat: 41.377491,
    lng: 64.585262,
    country: 'Uzbekistan',
  },
  {
    lat: 41.902916,
    lng: 12.453389,
    country: 'Vatican City',
  },
  {
    lat: 12.984305,
    lng: -61.287228,
    country: 'Saint Vincent and the Grenadines',
  },
  {
    lat: 6.42375,
    lng: -66.58973,
    country: 'Venezuela',
  },
  {
    lat: 18.420695,
    lng: -64.639968,
    country: 'British Virgin Islands',
  },
  {
    lat: 18.335765,
    lng: -64.896335,
    country: 'U.S. Virgin Islands',
  },
  {
    lat: 14.058324,
    lng: 108.277199,
    country: 'Vietnam',
  },
  {
    lat: -15.376706,
    lng: 166.959158,
    country: 'Vanuatu',
  },
  {
    lat: -13.768752,
    lng: -177.156097,
    country: 'Wallis and Futuna',
  },
  {
    lat: -13.759029,
    lng: -172.104629,
    country: 'Samoa',
  },
  {
    lat: 42.602636,
    lng: 20.902977,
    country: 'Kosovo',
  },
  {
    lat: 15.552727,
    lng: 48.516388,
    country: 'Yemen',
  },
  {
    lat: -12.8275,
    lng: 45.166244,
    country: 'Mayotte',
  },
  {
    lat: -30.559482,
    lng: 22.937506,
    country: 'South Africa',
  },
  {
    lat: -13.133897,
    lng: 27.849332,
    country: 'Zambia',
  },
  {
    lat: -19.015438,
    lng: 29.154857,
    country: 'Zimbabwe',
  },
];

export default countrys;
