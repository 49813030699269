/**
 * Phone input component
 *
 * @author Dapeng Zhang
 * @version 1.0.0
 * @Date 4 Dec 2019
 */

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

type Props = {
  placeholder: string;
  onChange?: Function;
  focus?: boolean;
  onKeyEvent?: Function;
  previousValue?: string
};

const Wrapper = styled.div`
  .react-phone-number-input__input{
    border: none;
    background: none;
    color: white;


    &::placeholder{
      color: rgb(210, 210, 210);
    }
  }
`;

const Input = ( props: Props ) => {
  const [value, setValue] = useState( '' );
  const {
    placeholder,
    onChange,
    focus,
    onKeyEvent,
    previousValue,
  } = props;
  const phoneInput = useRef( <input /> as any );
  useEffect( () => {
    if ( focus ) {
      phoneInput.current.focus();

    }
    if ( previousValue ) {
      if(value.length<=0){
        setValue( previousValue ? previousValue : "" )
      }
      
    }
  }, [focus, previousValue] );

  return (
    <Wrapper>
      <PhoneInput
        ref={phoneInput}
        placeholder={placeholder}
        value={value}
        onChange={( input: string ) => {
          setValue( input );
          if ( onChange ) {
            onChange( input );
          }
        }}
        onKeyUp={( e: any ) => {
          if ( onKeyEvent ) {
            onKeyEvent( e );
          }
        }}
      />
    </Wrapper>
  );
};

export default Input;
