/**
 * This is a demo version of the product tracking page
 * as the requirement is not clear now.
 *
 * This page now only show the first 100 keys user owned.
 * And when user click on a key record, the event history of this key will show.
 *
 * TODO:
 *  - This component should show all keys the user owns, which could be millions
 *    or billions. To achieve this heavy duty goal, this component should request
 *    keys partially each time, for example, 100 every time. And the key request
 *    should be hidden in table's pagination buttons. So user will have continuous
 *    user experience.
 *
 *  - Another issue is key sorting and key filtering, if we still keep these table
 *    features, we should consider how to filter and sort again the whole keys pool
 *    without influncing user experience
 *
 * @author Dapeng Zhang
 * @version DEMO
 * @Date 16 Dec 2019
 */

import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import axios from 'axios';
import Table from '../../../components/Table';
import {
  api,
  productSummary,
} from '../../../utilities/types';
import Loading from '../../../components/Loading';
import Selection from '../../../components/Selection';
import {
  PageWrapper,
  Body,
  Grid,
} from '../../../statics/styles/StyledComponents';
import { useDialog } from '../../../components/Dialog';
import EventHistory from './EventHistory';
import Statistics from '../../../components/Statistics';
import stock from '../../../statics/images/stock.svg'
import warehouse from '../../../statics/images/warehouse.svg'
import valueChain from '../../../statics/images/valuechain.svg'
import diversion from '../../../statics/images/diversion.svg'
import SearchBox from '../../../components/SearchBox';
import styled from 'styled-components';
import refresh from '../../../statics/images/refresh-icon.svg';
import { isMobile } from 'react-device-detect';
import { onKeyEvent } from '../../../utilities/Functions/Functions';
import { KEYBOARD_CODES } from '../../../utilities/Functions/CONSTANTS';

type Props = {
  apis: api[];
};

const list = [{
  key: 'product_tracking',
  label: 'Value Chain Tracking',
}];
const CardsData = [
  {
    bgOne: "#2ABAD3",
    bgTwo: "#3CEAA3",
    icons: stock,
    title: "Stock in Production",
    value: 2,
  },
  {
    bgOne: "#6B8DEC",
    bgTwo: "#49C4F2",
    icons: warehouse,
    title: "Stock in Warehouse",
    value: 2,
  },
  {
    bgOne: "#EE931E",
    bgTwo: "#FBD224",
    icons: valueChain,
    title: "Stock in Value Chain",
    value: 0
  },
  {
    bgOne: "#E2382E",
    bgTwo: "#EF7F48",
    icons: diversion,
    title: "Unauthorised Diversion",
    value: 0
  },
]
const ShowHeader = styled.div`
  display:flex
  margin-top: ${( { margin }: { margin?: string } ) => {
    if ( margin ) return margin;
    return '-5px';
  }};
`
const Header = styled.div`
*{
  color: white;
}

&>p{
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
}
display:flex;
align-item:flex-end;
justify-content:flex-end
`;
const ProductTracking = ( props: Props ) => {
  const openDialog = useDialog();
  const { apis } = props;
  const eventUrlBase = apis[1].url;
  const [url, setUrl] = useState<string>( apis[0].url );
  const [selected, setSelected] = useState<string>( '' );
  const [data, setData] = useState<productSummary[]>( [] );
  const [loading, setLoading] = useState( false );
  const [historyRefresh, setHistoryRefresh] = useState( false )
  const [searchText,setSearchText] = useState("");
  const [filteredKeys,setFilteredKey] = useState<productSummary[]>([])
  useEffect( () => {
    setUrl( apis[0].url );
  }, [apis] );

  const update = useCallback( () => {

    setLoading( true );
    axios.get( url ).then( ( response ) => {
      if ( response.data ) {
        const newData = response.data.product_list;
        if ( Array.isArray( newData ) ) {
         let formateDate= newData.map( ( item: any ): productSummary => ( {
            key: item.uni_code,
            itrazokey: item.uni_code,
            name: item.name,
            lastLocation: "Clyde North VIC",
            productDate: item.product_date,
          } ) ) 
          setData(formateDate);
          setFilteredKey(formateDate)
        }
      }
    } ).catch( ( e ) => {
      openDialog( 'Error', e.message, "Network Error", true );
    } ).finally( () => {
      setLoading( false );
    } );
  }, [url, openDialog] );
  const onSearch = () => {
    if ( searchText && searchText !== "" && data.length ) {
      let filteredKeys = data.filter( ( rec: any ) => {
        return rec.key.includes( searchText ) || rec.name.includes( searchText )
      } )
      if ( filteredKeys.length ) {
        setFilteredKey( filteredKeys )

      } else {
       
        setFilteredKey([])
      }
    } else {
      setFilteredKey( data )
    }
  }
  useEffect( update, [url] );
  
  return (
    <PageWrapper>
      <Header>
        {!isMobile && <Selection
          list={list}
          upperCase
        />}
        <ShowHeader>
        {isMobile ? <span onClick={() => {
            update()
          }}
            style={{ marginRight: "10px", marginTop: "0px", cursor: "pointer" }}><img src={refresh} />
          </span>  : <span onClick={() => {
            update()
          }}
            style={{ marginRight: "10px", marginTop: "45px", cursor: "pointer" }}><img src={refresh} />
          </span> }
        </ShowHeader>
      </Header>
      <Body>
        <Loading show={loading} />
        <Grid>
          {CardsData.map( rec => <Statistics
            bgOne={rec.bgOne}
            bgTwo={rec.bgTwo}
            icons={rec.icons}
            title={rec.title}
            value={rec.value}
          /> )}
        </Grid>
        <div>
          <SearchBox
          placeHolder="Search by ADI Number, Product Name or Production Date" 
          onChange={( input: string ) => {
            setSearchText( input );
          }}
          onKeyEvent={( e: any ) => {
            onKeyEvent( e, KEYBOARD_CODES.ENTER, () => {
              onSearch();
            } );
          }}
          
          />
        </div>
        {loading ? null : <Table
          originalList={filteredKeys ? filteredKeys :[] }
          NoData={<p>No product</p>}
          collapse={
            {
              onSelect: ( key: string ) => {
                setSelected( key );
              },
              details: <EventHistory url={`${eventUrlBase}${selected}`} />,
            }
          }
        />}
      </Body>
    </PageWrapper>
  );
};

export default ProductTracking;
